import React, { useState, useEffect, useCallback, useRef, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Space, Card, Input, Table, message } from 'antd'
import { Timer } from 'lucide-react'
import Layout from '../../../components/Layout'
import ViewProductionWorkersModal from '../../../components/Modal/viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../../components/Modal/SuccessModalWithTimer'
import ErrorModalWithTimer from '../../../components/Modal/ErrorModalWithTimer'
import { columnProductionWorkers } from '../../../utils/columns.jsx'
import { fetchProductionWorkers } from '../../../utils/fetchData.js'
import moment from 'moment'
import { http } from '../../../services/http'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import './table.css'
import {
	handleMarshrutWorkers,
	handleNavigateSeriesProdWorkers,
} from '../../../utils/navigateToPdf'
import {
	calculateDurationString,
	getToday,
	formatTimeWithHours,
	isAfter,
	isSameDay,
	isBefore,
} from '../../../utils/time'
import IsDoing from './IsDoing'
import IsReadyNextStageNo from './IsReadyNextStageNo'
import {
	setActiveTimersWorker,
	setTestState,
} from '../../../slice/productionsSlice'

import {
	switchBgColor,
	meaningOfBg,
	ignoredKeys,
} from '../../../utils/switchBgProductionsTables'

const ProductionManagement = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const { activeTimersWorker } = useSelector((state) => state.production)

	const successRef = useRef()
	const errorRef = useRef()
	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [inputDocNum, setInputDocNum] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(
		moment().endOf('year').format('YYYY-MM-DD'),
	)
	const [pageSize] = useState('10')
	const [activeTimers, setActiveTimers] = useState(activeTimersWorker)
	const [scannedCode, setScannedCode] = useState('')
	const [lastScanTime, setLastScanTime] = useState(null)
	const [mappedData, setMappedData] = useState([])
	const [activeTab, setActiveTab] = useState('tab1')
	const [isStarted, setIsStarted] = useState('')
	const [isReady, setIsReady] = useState('')
	const [isReadyForNextStage, setIsReadyForNextStage] = useState('Yes')

	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				//IsReadyNextStageYes -> Alohida componentga ajratishga logika yozildi bunda handleStartBtn va handleEndBtn funksiyalari props sifatida beriladi va yana bitta narsani oylavomman bu refetch qilish bunga bitta state ochib usha orqali bersa boladi useCallback	orqali yozsa yaxshi bolsa kerak
				return (
					<div key={'Ready'}>
						<div className="mt-5 ml-2 sm:ml-10 flex gap-5 items-end">
							<div className="flex flex-col gap-3">
								<span className="font-bold text-sm">
									{t('docNum')}
								</span>
								<Input
									className="w-full sm:w-[250px] h-8"
									placeholder={t('docNum')}
									onChange={(e) => {
										setInputDocNum(e.target.value)
										handleDocNumChange(e.target.value)
									}}
									value={inputDocNum}
								/>
							</div>
							{/* <div className="flex flex-col gap-3">
								<p className="font-bold text-sm">
									{t('startDate')}
								</p>
								<Input
									type="date"
									value={startDate}
									className="w-[150px] h-8"
									onChange={handleStartDate}
									max={endDate}
									allowClear
								/>
							</div>
							<div className="flex flex-col gap-3">
								<p className="font-bold text-sm">
									{t('endDate')}
								</p>
								<Input
									type="date"
									value={endDate}
									className="w-[150px] h-8"
									onChange={handleEndDate}
									min={startDate}
									// max={formattedDate}
									allowClear
								/>
							</div> */}
						</div>
						<div className=" mt-10 mb-4 ml-10 flex flex-col justify-between sm:flex-row">
							<div className="flex gap-5 flex-wrap">
								{meaningBg.map((item, index) => (
									<div
										key={index}
										className="flex gap-1 items-center"
									>
										<div
											className={`rounded-full ${item.className} w-[20px] h-[20px]`}
										></div>
										<span>{item.meaning}</span>
									</div>
								))}
							</div>
							<div className="flex sm:mr-10">
								<button
									onClick={handlePreviousPage}
									disabled={currentPage === 0}
									className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
								>
									{t('previous')}
								</button>
								<div className="bg-[#099204] w-[20px] h-[30px] flex items-center justify-center">
									<span className="text-center text-white">
										{currentPage + 1}
									</span>
								</div>
								<button
									onClick={handleNextPage}
									disabled={mappedData.length !== 10}
									className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
								>
									{t('next')}
								</button>
							</div>
						</div>

						<div className="ml-10">
							<Table
								columns={columns}
								dataSource={mappedData}
								pagination={false}
								className="mt-6 w-full sm:mt-10 ant-table-override"
								rowKey={(record) => record.key}
								bordered
								scroll={{ x: 900 }}
								loading={isLoading}
								rowClassName={switchBgColor}
							/>
						</div>
					</div>
				)
			case 'tab2':
				return <IsDoing />
			case 'tab3':
				return <IsReadyNextStageNo />

			default:
				return null
		}
	}

	const {
		data: productionWorkersData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionWorkersReady',
			currentPage,
			pageSize,
			startDate,
			endDate,
			docNum,
			isStarted,
			isReady,
			isReadyForNextStage,
			roleNames.includes('admin') ||
			roleNames.includes('ProdManager') ||
			roleNames.includes('Worker')
				? ''
				: getMe?.department,
			roleNames.includes('admin') ? '' : getMe?.u_Service,
		],
		queryFn: fetchProductionWorkers,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	useEffect(() => {
		setMappedData(productionWorkersData)
	}, [productionWorkersData])

	const startTimePatch = async (data, startTime) => {
		const docLines = data
		const documentLines = [
			{
				lineId: docLines.lineId,
				startTime: startTime,
				endTime: docLines.endTime,
				wastedTime: docLines.wastedTime,
				isStarted: 'Yes',
				isReady: docLines.isReady,
				isReadyForNextStage: docLines.isReadyForNextStage,
				description: data.description,
			},
		]
		const patchingData = {
			docEntry: data.docEntry,
			priority: data.priority,
			status: data.description,
			salesOrderDocEntry: data.salesOrderDocEntry,
			documentLines,
		}
		try {
			await http.patch(
				`api/pots/update-pots/${data?.docEntry}`,
				patchingData,
			)
			refetch()
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		}
	}

	const endTimePatch = async (data, endTime, duration) => {
		console.log('data', data)
		const docLines = data
		const documentLines = [
			{
				lineId: docLines.lineId,
				startTime: docLines.startTime,
				endTime: endTime,
				wastedTime: duration,
				isStarted: docLines.isStarted,
				isReady: 'Yes',
				isReadyForNextStage: 'Yes',
				description: data.description,
			},
		]
		const patchingData = {
			docEntry: data.docEntry,
			priority: data.priority,
			status: data.description,
			salesOrderDocEntry: data.salesOrderDocEntry,
			documentLines,
		}
		try {
			await http.patch(
				`api/pots/update-pots/${data?.docEntry}`,
				patchingData,
			)
			refetch()
			successRef.current?.open(t('sucSaved'))
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		}
	}

	const patchStatusReadyForNextStage = async (data, serialNumber) => {
		try {
			await http.patch(
				`api/pots/ready-for-next-stage/?serialNumber=${serialNumber}&docEntry=${data?.docEntry}`,
			)
			refetch()
			// successRef.current?.open(t('sucChangesStatus'))
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartBtn = async (serialNumber) => {
		const currentTime = moment().format('HH:mm:ss')
		let url = `api/pots/get-pots-lines?serialNumber=${serialNumber}&service=${getMe?.u_Service}`
		const { data } = await http.get(url)
		const validData = data[0]

		if (validData) {
			if (validData?.isReadyForNextStage === 'Yes') {
				if (
					validData?.isStarted === 'Yes' &&
					validData?.isReady === 'Yes'
				) {
					errorRef.current?.open(t('alreadyFinished'))
				} else {
					setActiveTimers((prev) => ({
						...prev,
						[serialNumber]: {
							startTime: currentTime,
							u_Timer: Number(validData?.timer) * 60 || 30,
							timer: Number(validData?.timer) * 60 || 30,
							isActive: true,
							serialNumber,

							// ...validData,
						},
					}))

					const dData = () => ({
						...activeTimers,
						[serialNumber]: {
							startTime: currentTime,
							timer: Number(validData?.timer) * 60 || 30,
							isActive: true,
							serialNumber,
						},
					})

					const response = dData()

					dispatch(setActiveTimersWorker(response))
					try {
						startTimePatch(validData, currentTime)
					} catch (error) {
						console.log(error)
					}
				}
			} else {
				errorRef.current?.open(t('notReadyForNextStage'))
			}
		} else {
			errorRef.current?.open(t('seriesNotFound'))
		}
	}

	const handleEndBtn = async (serialNumber) => {
		const currentTime = moment().format('HH:mm:ss')
		let url = `api/pots/get-pots-lines?serialNumber=${serialNumber}&service=${getMe?.u_Service}`
		const { data } = await http.get(url)
		const validData = data[0]

		const endData = activeTimers[serialNumber]
		const durationFromStart = calculateDurationString(
			validData?.startTime,
			currentTime,
		)

		try {
			endTimePatch(validData, currentTime, durationFromStart)
			setTimeout(() => {
				patchStatusReadyForNextStage(validData, serialNumber)
			}, 3000)
			setActiveTimers((prev) => {
				const newTimers = { ...prev }
				delete newTimers[serialNumber]
				return newTimers
			})
			const dispatchData = () => {
				const newTimers = { ...activeTimers }
				delete newTimers[serialNumber]
				return newTimers
			}
			const dData = dispatchData()
			dispatch(setActiveTimersWorker(dData))
			// if (index !== '') {
			// 	setMappedData((prev) => {
			// 		return prev.map((item, i) => {
			// 			if (i === index) {
			// 				return {
			// 					...item,
			// 					isShowStart: true,
			// 					isShowEnd: false,
			// 				}
			// 			}
			// 			return item
			// 		})
			// 	})
			// }
		} catch (error) {
			console.log('Error updating production:', error)
		}
	}

	const handleScan = useCallback(
		async (serialNumber) => {
			const currentTime = moment().format('HH:mm:ss')

			if (lastScanTime && currentTime - lastScanTime < 500) {
				return
			}
			setLastScanTime(currentTime)

			if (!activeTimers[serialNumber]) {
				handleStartBtn(serialNumber)
			} else if (activeTimers[serialNumber].isActive) {
				handleEndBtn(serialNumber)
			}
		},
		[lastScanTime, activeTimers],
	)

	const handleNextPage = () => {
		if (mappedData.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.ctrlKey || e.metaKey || e.altKey || e.shiftKey) {
				return
			}

			if (e.key === 'Enter') {
				if (scannedCode) {
					handleScan(scannedCode) // Process the scanned barcode
					setScannedCode('') // Clear the scannedCode
				} else {
					console.log('Enter key pressed but no code to process.')
				}
			} else if (!ignoredKeys.includes(e.key)) {
				// Append valid characters to scannedCode
				setScannedCode((prev) => prev + e.key)
			}
		}

		window.addEventListener('keydown', handleKeyPress)
		return () => window.removeEventListener('keydown', handleKeyPress)
	}, [scannedCode, handleScan])

	useEffect(() => {
		const interval = setInterval(() => {
			const currentTime = moment()
			// Bu yerda const time = hozirgi vaqtdan va boshlangan vaqtni ayrilib  uni sekundlarda olinvotti va shuni Itemning timeri - time qilinvotti
			setActiveTimers((prev) => {
				const newTimers = {}
				Object.keys(prev).forEach((serialNumber) => {
					const startTime = moment(
						prev[serialNumber].startTime,
						'HH:mm:ss',
					)
					const elapsedTime = prev[serialNumber].isActive
						? prev[serialNumber].u_Timer -
							currentTime.diff(startTime, 'seconds')
						: prev[serialNumber].timer

					newTimers[serialNumber] = {
						...prev[serialNumber],
						timer: elapsedTime,
					}
				})
				return newTimers
			})

			setActiveTimers((current) => {
				dispatch(setActiveTimersWorker({ ...current }))
				return current
			})
		}, 1000)

		return () => clearInterval(interval)
	}, [])

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const columns = columnProductionWorkers(
		t,
		handleRowClick,
		handleStartBtn,
		handleEndBtn,
		handleNavigateSeriesProdWorkers,
		handleDocNumChange,
		handleMarshrutWorkers,
		true,
		true,
	)

	const meaningBg = meaningOfBg(t)

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
					{t('production')}
				</h1>

				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14" />

				<div className="mt-5">
					<h2 className="text-lg font-bold mb-4 ml-10 ">
						{t('inProcessProduction')}
					</h2>
					{/* Buyoda ikkinchi tog'riroq yo'l bilan ham qilsa bolar ekan oldin etilamagan edi bu TZ da buni backenddan olsa ham bolar ekan filtera isStarted Yes va isReady No larni olib chiqsa kifoya qiladi user xar gal skaner qilganda boshqattan get qilinadi  */}
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 m-5">
						{Object.entries(activeTimers).map(([docNum, data]) => (
							<Card key={docNum} className="relative">
								<div className="p-6">
									<div className="flex items-center justify-between">
										<span className="font-medium">
											{docNum}
										</span>
										<div className="flex items-center gap-2">
											<Timer className="w-5 h-5" />
											<span className="text-xl font-bold">
												{formatTimeWithHours(
													data.timer,
												)}
											</span>{' '}
										</div>
									</div>
								</div>
							</Card>
						))}
					</div>
				</div>
				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14" />

				<Space className="my-5 ml-10">
					<button
						className={`${
							activeTab === 'tab1'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => {
							setActiveTab('tab1')
						}}
					>
						{t('isReadyForNextStage')}
					</button>
					<button
						className={`${
							activeTab === 'tab2'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold ml-3`}
						onClick={() => setActiveTab('tab2')}
					>
						{t('isDoing')}
					</button>
					<button
						className={`${
							activeTab === 'tab3'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold ml-3`}
						onClick={() => setActiveTab('tab3')}
					>
						{t('notReadyForNextStage')}
					</button>
				</Space>
				<div className="mt-5">{renderContent()}</div>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsModalVisible(false)
				}}
			/>
			<SuccessModalWithTimer
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModalWithTimer
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Layout>
	)
}

export default memo(ProductionManagement)
