import React, { useState, useRef, useEffect } from 'react'
import Layout from '../../../../components/Layout/index'
import {
	DatePicker,
	Select,
	Input,
	Button,
	AutoComplete,
	message,
	Tag,
} from 'antd'
import { useTranslation } from 'react-i18next'
import CreateUserModal from '../../../../components/Modal/createUserModal'
import SuccessModal from '../../../../components/Modal/SuccessModal'
import { http } from '../../../../services/http'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import { useSelector } from 'react-redux'
import ErrorModal from '../../../../components/Modal/ErrorModal'
import { useQuery } from '@tanstack/react-query'
import { get } from 'lodash'
import {
	fetchItemsSelect,
	fetchSuppliersSelect,
	fetchWarehouseSelect,
} from '../../../../utils/fetchData'
import { debouncedSet } from '../../../../utils/debounce'
import {
	formatNumberWithSpaces,
	numberWithSpacesIntl,
} from '../../../../utils/numberWithSpaces'
import dayjs from 'dayjs'

export default function CreatePurchaseOrder() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const successRef = useRef()
	const errorRef = useRef()
	const employeeId = useSelector((state) => state.main.employeeId)
	const salesPersonCode = useSelector((state) => state.main.salesPersonCode)

	const initItem = {
		ItemCode: '',
		itemName: '',
		width: '',
		height: '',
		ugpName: '',
		priceQ: 0,
		priceKvm: 0,
		quantity: '',
		U_Kvmetr: '',
		lineTotal: 0,
		disabled: false,
	}

	const [items, setItems] = useState([{ ...initItem }])
	const [loading, setLoading] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [currency, setCurrency] = useState('')
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
	const [comments, setComments] = useState('')
	const [searchItemName, setSearchItemName] = useState('')
	const [searchClientName, setSearchClientName] = useState('')
	const [searchWarehouseName, setSearchWarehouseName] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [clientName, setClientName] = useState('')
	const [warehouseName, setWarehouseName] = useState('')
	const [warehouseCode, setWarehouseCode] = useState('')

	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }])
	}

	const handleRemoveItem = (i) => {
		setItems((prev) => prev.filter((_, idx) => idx !== i))
	}

	const {
		data: clientsData = [],
		isLoading: clientsLoading,
		refetch: refetchSuppliers,
	} = useQuery({
		queryKey: [
			'suppliers',
			searchClientName,
			'20',
			'api/businesspartners/yetkazib-beruvchilar',
		],
		queryFn: fetchSuppliersSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: warehousesData = [], isLoading: warehousesLoading } =
		useQuery({
			queryKey: [
				'warehouses',
				searchWarehouseName,
				'20',
				'api/warehouses',
			],
			queryFn: fetchWarehouseSelect,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		})

	const { data: itemsData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['items', searchItemName, 'oyna', 'api/items/get-items'],
		queryFn: fetchItemsSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleItemChange = (value, i) => {
		const seacrhValue = value.split(' ,/ ')[0]
		setSearchItemName(seacrhValue)

		const selectedOption = itemsData?.find((item) => {
			const itemName = get(item, 'itemName', '')
			const itemCode = get(item, 'itemCode', '')

			return `${itemName} ,/ ${itemCode}` === value
		})
		if (selectedOption) {
			const itemCode = get(selectedOption, 'itemCode', '')
			const selectedItem = itemsData?.find(
				(item) => `${item.itemName} ,/ ${item.itemCode}` === value,
			)
			if (selectedItem) {
				const updatedItems = [...items]
				updatedItems[i] = {
					...updatedItems[i],
					ItemCode: selectedItem.itemCode,
					itemName: selectedItem.itemName,
					width: selectedItem.u_Width,
					height: selectedItem.u_Height,
					ugpName: selectedItem.ugpName,
					U_Kvmetr: selectedItem.u_Kvmetr,
					disabled: true,
				}
				setItems(updatedItems)
				message.success(itemCode)
			}
		}
	}

	const handleInputChange = (e, i, field) => {
		const rawInput = e.target.value

		// Sanitize the raw input (remove spaces and non-numeric characters)
		const sanitizedVal = rawInput
			.replace(/[^0-9.,]/g, '')
			.replace(/\s+/g, '')

		// Parse as number for calculations
		const val = parseFloat(sanitizedVal.replace(',', '.')) || 0
		const updatedItems = [...items]
		updatedItems[i][field] = val

		// Recalculate the lineTotal based on quantity, priceQ, or priceKvm
		const { quantity, priceQ, priceKvm, U_Kvmetr } = updatedItems[i]

		updatedItems[i].lineTotal =
			priceQ > 0
				? quantity * priceQ
				: priceKvm > 0
					? quantity * U_Kvmetr * priceKvm
					: 0

		setItems(updatedItems)
	}
	const handleItemNameChange = (val) => {
		debouncedSet(val, setSearchItemName)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setSearchClientName)
	}

	const handleWarehouseChange = (val) => {
		debouncedSet(val, setSearchWarehouseName)
	}

	const handleDateChange = (date, dateString) => {
		setDate(dateString)
	}

	const postData = async () => {
		setLoading(true)

		try {
			const documentLines = items.map((item) => ({
				itemCode: item.ItemCode,
				u_Kvmetr: item.quantity,
				unitPrice: item.priceKvm,
				currency: currency,
				quantity: item.U_Kvmetr * item.quantity,
				warehouseCode: warehouseCode,
			}))

			await http.post('api/purchase/xarid-uchun-buyurtma', {
				cardCode: cardCode,
				salesPersonCode: Number(salesPersonCode),
				documentsOwner: Number(employeeId),
				docDate: dayjs().format('YYYY-MM-DD'),
				docDueDate: date,
				comments: comments,
				docCurrency: currency,
				documentLines: documentLines,
			})
			successRef.current.open(t('sucCreated'))
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Layout>
			<div className="m-5 flex items-center gap-5">
				<div className="flex flex-col gap-2">
					<p>{t('suppliers')}</p>
					<div className="flex">
						<AutoComplete
							loading={clientsLoading}
							showSearch
							value={clientName}
							className="flex-1 h-[30px] w-[300px]"
							onSelect={(value) => {
								const val = value.split(' ,/ ')[0]

								setClientName(val)
								handleCardNameChange(val)
								const selectedOption = clientsData.find(
									(client) => {
										const cardName = get(
											client,
											'cardName',
											'',
										)
										const cardCode = get(
											client,
											'cardCode',
											'',
										)
										const phoneNumber = get(
											client,
											'Cellular',
											'',
										)
											? get(client, 'Cellular', '')
											: ''
										const formattedValue = [
											cardName,
											phoneNumber,
										]
											.filter(Boolean)
											.join(' ')
										return (
											`${formattedValue} ,/ ${cardCode}` ===
											value
										)
									},
								)

								if (selectedOption) {
									const cardCode = get(
										selectedOption,
										'cardCode',
										'',
									)
									setCardCode(cardCode)
									message.success(cardCode)
								}
							}}
							onChange={(val) => {
								const value = val.split(' ,/ ')[0]
								setClientName(value)
								handleCardNameChange(value)
							}}
							options={
								Array.isArray(clientsData)
									? clientsData.map((client) => ({
											value: `${client.cardName}${client.Cellular ? ` ${client.Cellular}` : ''} ,/ ${client.cardCode}`,
											label: (
												<div className="flex items-center justify-between">
													<span className="text-[12px] text-ellipsis">
														{client.cardName}
													</span>
													{client.Cellular && (
														<Tag
															className="text-[10px]  "
															color={'green'}
														>
															{client.Cellular
																? client.Cellular
																: ''}
														</Tag>
													)}
												</div>
											),
										}))
									: []
							}
						/>
						<button
							className="w-[30px] h-[30px] bg-blue-700 text-white text-lg"
							onClick={() => setIsModalVisible(true)}
						>
							+
						</button>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<p>{t('currency')}</p>
					<div className="flex">
						<Select
							value={currency}
							onChange={(value) => setCurrency(value)}
							className="w-[150px]"
						>
							<Select.Option key={1} value={'USD'}>
								USD
							</Select.Option>
							<Select.Option key={1} value={'UZS'}>
								UZS
							</Select.Option>
						</Select>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<p>{t('warehouses')}</p>
					<AutoComplete
						loading={warehousesLoading}
						showSearch
						value={warehouseName}
						className="flex-1 h-[30px] w-[300px]"
						onSelect={(value) => {
							const whsName = value.split(' ,/ ')[0]
							const whsCode = value.split(' ,/ ')[1]

							setWarehouseName(whsName)
							setWarehouseCode(whsCode)
							handleWarehouseChange(whsName)
							const selectedOption = warehousesData.find(
								(warehouse) => {
									const warehouseName = get(
										warehouse,
										'warehouseName',
										'',
									)
									const warehouseCode = get(
										warehouse,
										'warehouseCode',
										'',
									)
									const formattedValue = [
										warehouseName,
										warehouseCode,
									]
										.filter(Boolean)
										.join(' ')
									return (
										`${formattedValue} ,/ ${warehouseCode}` ===
										value
									)
								},
							)
							// if (selectedOption) {
							// 	const warehouseCode = get(
							// 		selectedOption,
							// 		'warehouseCode',
							// 		'',
							// 	)
							// 	setWarehouseCode(warehouseCode)
							// 	message.success(warehouseCode)
							// }
						}}
						onChange={(val) => {
							const value = val.split(' ,/ ')[0]
							setWarehouseName(value)
							handleWarehouseChange(value)
						}}
						options={
							Array.isArray(warehousesData)
								? warehousesData.map((warehouse) => ({
										value: `${warehouse.warehouseName} ,/ ${warehouse.warehouseCode}`,
										label: (
											<div className="flex items-center justify-between">
												<span className="text-[12px] text-ellipsis">
													{warehouse.warehouseName}
												</span>
												<Tag
													className="text-[10px]"
													color={'blue'} // You can change the color as needed
												>
													{warehouse.warehouseCode}
												</Tag>
											</div>
										),
									}))
								: []
						}
					/>
				</div>
			</div>
			<div className="overflow-y-auto my-8 ">
				<table className="w-full text-sm m-5 text-left rtl:text-right text-gray-500">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
						<tr>
							<th className="px-6 py-4 border text-center">
								{t('item')}
							</th>
							<th className="px-6 py-4 border text-center">{`${t('width')} (${t('mm')})`}</th>
							<th className="px-6 py-4 border text-center">{`${t('height')} (${t('mm')})`}</th>
							<th className="px-6 py-4 border text-center">
								{t('quantityN')}
							</th>
							<th className="px-6 py-4 border text-center">
								{t('kv.m')}
							</th>
							<th className="px-6 py-4 border text-center">
								{t('pricePerKv')}
							</th>

							<th className="px-6 py-4 border text-center">
								{t('totalSale')}
							</th>
							<th className="px-6 py-4 border text-center"></th>
						</tr>
					</thead>
					<tbody>
						{items.map((v, i) => (
							<tr className="bg-white hover:bg-gray-50 " key={i}>
								<td className="px-6 border py-4 ">
									<AutoComplete
										loading={itemsLoading}
										options={itemsData?.map((item) => ({
											value: `${item.itemName} ,/ ${item.itemCode}`,
											label: item.itemName,
										}))}
										className="w-[200px]"
										value={v.itemName}
										disabled={v.disabled}
										onSelect={(value) =>
											handleItemChange(value, i)
										}
										onChange={(val) => {
											const value = val.split(' ,/ ')[0]
											handleItemNameChange(value)

											const updatedItems = [...items]
											updatedItems[i] = {
												...updatedItems[i],
												itemName: value,
											}
											setItems(updatedItems)
										}}
										showSearch
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[100px]"
										type="number"
										placeholder={`${t('width')} (${t('mm')})`}
										value={v.width}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[100px]"
										type="number"
										placeholder={`${t('height')} (${t('mm')})`}
										value={v.height}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder={t('quantityN')}
										value={formatNumberWithSpaces(
											v.quantity,
										)}
										onChange={(e) =>
											handleInputChange(e, i, 'quantity')
										}
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder={t('kv.m')}
										value={numberWithSpacesIntl(
											v.quantity * v.U_Kvmetr,
										)}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<Input
										className="w-[80px]"
										type="text"
										placeholder="0"
										value={formatNumberWithSpaces(
											v.priceKvm,
										)}
										onChange={(e) =>
											handleInputChange(e, i, 'priceKvm')
										}
										disabled={v.priceQ > 0}
									/>
								</td>

								<td className="px-6 border py-4">
									<Input
										className="w-[120px]"
										type="text"
										placeholder={t('totalSale')}
										value={numberWithSpacesIntl(
											v.quantity *
												v.U_Kvmetr *
												v.priceKvm,
										)}
										disabled
									/>
								</td>
								<td className="px-6 border py-4">
									<div className="flex items-center gap-2">
										{items.length > 1 && (
											<Button
												type="danger"
												onClick={() =>
													handleRemoveItem(i)
												}
												className="bg-red-600 text-white"
											>
												-
											</Button>
										)}
										{i === items.length - 1 && (
											<Button
												type="primary"
												onClick={handleAddItem}
											>
												+
											</Button>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className="flex gap-10 mt-20 drop-shadow-lg bg-white p-5 rounded-lg">
				<div className="w-full flex flex-col  gap-6 ">
					<div className="flex flex-col items-start gap-3 ">
						<p className="font-nunito">{t('date')}</p>
						<DatePicker
							className={'w-full h-10'}
							onChange={handleDateChange}
							value={dayjs(date)}
							minDate={dayjs()}
						/>
					</div>
					<div className="flex flex-col items-start gap-3">
						<p className="font-nunito">{t('totalForDocument')}</p>
						<Input
							type="text"
							value={`${new Intl.NumberFormat('fr-FR').format(items.reduce((acc, row) => acc + Number(row.lineTotal || 0), 0))} ${currency}`}
							className="h-10 w-full"
							style={{ color: 'black' }}
							disabled
						/>
					</div>
				</div>

				<div className="w-full flex  h-full">
					<div className="flex flex-col items-start gap-3 w-full">
						<p className="font-nunito">{t('comments')}</p>
						<TextArea
							className={'w-full'}
							rows={6}
							onChange={(e) => {
								setComments(e.target.value)
							}}
						/>
					</div>
				</div>
			</div>
			<div className="flex items-center justify-end mt-10 mb-10 mr-10">
				<div className="flex items-center gap-10">
					<Button
						onClick={() => navigate(-1)}
						className="h-[30px] w-[140px]  bg-red-600 rounded-lg text-white "
					>
						{t('back')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading || cardCode === '' || currency === ''}
						className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
					>
						{t('save')}
					</Button>
				</div>
				<CreateUserModal
					visible={isModalVisible}
					onClose={() => {
						// refetchSuppliers()
						setIsModalVisible(false)
					}}
					onConfirmPost={(code) => {
						const data = `${code?.name} ${code?.phoneNumber ? code?.phoneNumber : ''}`
						setClientName(data)
						setCardCode(code?.cardCode)
					}}
				/>
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
					onConfirm={() => navigate('/purchase-order')}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</div>
		</Layout>
	)
}
