import React, { useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table, Input } from 'antd'
import ViewProductionWorkersModal from '../../../../components/Modal/viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../../../components/Modal/SuccessModalWithTimer'
import ErrorModalWithTimer from '../../../../components/Modal/ErrorModalWithTimer'
import { columnProductionWorkersNotReady } from '../../../../utils/columns.jsx'
import { fetchProductionWorkers } from '../../../../utils/fetchData.js'
import { useSelector } from 'react-redux'
import { debouncedSet } from '../../../../utils/debounce.js'
import {
	handleMarshrutWorkers,
	handleNavigateSeriesProdWorkers,
} from '../../../../utils/navigateToPdf'
import { getToday, isAfter, isBefore, isSameDay } from '../../../../utils/time'
import '../table.css'
import {
	switchBgColor,
	meaningOfBg,
} from '../../../../utils/switchBgProductionsTables'
import moment from 'moment'

const IsReadyNextStageNo = () => {
	const { t } = useTranslation()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const formattedDate = getToday()
	const successRef = useRef()
	const errorRef = useRef()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [inputDocNum, setInputDocNum] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(
		moment().endOf('year').format('YYYY-MM-DD'),
	)
	const [pageSize] = useState('10')
	const [isReadyForNextStage] = useState('No')
	const [isStarted] = useState('')
	const [isReady] = useState('')

	const meaningBg = meaningOfBg(t)

	const userRoleInfo = useMemo(
		() => ({
			department:
				roleNames.includes('admin') ||
				roleNames.includes('ProdManager') ||
				roleNames.includes('Worker')
					? ''
					: getMe?.department,
			service: roleNames.includes('admin') ? '' : getMe?.u_Service,
		}),
		[roleNames, getMe],
	)

	const {
		data: productionWorkersData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionWorkersNotReady',
			currentPage,
			pageSize,
			startDate,
			endDate,
			docNum,
			isStarted,
			isReady,
			isReadyForNextStage,
			userRoleInfo.department,
			userRoleInfo.service,
		],
		queryFn: fetchProductionWorkers,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (productionWorkersData.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleStartDate = (e) => setStartDate(e.target.value)
	const handleEndDate = (e) => setEndDate(e.target.value)
	const handleDocNumChange = (val) => debouncedSet(val, setDocNum)

	const columns = columnProductionWorkersNotReady(
		t,
		handleNavigateSeriesProdWorkers,
		handleDocNumChange,
		handleMarshrutWorkers,
		false,
		false,
	)

	return (
		<div key={'NotReady'}>
			<div className="mt-5 ml-2 sm:ml-10 flex gap-5 items-end">
				<div className="flex flex-col gap-3">
					<span className="font-bold text-sm">{t('docNum')}</span>
					<Input
						className="w-full sm:w-[250px] h-8"
						placeholder={t('docNum')}
						onChange={(e) => {
							setInputDocNum(e.target.value)
							handleDocNumChange(e.target.value)
						}}
						value={inputDocNum}
					/>
				</div>
				{/* <div className="flex flex-col gap-3">
					<p className="font-bold text-sm">{t('startDate')}</p>
					<Input
						type="date"
						value={startDate}
						className="w-[150px] h-8"
						onChange={handleStartDate}
						max={endDate}
						allowClear
					/>
				</div>
				<div className="flex flex-col gap-3">
					<p className="font-bold text-sm">{t('endDate')}</p>
					<Input
						type="date"
						value={endDate}
						className="w-[150px] h-8"
						onChange={handleEndDate}
						min={startDate}
						max={formattedDate}
						allowClear
					/>
				</div> */}
			</div>

			<div className="mt-10 mb-4 ml-10 flex flex-col justify-between sm:flex-row">
				<div className="flex gap-5 flex-wrap">
					{meaningBg.map((item) => (
						<div key={item.id} className="flex gap-1 items-center">
							<div
								className={`rounded-full ${item.className} w-[20px] h-[20px]`}
							></div>
							<span>{item.meaning}</span>
						</div>
					))}
				</div>
				<div className="flex sm:mr-10">
					<button
						onClick={handlePreviousPage}
						disabled={currentPage === 0}
						className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400"
					>
						{t('previous')}
					</button>
					<div className="bg-[#099204] w-[20px] h-[30px] flex items-center justify-center">
						<span className="text-center text-white">
							{currentPage + 1}
						</span>
					</div>
					<button
						onClick={handleNextPage}
						disabled={productionWorkersData.length !== 10}
						className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400"
					>
						{t('next')}
					</button>
				</div>
			</div>

			<div className="ml-10">
				<Table
					columns={columns}
					dataSource={productionWorkersData}
					pagination={false}
					className="mt-6 w-full sm:mt-10 ant-table-override"
					rowKey={(record) => record.key}
					bordered
					scroll={{ x: 900 }}
					loading={isLoading}
					rowClassName={switchBgColor}
				/>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>
			<SuccessModalWithTimer getRef={(r) => (successRef.current = r)} />
			<ErrorModalWithTimer getRef={(r) => (errorRef.current = r)} />
		</div>
	)
}

export default IsReadyNextStageNo
