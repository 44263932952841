import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../../../../services/http'
import { useSelector } from 'react-redux'
import SuccessModal from '../../../../../components/Modal/SuccessModal'
import ErrorModal from '../../../../../components/Modal/ErrorModal'
import { useQuery } from '@tanstack/react-query'
import CreateOutgoingPaymentModal from '../CreateAdmin'
import PaySupplierModal from '../PaySupplierAdmin'
const ChooseTypePayment = ({ visible, onClose }) => {
	const { t } = useTranslation()
	const successRef = useRef()
	const errorRef = useRef()

	const [isPayVendorModalVisible, setIsPayVendorModalVisible] =
		useState(false)
	const [isOutgoingModal, setIsOutgoingModal] = useState(false)

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('createOutgoing')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="65%"
			style={{ height: '70%' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="mt-10 flex items-center gap-10 justify-center">
					<Button
						type="primary"
						className="bg-basic "
						onClick={() => setIsPayVendorModalVisible(true)}
					>
						{t('paySupplier')}
					</Button>
					<Button
						type="primary"
						className="bg-green-700 hover:!bg-green-900 "
						onClick={() => setIsOutgoingModal(true)}
					>
						{t('addExpense')}
					</Button>
				</div>
			</div>
			<CreateOutgoingPaymentModal
				visible={isOutgoingModal}
				onClose={() => {
					setIsOutgoingModal(false)
					setTimeout(() => {
						onClose()
					}, 100)
				}}
			/>
			<PaySupplierModal
				visible={isPayVendorModalVisible}
				onClose={() => {
					setIsPayVendorModalVisible(false)
					setTimeout(() => {
						onClose()
					}, 100)
				}}
			/>
		</Modal>
	)
}

export default ChooseTypePayment
