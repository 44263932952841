import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnDebtorsSalesPerson } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import { debouncedSet } from '../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import { fetchSalesPersonDebtors } from '../../../utils/fetchData'

const DebtorsSalesPerson = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [slpName, setSlpName] = useState('')

	const { data = [], isLoading } = useQuery({
		queryKey: ['salesPersonDebtors', slpName],
		queryFn: fetchSalesPersonDebtors,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName)
	}

	const handleSeeBtnClick = (cardCode) => {
		navigate(`/see-sales?cardCode=${encodeURIComponent(cardCode)}`)
	}

	const columns = columnDebtorsSalesPerson(
		t,
		handleSlpNameChange,
		handleSeeBtnClick,
	)

	return (
		<Layout>
			<Header
				title={'slpDebtors'}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
			/>
		</Layout>
	)
}

export default DebtorsSalesPerson
