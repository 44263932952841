import {
	Modal,
	Select,
	Table,
	Tooltip,
	Button,
	AutoComplete,
	message,
	Tag,
} from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import ConfirmExitModal from '../ConfirmExitModal'
import { http } from '../../../services/http'
import { numberWithSpacesIntl } from '../../../utils/numberWithSpaces'
import _ from 'lodash'
import moment from 'moment'
import { debouncedSet } from '../../../utils/debounce'
const ProcessedFileModal = ({ visible, data, onClose, code, refetch }) => {
	const { t } = useTranslation()

	const [tableData, setTableData] = useState([])
	const [dataCode, setDataCode] = useState('')
	const [originalData, setOriginalData] = useState([])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [bpNameSearch, setBpNameSearch] = useState('')
	const [bpData, setBpData] = useState([])
	const [saveLoading, setSaveLoading] = useState(false)
	const [postLoading, setPostLoading] = useState(false)

	const paymentFor = [
		{ value: 'A', label: t('account') },
		{ value: 'C', label: t('customer') },
		{ value: 'S', label: t('supplier') },
	]

	useEffect(() => {
		fetchBpName(bpNameSearch)
	}, [bpNameSearch, data])

	const handleSearchBp = (val) => {
		debouncedSet(val, setBpNameSearch)
	}

	const handleBpChange = (val, label, index, record, searchVal) => {
		const newData = structuredClone(tableData)
		if (record?.u_DocType === 'A') {
			newData[index].u_AcctCode = label?.cardCode || ''
			newData[index].u_AcctName = val
		} else {
			newData[index].u_BPCode = label?.cardCode || ''
			newData[index].u_BPName = val
		}
		setTableData(newData)
		handleSearchBp(searchVal)
	}

	const fetchData = async (docEntry) => {
		setLoading(true)
		try {
			const { data } = await http.get(
				`api/bankstatements/bank-statements-for-sap?docEntry=${docEntry}&pageSize=1000`,
			)
			setOriginalData(data)
			setTableData(data)
		} catch (error) {
			message.error(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	const fetchBpName = async (value) => {
		try {
			const { data } = await http.get(
				`api/bankstatements/business-partners?cardName=${value}`,
			)
			setBpData(data)
		} catch (err) {
			message.error(err?.response?.data?.message)
		}
	}

	useEffect(() => {
		if (data) {
			fetchData(data?.u_ProcessedDataID)
			setDataCode(data?.code)
		}
	}, [data, code, visible])

	const columns = [
		{
			title: t('paymentType'),
			dataIndex: 'u_PaymentType',
			key: 'u_PaymentType',
			render: (text) => {
				const color = text === 'Приход' ? 'green' : 'yellow'
				return (
					<Tag color={color}>
						<span>{text}</span>
					</Tag>
				)
			},
		},
		{
			title: 'Line Id',
			dataIndex: 'lineId',
			key: 'lineId',
		},

		{
			title: t('docDate'),
			dataIndex: 'u_DocDate',
			key: 'u_DocDate',
			render: (text) => {
				return <span>{moment(text).format('DD.MM.YYYY HH:mm:ss')}</span>
			},
		},
		{
			title: t('paymentFor'),
			dataIndex: 'u_DocType',
			key: 'u_DocType',
			render: (text, record, index) => {
				return (
					<Select
						value={text}
						options={paymentFor}
						className="w-[180px]"
						onChange={(val) => {
							const newData = structuredClone(tableData)
							newData[index].u_DocType = val
							newData[index].u_BPName = ''
							newData[index].u_BPCode = ''
							newData[index].u_AcctName = ''
							newData[index].u_AcctCode = ''
							setTableData(newData)
						}}
						disabled={record?.u_IsPaymentMade === 'Yes'}
					/>
				)
			},
		},

		{
			title: t('bpName'),
			dataIndex: 'u_BPName',
			key: 'u_BPName',
			render: (text, record, index) => {
				return (
					<AutoComplete
						value={
							record?.u_DocType === 'A'
								? record?.u_AcctName
								: record?.u_BPName
						}
						options={bpData
							.filter(
								(item) => item.cardType === record?.u_DocType,
							)
							.map((item) => ({
								value: item.cardName,
								label: (
									<div className="flex items-center justify-between">
										<span>{item.cardName}</span>
									</div>
								),
								cardCode: item.cardCode,
								cardName: item.cardName,
							}))}
						disabled={
							!record?.u_DocType ||
							record?.u_IsPaymentMade === 'Yes'
						}
						className="w-[250px]"
						onChange={(val, label) => {
							handleBpChange(val, label, index, record, val)
						}}
						onSelect={(val, label) => {
							handleBpChange(val, label, index, record, '')
						}}
					/>
				)
			},
		},
		{
			title: t('cash'),
			dataIndex: 'cash',
			key: 'cash',
			render: (text) => {
				return <span>{code}</span>
			},
		},
		{
			title: t('paidSum'),
			dataIndex: 'u_PaidSum',
			key: 'u_PaidSum',
			render: (text) => {
				return <span>{numberWithSpacesIntl(text)}</span>
			},
		},
		{
			title: t('docRate'),
			dataIndex: 'u_ExchangeRate1',
			key: 'u_ExchangeRate1',
			render: (text) => {
				return <span>{numberWithSpacesIntl(text)}</span>
			},
		},
		{
			title: t('comment'),
			dataIndex: 'u_Comment',
			key: 'u_Comment',
			width: 200,
			render: (text, record) => {
				return (
					<Tooltip title={text}>
						<div className="cursor-pointer">{`${text && text.length > 30 ? `${text.slice(0, 30)}...` : text}`}</div>
					</Tooltip>
				)
			},
		},
		{
			title: t('paymentNum'),
			dataIndex: 'u_PaymentNum',
			key: 'u_PaymentNum',
		},
		{
			title: t('paymentIsMade'),
			dataIndex: 'u_IsPaymentMade',
			key: 'u_IsPaymentMade',
			render: (text) => {
				return (
					<span>
						{text === 'Yes'
							? t('paidToSap')
							: text === 'No'
								? t('notPaid')
								: text}
					</span>
				)
			},
		},
	]

	const handleSaveClick = async (data) => {
		setSaveLoading(true)
		const patchData = data.map((item) => {
			return {
				docEntry: item.docEntry,
				lineId: item.lineId,
				u_DocType: item.u_DocType,
				u_BPCode: item.u_DocType === 'A' ? null : item.u_BPCode,
				u_BPName: item.u_DocType === 'A' ? null : item.u_BPName,
				u_AcctCode: item.u_DocType === 'A' ? item.u_AcctCode : null,
				u_AcctName: item.u_DocType === 'A' ? item.u_AcctName : null,
				u_CashRegCode: code,
			}
		})
		try {
			const { data } = await http.patch(
				`api/bankstatements/bank-statements-for-sap`,
				patchData,
			)
			message.success('Успешно сохранено!')
			closeModal()
		} catch (err) {
			message.error(err?.response?.data?.message)
		} finally {
			setSaveLoading(false)
		}
	}

	const closeModal = () => {
		resetForm()
		onClose()
	}

	const handleUploadClick = async (data) => {
		setPostLoading(true)
		const postData = data
			.filter((item) => {
				return item.u_IsPaymentMade === 'No'
			})
			.map((item) => {
				return {
					docEntry: item.docEntry,
					docNum: item.docNum,
					u_Status: item.u_Status,
					u_PaymentType: item.u_PaymentType,
					u_DocDate: item.u_DocDate,
					u_PaidSum: item.u_PaidSum,
					u_ExchangeRate1: item.u_ExchangeRate1,
					u_ExchangeRate2: item.u_ExchangeRate2,
					u_Comment: item.u_Comment,
					u_PaymentNum: item.u_PaymentNum,
					u_IsPaymentMade: item.u_IsPaymentMade,
					lineId: item.lineId,
					u_DocType: item.u_DocType,
					u_BPCode: item.u_DocType === 'A' ? null : item.u_BPCode,
					u_BPName: item.u_DocType === 'A' ? null : item.u_BPName,
					u_AcctCode: item.u_DocType === 'A' ? item.u_AcctCode : null,
					u_AcctName: item.u_DocType === 'A' ? item.u_AcctName : null,
					u_CashRegCode: code,
				}
			})

		try {
			await http.post(
				`api/bankstatements/bank-statements-for-sap/upload-to-sap/${dataCode}`,
				postData,
			)
			closeModal()
			message.success('Успешно сохранено!')
			refetch()
		} catch (error) {
			message.error(error?.response?.data?.message)
		} finally {
			setPostLoading(false)
		}
	}

	const onCloseModal = () => {
		setIsModalOpen(true)
	}

	const handleCloseExitModal = () => {
		setIsModalOpen(false)
		setTimeout(() => {
			onClose()
			resetForm()
		}, 500)
	}

	const handleClickNoExitModal = () => {
		setIsModalOpen(false)
	}

	const resetForm = () => {
		setTableData([])
		setOriginalData([])
		setBpNameSearch('')
		setSaveLoading(false)
		setPostLoading(false)
	}

	const isDataSimilar = _.isEqual(tableData, originalData)

	return (
		<Modal
			title={
				<div>
					<h1 className="font-nunito text-xl font-extrabold text-basic">
						{t('code')} - {data?.code}
					</h1>
					<h1 className="font-nunito text-xl font-extrabold text-basic">
						{t('outgoingOriginalPayments')} - {code}
					</h1>
				</div>
			}
			open={visible}
			onCancel={onCloseModal}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="90%"
			style={{ height: '80%' }}
		>
			<div className="mt-10 w-full">
				<div className="flex justify-end">
					<Button
						type="primary"
						onClick={() => handleSaveClick(tableData)}
						disabled={isDataSimilar}
						loading={saveLoading}
						className="mr-5 bg-basic"
					>
						{t('saveChanges')}
					</Button>
					<Button
						type="primary"
						onClick={() => handleUploadClick(tableData)}
						disabled={
							!tableData
								?.filter(
									(item) => item.u_IsPaymentMade === 'No',
								)
								.every(
									(item) => item.u_BPCode || item.u_AcctCode,
								) ||
							!isDataSimilar ||
							tableData.every(
								(item) => item.u_IsPaymentMade === 'Yes',
							) ||
							!tableData?.length
						}
						loading={postLoading}
						className="mr-5 bg-basic"
					>
						{t('uploadToSAP')}
					</Button>
				</div>
				<Table
					columns={columns}
					dataSource={tableData}
					pagination={false}
					loading={loading}
					className="border-1 mt-10"
					scroll={{ x: '1200px' }}
				/>
			</div>
			<ConfirmExitModal
				visible={isModalOpen}
				handleClickNo={handleClickNoExitModal}
				handleCloseModal={handleCloseExitModal}
			/>
		</Modal>
	)
}

export default ProcessedFileModal
