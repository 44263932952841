import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table } from 'antd'
import ViewProductionWorkersModal from '../../viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../SuccessModalWithTimer/index.jsx'
import ErrorModalWithTimer from '../../ErrorModalWithTimer/index.jsx'
import { columnProductionInSalesModal } from '../../../../utils/columns.jsx'
import { fetchProductionInSalesModal } from '../../../../utils/fetchData.js'
import moment from 'moment'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import './table.css'
import { isAfter, isBefore, isSameDay } from '../../../../utils/time'
import {
	switchBgColor,
	meaningOfBg,
} from '../../../../utils/switchBgProductionsTables'

const ProductionInViewSales = ({ docEntry }) => {
	const { t } = useTranslation()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const successRef = useRef()
	const errorRef = useRef()
	const tableRef = useRef(null)

	const formattedDate = moment()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [productionWorkersData, setProductionWorkersData] = useState([])
	const [hasMore, setHasMore] = useState(false)
	const [isFetching, setIsFetching] = useState(false)

	const pageSize = 10
	const isStarted = ''
	const isReady = ''
	const isReadyForNextStage = ''

	const meaningBg = meaningOfBg(t)

	const {
		data: productionWorker = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionSalesOrder',
			currentPage,
			pageSize,
			docEntry,
			isStarted,
			isReady,
			isReadyForNextStage,
		],
		queryFn: fetchProductionInSalesModal,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: !isFetching,
	})

	useEffect(() => {
		if (productionWorker.length === 0) {
			setHasMore(false)
		} else {
			setProductionWorkersData((prev) => [...prev, ...productionWorker])
			setHasMore(productionWorker.length === pageSize)
		}
		setIsFetching(false)
	}, [productionWorker])

	const handleScroll = _.debounce(() => {
		if (tableRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = tableRef.current

			if (
				scrollTop + clientHeight >= scrollHeight - 100 &&
				!isFetching &&
				hasMore
			) {
				setIsFetching(true)
				setCurrentPage((prev) => prev + 1)
			}
		}
	}, 200)

	useEffect(() => {
		const tableContainer =
			tableRef.current?.getElementsByClassName('ant-table-body')[0]
		if (tableContainer) {
			tableContainer.addEventListener('scroll', handleScroll)
			return () => {
				tableContainer.removeEventListener('scroll', handleScroll)
				handleScroll.cancel()
			}
		}
	}, [hasMore, isFetching])

	useEffect(() => {
		if (isFetching) {
			refetch()
		}
	}, [isFetching, refetch])

	const columns = columnProductionInSalesModal(t)

	return (
		<>
			<div className="px-4">
				<div className="mt-10 mb-4 flex flex-col justify-between sm:flex-row">
					<div className="flex gap-5 flex-wrap">
						{meaningBg.map((item) => (
							<div
								key={item.id}
								className="flex gap-1 items-center"
							>
								<div
									className={`rounded-full ${item.className} w-[20px] h-[20px]`}
								></div>
								<span>{item.meaning}</span>
							</div>
						))}
					</div>
				</div>

				<div
					ref={tableRef}
					className="max-h-[500px] mt-5 overflow-auto border rounded-lg shadow-md"
				>
					<Table
						columns={columns}
						dataSource={productionWorkersData}
						pagination={false}
						className="w-full ant-table-override"
						rowKey={(record) => record.key}
						bordered
						scroll={{
							y: 500,
							x: 900,
							scrollToFirstRowOnChange: true,
						}}
						sticky={{
							offsetHeader: 0,
							offsetScroll: 0,
							getContainer: () => window,
						}}
						loading={isLoading || isFetching}
						rowClassName={switchBgColor}
					/>
				</div>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>

			<SuccessModalWithTimer
				getRef={(r) => {
					successRef.current = r
				}}
			/>

			<ErrorModalWithTimer
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</>
	)
}

export default ProductionInViewSales
