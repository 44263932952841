import React, { useState, useRef } from 'react'
import Layout from '../../../../components/Layout'
import {
	message,
	Input,
	Select,
	Table,
	Button,
	Modal,
	Checkbox,
	AutoComplete,
	Space,
	Tag,
	InputNumber,
} from 'antd'
import { http } from '../../../../services/http'
import TextArea from 'antd/lib/input/TextArea'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CreteCustomerModal from '../../../../components/Modal/createCustomerModal'
import SuccessModal from '../../../../components/Modal/SuccessModal'
import ErrorModal from '../../../../components/Modal/ErrorModal'
import moment from 'moment'
import { debouncedSet } from '../../../../utils/debounce'
import { get } from 'lodash'
import { submitSalesOrder } from '../../../../slice/salesSlice'
import semicircle from '../../../../assets/images/semiricle.png'
import oval from '../../../../assets/images/oval.png'
import Parallelogram from '../../../../assets/images/parallelogram.png'
import Pencil from '../../../../assets/images/pencil.jpg'
import {
	fetchClientsSelect,
	fetchItemsSelect,
	fetchDepartments,
	fetchSalesPerson,
	fetchWarehouse,
	fetchPackageServicesNoPagination,
} from '../../../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import CopyIcon from '../../../../assets/icons/CopySvg'
import ConfirmExitModal from '../../../../components/Modal/ConfirmExitModal/index'
import beshburchak from '../../../../assets/images/beshburchak.png'
import sakkizBurchak from '../../../../assets/images/sakkizBurchak.svg.png'
import rectangle from '../../../../assets/images/rectangle.png'
import tortburchak from '../../../../assets/images/tortburchak.png'
import dumaloq from '../../../../assets/images/dumaloq.png'
import rhombus from '../../../../assets/images/rhombus.png'
import uchburchak from '../../../../assets/images/uchburchak.png'
import ViewIcon from '../../../../assets/icons/viewSvg.jsx'

import ViewDepartmentsBalanceModal from '../../../../components/Modal/viewDepartmentsBalanceModal'
import {
	formatNumberWithSpaces,
	numberWithSpacesIntl,
	removeSpaces,
} from '../../../../utils/numberWithSpaces'
import {
	calculateTotalWithKoef,
	calculateLineTotal,
	calculateMetrTotalWithKoef,
} from '../../../../utils/calculations'
import WarningModal from '../../../../components/Modal/WarningModal/index'

// This is the hardest screen in our project and I know that i have to optimize my codes in this page when i will be free i will do it

const CreateSales = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const successRef = useRef()
	const errorRef = useRef()
	const warningRef = useRef()

	const { roleNames, getMe, slpCode, department } = useSelector(
		(state) => state.main,
	)
	const { loading, success, error, progress } = useSelector(
		(state) => state.sales,
	)

	const [clientCode, setClientCode] = useState('')
	const [clientName, setClientName] = useState('')
	const [searchClientName, setSearchClientName] = useState('')
	const [searchItemName, setSearchItemName] = useState('')
	const [date, setDate] = useState('')
	const [currency, setCurrency] = useState('UZS')
	const [comments, setComments] = useState('')
	const [sendFile, setSendFile] = useState(false)
	const [imageList, setImageList] = useState([
		{ file: null, path: '', imgLoading: false },
	])
	const [whs, setWhs] = useState(get(getMe, 'u_WareHouse', ''))
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
	const [isViewBpBalanceModalVisible, setIsViewBpBalanceModalVisible] =
		useState(false)
	const [isConfirmServiceModalVisible, setIsConfirmServiceModalVisible] =
		useState(false)
	const [activeTab, setActiveTab] = useState('tab1')
	const [btnLoading, setIsBtnLoading] = useState(false)
	const [isDiscountBigger, setIsDiscountBigger] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedServices, setSelectedServices] = useState([])
	const [currentRecord, setCurrentRecord] = useState(null)
	const [departmentAdmin, setDepartmentAdmin] = useState(department)
	const [slpAdmin, setSlpAdmin] = useState(get(getMe, 'salesPersonCode', ''))
	const [dateSale, setDateSale] = useState(moment().format('YYYY-MM-DD'))
	const [bpDebt, setBpDebt] = useState('')
	const [docTotalWithDiscount, setDocTotalWithDiscount] = useState(0)
	const [inputDocTotal, setInputDocTotal] = useState(0)
	const [inputValues, setInputValues] = useState({})
	const [inputParametrQuantity, setInputParametrQuantity] = useState({})
	const [tableData, setTableData] = useState([
		{
			key: '1',
			itemCode: null,
			itemName: null,
			width: '',
			height: '',
			quantity: '',
			ugpName: '',
			price: '',
			currency: currency,
			lineTotal: '',
			lineNum: 0,
			u_Timer: '',
			disabled: false,
			koefitsent: 1,
			selectedServices: [],
			amountInWhs: 0,
		},
	])

	const docTotal = tableData.reduce((acc, row) => {
		const koef = row.koefitsent || 1

		const selectedPrices = row?.selectedServices?.reduce((acc, service) => {
			if (service.ugpName !== 'шт' && service.ugpName !== 'м') {
				return acc + service.price
			}
			return acc
		}, 0)

		const shtPrices = row?.selectedServices?.reduce((acc, service) => {
			if (service.ugpName === 'шт') {
				return acc + service.price * service.quantity
			}
			return acc
		}, 0)

		const metrPricesServices = row?.selectedServices?.reduce(
			(acc, service) => {
				if (service.ugpName === 'м' && !service.isParametr) {
					return (
						acc +
						service.price *
							((((service.width + service.height) * 2) / 1000) *
								service.quantity)
					)
				}
				return acc
			},
			0,
		)
		const metrPricesParametr = row?.selectedServices?.reduce(
			(acc, service) => {
				if (service.ugpName === 'м' && service.isParametr) {
					return (
						acc +
						service.itemQuantity *
							(service.price *
								(service.u_ParametrQuantityValues *
									service.quantity))
					)
				}
				return acc
			},
			0,
		)

		const totalWithKoef = calculateTotalWithKoef(
			row.width,
			row.height,
			row.quantity,
			koef,
		)

		return (
			acc +
			(calculateLineTotal(
				row.price,
				selectedPrices || 0,
				totalWithKoef || 0,
			) +
				(shtPrices || 0) +
				(metrPricesServices || 0) +
				(metrPricesParametr || 0))
		)
	}, 0)

	const totalKvm = new Intl.NumberFormat().format(
		tableData.reduce(
			(acc, row) =>
				acc +
				(parseFloat(
					row.quantity * ((row.width * row.height) / 1000000),
				) * row.koefitsent || 0),
			0,
		),
	)

	const totalPerimetr = new Intl.NumberFormat().format(
		tableData.reduce((acc, row) => acc + row.totalPerimetr || 0, 0),
	)

	const fetchItemsGeometricShapes = async () => {
		try {
			let url = `api/items/get-items?category=геометрические фигуры`
			const { data } = await http.get(url)
			const formattedData = data.data.map((item) => ({
				...item,
				image: generateImage(item.itemCode),
			}))
			return formattedData
		} catch (error) {
			console.log(error)
		}
	}

	const fetchBpBalance = async ({ queryKey }) => {
		const [, clientCode] = queryKey
		if (clientCode) {
			try {
				let url = `api/businesspartners/get-business-partners-balance?cardCode=${clientCode}`
				const { data } = await http.get(url)
				setBpDebt(
					data[0]?.departments?.reduce(
						(acc, item) => acc + item.balance,
						0,
					),
				) || 0
				return data
			} catch (error) {
				console.log(error)
			}
		}
	}

	const fetchItemsInWhs = async (itemCode, index) => {
		if (itemCode) {
			try {
				let url = `api/items/inventory-items?itemCode=${itemCode}`
				const { data } = await http.get(url)
				const newTableData = [...tableData]
				newTableData[index].amountInWhs = data.data[0]?.amountInWhs || 0
				setTableData(newTableData)
				console.log('tableDataAfterOnHand', newTableData)
			} catch (error) {
				console.log(error)
			}
		}
	}

	const { data: packageServicesData = [] } = useQuery({
		queryKey: ['packageServices'],
		queryFn: fetchPackageServicesNoPagination,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: bpBalanceData = [] } = useQuery({
		queryKey: ['bpBalance', clientCode],
		queryFn: fetchBpBalance,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const {
		data: clientsData = [],
		isLoading: loadingClient,
		refetch,
	} = useQuery({
		queryKey: [
			'clients',
			searchClientName,
			'20',
			'api/businesspartners/mijozlar',
		],
		queryFn: fetchClientsSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: itemsData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['items', searchItemName, 'oyna', 'api/items/get-items'],
		queryFn: fetchItemsSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchInterval: 60000,
	})

	const { data: serviceItemsData = [] } = useQuery({
		queryKey: ['itemsService', '', 'услуги', 'api/items/get-items'],
		queryFn: fetchItemsSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchInterval: 60000,
	})

	const { data: geometricShapesData = [] } = useQuery({
		queryKey: ['geometricShapes'],
		queryFn: fetchItemsGeometricShapes,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchInterval: 60000,
	})

	const { data: warehouseData = [] } = useQuery({
		queryKey: ['warehouses'],
		queryFn: fetchWarehouse,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: departmentData = [] } = useQuery({
		queryKey: ['departments'],
		queryFn: fetchDepartments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: slpData = [] } = useQuery({
		queryKey: ['salesPerson'],
		queryFn: fetchSalesPerson,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const columns = [
		{
			title: t('item'),
			dataIndex: 'itemCode',
			key: 'itemCode',
			fixed: 'left',
			width: 200,
			render: (_, record, index) => (
				<AutoComplete
					loading={itemsLoading}
					options={itemsData?.map((item) => ({
						value: item.itemCode,
						label: item.itemName,
						data: {
							ugpName: item.ugpName,
							price: item.price,
							koefitsent: item.u_DefaultCoefficient || 1,
							u_Number: item.u_Number,
							u_Timer: item.u_Timer,
						},
					}))}
					className="w-full "
					value={record.itemName}
					onSelect={async (value, label) => {
						let url = `api/items/inventory-items?itemCode=${value}`
						const { data } = await http.get(url)
						const amountInWhs = data.data[0]?.onHandSum

						const updatedData = tableData.map((row) =>
							row.key === record.key
								? {
										...row,
										itemCode: label.value,
										itemName: label.label || '',
										quantity: '',
										ugpName: label?.data?.ugpName || '',
										price: label?.data?.price || '',
										lineTotal: '',
										disabled: true,
										koefitsent:
											label?.data?.koefitsent || 1,
										u_Number: label?.data?.u_Number || '',
										u_Timer: label?.data?.u_Timer || '',
										amountInWhs,
									}
								: row,
						)
						handleItemNameChange('')
						setTableData(updatedData)
					}}
					disabled={record.disabled}
					onChange={(val) => {
						const value = val || ''
						handleItemNameChange(value)
						const updatedData = tableData.map((row) =>
							row.key === record.key
								? {
										...row,
										itemName: value || '',
									}
								: row,
						)
						setTableData(updatedData)
					}}
					showSearch
				/>
			),
		},
		{
			title: `${t('width')} (${t('mm')})`,
			dataIndex: 'width',
			key: 'width',
			width: 150,
			render: (_, record) => (
				<Input
					value={formatNumberWithSpaces(record.width)}
					className={'w-full'}
					onChange={(e) => {
						const updatedData = tableData.map((row) => {
							if (row.key === record.key) {
								const rawInput = e.target.value

								// Sanitize the raw input (remove spaces and non-numeric characters)
								const sanitizedWidth = rawInput
									.replace(/[^0-9.,]/g, '')
									.replace(/\s+/g, '')

								// Parse as number for calculations
								const width =
									parseFloat(
										sanitizedWidth.replace(',', '.'),
									) || 0

								return {
									...row,
									width,
								}
							}
							return row
						})
						setTableData(updatedData)
					}}
				/>
			),
		},
		{
			title: `${t('height')} (${t('mm')})`,
			dataIndex: 'height',
			key: 'height',
			width: 150,
			render: (_, record) => (
				<Input
					value={formatNumberWithSpaces(record.height)}
					className={'w-full'}
					onChange={(e) => {
						const updatedData = tableData.map((row) => {
							if (row.key === record.key) {
								const rawInput = e.target.value

								const height =
									parseFloat(
										removeSpaces(rawInput).replace(
											',',
											'.',
										),
									) || 0

								return {
									...row,
									height,
								}
							}
							return row
						})

						setTableData(updatedData)
					}}
				/>
			),
		},
		{
			title: t('quantity'),
			dataIndex: 'quantity',
			key: 'quantity',
			width: 150,
			render: (_, record) => (
				<Input
					value={formatNumberWithSpaces(record.quantity)}
					className="w-full"
					onChange={(e) => {
						const rawInput = e.target.value

						const updatedData = tableData.map((row) => {
							if (row.key === record.key) {
								const quantity =
									parseFloat(
										removeSpaces(rawInput).replace(
											',',
											'.',
										),
									) || 0
								return {
									...row,
									quantity,
								}
							}
							return row
						})

						setTableData(updatedData)
					}}
				/>
			),
		},
		{
			title: t('kv.m'),
			dataIndex: 'ugpName',
			key: 'ugpName',
			width: 150,
			render: (_, record) => {
				const koef = 1
				const totalWithKoef =
					((record.width * record.height) / 1000000) *
					parseFloat(record.quantity || 0) *
					koef
				return (
					<span className="w-full">
						{new Intl.NumberFormat().format(totalWithKoef)}
					</span>
				)
			},
		},
		{
			title: t('pricePerKv'),
			dataIndex: 'price',
			key: 'price',
			width: 170,
			render: (_, record) => (
				<span className="w-full">
					{new Intl.NumberFormat().format(record.price || 0)}
				</span>
			),
		},
		// Uslugi modalga kirish bu joyda agar ugpName sht bolsa uni narxga qoshmaslik aks xolda uni userga usligi yozuvi orniga korastish kerak
		{
			title: t('services'),
			key: 'services',
			width: 120,
			render: (_, record) => (
				<div className="flexw- full items-center justify-center">
					<Button
						type="primary"
						onClick={() => {
							setIsModalOpen(true)
							setCurrentRecord(record)
							setSelectedServices(record.selectedServices || [])
						}}
					>
						{record.width > 0 ||
						record.height > 0 ||
						record.quantity > 0
							? new Intl.NumberFormat().format(
									record.selectedServices
										? record.selectedServices.reduce(
												(acc, service) => {
													if (
														service.ugpName !==
															'шт' &&
														service.ugpName !== 'м'
													) {
														return (
															acc + service.price
														)
													}
													return acc
												},
												0,
											) *
												(record.quantity *
													((record.width *
														record.height) /
														1000000) *
													1) +
												record.selectedServices.reduce(
													(acc, service) => {
														if (
															service.ugpName ===
															'шт'
														) {
															return (
																acc +
																service.price *
																	service.quantity
															)
														}
														return acc
													},
													0,
												) +
												record.selectedServices.reduce(
													(acc, service) => {
														if (
															service.ugpName ===
																'м' &&
															!service.isParametr
														) {
															return (
																acc +
																service.price *
																	((((service.width +
																		service.height) *
																		2) /
																		1000) *
																		service.quantity)
															)
														}
														return acc
													},
													0,
												) +
												record.selectedServices.reduce(
													(acc, service) => {
														if (
															service.ugpName ===
																'м' &&
															service.isParametr
														) {
															return (
																acc +
																service.itemQuantity *
																	(service.price *
																		(service.u_ParametrQuantityValues *
																			service.quantity))
															)
														}
														return acc
													},
													0,
												)
										: 0,
								)
							: t('services')}
					</Button>
				</div>
			),
		},
		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			width: 170,
			render: (_, record) => {
				const selectedPrices = record?.selectedServices?.reduce(
					(acc, service) => {
						if (
							service.ugpName !== 'шт' &&
							service.ugpName !== 'м'
						) {
							return acc + service.price
						}
						return acc
					},
					0,
				)

				const shtPrices = record?.selectedServices?.reduce(
					(acc, service) => {
						if (service.ugpName === 'шт') {
							return acc + service.price * service.quantity
						}
						return acc
					},
					0,
				)
				const koef = 1
				const totalWithKoef = calculateTotalWithKoef(
					record.width,
					record.height,
					record.quantity,
					koef,
				)

				const metrPricesServices = record?.selectedServices?.reduce(
					(acc, service) => {
						if (service.ugpName === 'м' && !service.isParametr) {
							return (
								acc +
								service.price *
									((((service.width + service.height) * 2) /
										1000) *
										service.quantity)
							)
						}
						return acc
					},
					0,
				)
				const metrPricesParametr = record?.selectedServices?.reduce(
					(acc, service) => {
						if (service.ugpName === 'м' && service.isParametr) {
							return (
								acc +
								service.itemQuantity *
									(service.price *
										(service.u_ParametrQuantityValues *
											service.quantity))
							)
						}
						return acc
					},
					0,
				)
				const lineTotal =
					calculateLineTotal(
						record.price,
						selectedPrices,
						totalWithKoef,
					) +
					shtPrices +
					metrPricesServices +
					metrPricesParametr

				return (
					<span>
						{new Intl.NumberFormat().format(lineTotal || 0)}
					</span>
				)
			},
		},
		{
			title: t('actions'),
			key: 'actions',
			width: 100,

			render: (_, record) => (
				<div className="flex gap-2 items-center justify-center">
					<Button type="primary" onClick={addRow}>
						+
					</Button>
					<Button type="primary" onClick={() => copyRow(record)}>
						<CopyIcon />
					</Button>
					<Button
						danger
						type={'primary'}
						onClick={() => removeRow(record.key)}
						disabled={tableData.length === 1}
					>
						-
					</Button>
				</div>
			),
		},
	]

	const columnsServices = [
		{
			title: t('service'),
			dataIndex: 'itemName',
			key: 'itemName',
			render: (text, record) => (
				<span>
					<span className="font-bold">
						{record.u_Number ? `${record.u_Number} ` : ''}
					</span>
					{`${text}`}
				</span>
			),
		},
		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text) => (
				<span>{text !== null ? numberWithSpacesIntl(text) : ''}</span>
			),
		},
		{
			title: t('measureUnit'),
			dataIndex: 'ugpName',
			key: 'ugpName',
			render: (text) => <span>{text !== null ? text : ''}</span>,
		},
		{
			title: t('quantity'),
			dataIndex: 'quantity',
			key: 'quantity',
			width: 150,
			render: (_, record) =>
				record.ugpName === 'шт' ? (
					<Input
						value={
							selectedServices.find(
								(service) =>
									service.itemCode === record.itemCode,
							)?.quantity || ''
						}
						key={`${currentRecord.key}`}
						className={'w-full'}
						disabled={
							!selectedServices.some(
								(service) =>
									service.itemCode === record.itemCode,
							)
						}
						onChange={(e) => {
							const inputQuantity = e.target.value

							// Update only the service that matches the record's itemCode
							const updatedSelectedServices =
								selectedServices.map((service) =>
									service.itemCode === record.itemCode
										? {
												...service,
												quantity: inputQuantity,
											}
										: service,
								)

							setSelectedServices(updatedSelectedServices)

							// Update the tableData for the specific record
							const updatedData = tableData.map((row) => {
								if (row.key === record.key) {
									const selectedServicesTotal =
										updatedSelectedServices.reduce(
											(acc, service) =>
												acc +
												service.price *
													(service.quantity || 0),
											0,
										)
									const koef = 1
									const totalWithKoef =
										calculateTotalWithKoef(
											row.width,
											row.height,
											inputQuantity,
											koef,
										)
									const lineTotal = calculateLineTotal(
										row.price,
										selectedServicesTotal,
										totalWithKoef,
									)

									return {
										...row,
										quantity: inputQuantity,
										lineTotal,
									}
								}
								return row
							})

							setTableData(updatedData)
						}}
						onClick={(e) => {
							e.stopPropagation()
						}}
					/>
				) : (
					''
				),
		},
		{
			title: t('Select'),
			dataIndex: 'itemCode',
			key: 'select',
			render: (itemCode, record) => {
				const isChecked = selectedServices.some(
					(service) => service.itemCode === itemCode,
				)
				return (
					<Checkbox
						checked={isChecked}
						onChange={(e) => {
							toggleServiceSelection(record.itemCode)
						}}
						onClick={(e) => {
							e.stopPropagation()
						}}
					/>
				)
			},
		},
	]

	const columnsParametrs = [
		{
			title: t('service'),
			dataIndex: 'image',
			key: 'image',
			render: (text, record) => (
				<div className="flex items-center gap-2">
					<img
						src={record.image}
						alt="service"
						style={{ width: '50px', height: '50px' }}
					/>
				</div>
			),
		},
		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text) => (
				<span>{text !== null ? formatNumberWithSpaces(text) : ''}</span>
			),
		},
		{
			title: t('size'),
			dataIndex: 'itemCode',
			key: 'inputs',
			render: (itemCode, record) => {
				const isSelected = selectedServices.some(
					(service) => service.itemCode === record.itemCode,
				)
				return (
					<div>
						{renderInputs(
							itemCode,
							`${currentRecord.key}${itemCode}`,
							isSelected,
						)}
					</div>
				)
			},
		},
		{
			title: t('quantity'),
			dataIndex: 'itemCode',
			key: 'inputs',
			render: (itemCode, record) => {
				const isSelected = selectedServices.some(
					(service) => service.itemCode === record.itemCode,
				)
				return (
					<div>
						{renderInputsParametrQuantity(
							itemCode,
							`${currentRecord.key}${itemCode}`,
							isSelected,
						)}
					</div>
				)
			},
		},

		{
			title: t('Select'),
			dataIndex: 'itemCode',
			key: 'select',
			render: (itemCode, record) => (
				<Checkbox
					checked={selectedServices.some(
						(service) => service.itemCode === itemCode,
					)}
					onChange={(e) => {
						toggleParametrSelection(itemCode, record.key)
					}}
					onClick={(e) => {
						e.stopPropagation()
					}}
				/>
			),
		},
	]

	const columnsKoefitsent = [
		{
			title: t('item'),
			dataIndex: 'itemName',
			key: 'itemName',
			width: 200,
		},
		{
			title: t('kv.m'),
			dataIndex: 'lineTotalWithKoef',
			key: 'lineTotalWithKoef',
			render: (totalWithKoef) => (
				<span className="w-full">
					{new Intl.NumberFormat().format(totalWithKoef)}
				</span>
			),
		},
		{
			title: t('amountInWhs'),
			dataIndex: 'amountInWhs',
			key: 'amountInWhs',
			render: (text) => (
				<span className="w-full">
					{text ? new Intl.NumberFormat().format(text) : text}
				</span>
			),
		},
		{
			title: t('actions'),
			dataIndex: 'amountInWhs',
			key: 'amountInWhs',
			render: (text) => (
				<span className="w-full">
					{+text <= 0 && <Tag color="red">{t('itemIsNotInWhs')}</Tag>}
				</span>
			),
		},
		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			render: (_, record) => {
				const selectedPrices = selectedServices.reduce(
					(acc, service) => {
						if (
							service.ugpName !== 'шт' &&
							service.ugpName !== 'м'
						) {
							return acc + service.price
						}
						return acc
					},
					0,
				)

				const shtPrices = selectedServices.reduce((acc, service) => {
					if (service.ugpName === 'шт') {
						return acc + service.price * service.quantity
					}
					return acc
				}, 0)

				const koef = record.koefitsent || 1
				const totalWithKoef = record.lineTotalWithKoef // Use the precomputed summarized value

				const metrPricesServices = selectedServices?.reduce(
					(acc, service) => {
						if (service.ugpName === 'м' && !service.isParametr) {
							return (
								acc +
								service.price *
									((((service.width + service.height) * 2) /
										1000) *
										service.quantity)
							)
						}
						return acc
					},
					0,
				)

				const metrPricesParametr = selectedServices?.reduce(
					(acc, service) => {
						if (service.ugpName === 'м' && service.isParametr) {
							return (
								acc +
								service.itemQuantity *
									(service.price *
										(service.u_ParametrQuantityValues *
											service.quantity))
							)
						}
						return acc
					},
					0,
				)

				const lineTotal =
					calculateLineTotal(
						record.price,
						selectedPrices,
						totalWithKoef,
					) +
					shtPrices +
					metrPricesServices +
					metrPricesParametr

				// Update the summarized record with the calculated lineTotal (optional)
				record.lineTotal = lineTotal

				return (
					<span className="w-full">
						{numberWithSpacesIntl(lineTotal || 0)}
					</span>
				)
			},
		},
		{
			title: t('Koefitsent'),
			dataIndex: 'koefitsent',
			key: 'koefitsent',
			render: (_, record) => {
				return (
					<Input
						type="number"
						onWheel={(e) => e.target.blur()}
						min={-1}
						suffix="%"
						controls={false}
						value={record.koefitsent}
						onChange={(e) =>
							handleKoefitsentChange(
								e.target.value,
								record.itemName,
							)
						}
						className="w-full"
					/>
				)
			},
		},
	]

	const onSubmit = async () => {
		const photoId = (imageList || [])
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')

		setIsBtnLoading(true)
		console.log('selectedServices = ', selectedServices)
		console.log('tableData = ', tableData)
		const prevLocation = sessionStorage.getItem('prevLocation')

		try {
			let lineNumber = 0 // Initialize line number counter

			const data = {
				cardCode: clientCode,
				docDate: dateSale,
				docDueDate: date,
				docTotal: inputDocTotal,
				documentOwner: get(getMe, 'employeeId', ''),
				salesPersonCode: slpAdmin,
				comments: comments,
				u_ChertejId: photoId,
				docCurrency: currency,
				department: departmentAdmin,
				documentLines: [
					...(Array.isArray(tableData)
						? tableData.map((row) => ({
								lineNum: lineNumber++, // Increment line number
								itemCode: row.itemCode,
								quantity:
									Number(
										row.quantity *
											((row.width * row.height) /
												1000000),
									) * (row.koefitsent || 1),
								unitPrice: Number(row.price),
								currency: row.currency,
								warehouseCode: whs,
								u_uslugaID: Number(row.lineNum),
								u_Produkt: null,
								u_Kvmetr: (row.width * row.height) / 1000000,
								u_Width: row.width,
								u_Height: row.height,
								u_Coefficient: row.koefitsent,
								u_Timer: null,
								u_PlannedQuantity: row.quantity,
								u_Number: row.u_Number || '',
								u_ItemGroup: 'Item',
							}))
						: []),
					...(Array.isArray(tableData)
						? tableData
								.filter((row) =>
									Array.isArray(row.selectedServices),
								)
								.flatMap((row, rowIndex) =>
									row.selectedServices.map((service) => ({
										lineNum: lineNumber++, // Increment line number
										itemCode: service.itemCode,
										quantity:
											service.ugpName === 'шт'
												? service.quantity
												: service.ugpName === 'м' &&
													  !service.isParametr
													? ((+row.width +
															+row.height) /
															1000) *
														2 *
														service.quantity
													: service.ugpName === 'м' &&
														  service.isParametr
														? +service.quantity *
															+service.u_ParametrQuantityValues *
															service.itemQuantity
														: Number(
																+service.quantity *
																	((row.width *
																		row.height) /
																		1000000) *
																	(row.koefitsent ||
																		1),
															),
										unitPrice: Number(service.price),
										currency: service.currency,
										warehouseCode: whs,
										u_uslugaID: Number(service.lineNum),
										u_Produkt: row.itemName,
										u_Kvmetr: '',
										u_Timer: service.u_Timer,
										u_Width: row.width,
										u_Height: row.height,
										u_PlannedQuantity:
											service.ugpName === 'м' &&
											service.isParametr
												? service.u_ParametrQuantityValues
												: service.quantity,
										u_Coefficient:
											service.ugpName === 'шт' ||
											service.ugpName === 'м'
												? 1
												: row.koefitsent,
										u_Number: service.u_Number || '',
										u_ItemGroup: service.isParametr
											? 'Shape'
											: 'Service',
									})),
								)
								.sort((a, b) =>
									(a.u_Number || '').localeCompare(
										b.u_Number || '',
										undefined,
										{ numeric: true },
									),
								)
						: []),
				],
			}

			await http.post(
				`api/sales/sotuv-buyurtmasi?needToConfirmFromBot=${sendFile}`,
				data,
			)

			successRef.current.open(t('sucCreated'))
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
			console.log(error)
		} finally {
			setIsBtnLoading(false)
		}
	}

	const generateImage = (itemCode) => {
		switch (itemCode) {
			case 'Triangle':
				return uchburchak
			case 'Trapezoid':
				return beshburchak
			case 'Rectangle':
				return rectangle
			case 'Square':
				return tortburchak
			case 'Rhombus':
				return rhombus
			case 'Circle':
				return dumaloq
			case 'octagon':
				return sakkizBurchak
			case 'semicircle':
				return semicircle
			case 'oval':
				return oval
			case 'Parallelogram':
				return Parallelogram
			case 'Pencil':
				return Pencil
			default:
				break
		}
	}

	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				return (
					<>
						<div className="mt-5">
							<span className="font-bold text-lg">
								{t('chooseServicesPackage')}
							</span>
							<div className="flex flex-col  gap-1 mt-4 mb-4">
								{packageServicesData.map((service) => (
									<div
										key={service.code}
										className="flex items-center gap-2"
									>
										<span
											className="text-blue-600 hover:text-blue-800 cursor-pointer  font-bold"
											onClick={() =>
												toggleServiceSelectionGroup(
													service.serviceLines,
												)
											}
										>
											{service.name}
										</span>
									</div>
								))}
							</div>
						</div>
						<Table
							columns={columnsServices}
							dataSource={serviceItemsData}
							rowKey={(record) => record.itemCode}
							onRow={(record) => ({
								onClick: (e) => {
									e.stopPropagation()
									toggleServiceSelection(record?.itemCode)
								},
							})}
							pagination={false}
						/>
						<div className="mt-5 flex items-center justify-end  gap-2">
							<Button
								type="danger"
								className="bg-red-600 text-white hover:bg-red-500"
								onClick={() => {
									setIsConfirmServiceModalVisible(true)
								}}
							>
								{t('cancel')}
							</Button>
							<Button
								type="danger"
								className="bg-blue-800 text-white hover:bg-blue-600"
								onClick={handleOkCombined}
							>
								{t('save')}
							</Button>
						</div>
					</>
				)
			case 'tab2':
				return (
					<div className="mt-5 overflow-auto">
						<Table
							columns={columnsParametrs}
							dataSource={geometricShapesData}
							rowKey={(record) => record.itemCode}
							onRow={(record) => ({
								onClick: (e) => {
									e.stopPropagation()
									toggleParametrSelection(
										record?.itemCode,
										record?.key,
									)
								},
							})}
							pagination={false}
						/>
						<div className="mt-5 flex items-center justify-end gap-2">
							<Button
								type="danger"
								className="bg-red-600 text-white hover:bg-red-500"
								onClick={() => setIsModalOpen(false)}
							>
								{t('cancel')}
							</Button>
							<Button
								type="danger"
								className="bg-blue-800 text-white hover:bg-blue-600"
								onClick={handleOkCombined}
							>
								{t('save')}
							</Button>
						</div>
					</div>
				)

			default:
				return null
		}
	}

	const renderInputs = (itemCode, rowKey, isSelected) => {
		let inputCount = 1

		if (itemCode === 'Rectangle' || itemCode === 'Parallelogram') {
			inputCount = 4
		} else if (itemCode === 'Square') {
			inputCount = 4
		} else if (itemCode === 'oval') {
			inputCount = 2
		} else if (itemCode === 'Rhombus') {
			inputCount = 4
		} else if (itemCode === 'Circle') {
			inputCount = 1
		} else if (itemCode === 'Triangle') {
			inputCount = 3
		} else if (itemCode === 'octagon') {
			inputCount = 8
		} else if (itemCode === 'Trapezoid') {
			inputCount = 4
		} else if (itemCode === 'semicircle') {
			inputCount = 1
		} else if (itemCode === 'Pencil') {
			inputCount = 6
		} else if (itemCode !== 'Circle') {
			inputCount = 5
		}

		return Array.from({ length: inputCount }, (_, index) => (
			<Input
				key={`${rowKey}-input-${index}`}
				value={inputValues[rowKey]?.[index] || ''}
				onChange={(e) => {
					handleInputChange(rowKey, index, e.target.value)
				}}
				onClick={(e) => {
					e.stopPropagation()
				}}
				className="mb-2 w-full"
				disabled={!isSelected}
			/>
		))
	}

	const renderInputsParametrQuantity = (itemCode, rowKey, isSelected) => {
		let inputCount = 1
		return Array.from({ length: inputCount }, (_, index) => (
			<Input
				key={`${rowKey}-input-${index}`}
				value={inputParametrQuantity[rowKey]?.[index] || ''}
				onChange={(e) => {
					handleParametrQuantityInputChange(
						rowKey,
						index,
						e.target.value,
					)
				}}
				onClick={(e) => {
					e.stopPropagation()
				}}
				className="mb-2 w-[100px]"
				disabled={!isSelected}
			/>
		))
	}

	const addRow = () => {
		setTableData([
			...tableData,
			{
				key: Date.now(),
				itemCode: null,
				itemName: null,
				width: '',
				height: '',
				quantity: '',
				ugpName: '',
				price: '',
				lineTotal: '',
				lineNum: tableData[tableData.length - 1].lineNum + 1,
				u_Timer: null,
				u_Number: null,
				koefitsent: 1,
				disabled: false,
			},
		])
	}

	const copyRow = (record) => {
		setTableData([
			...tableData,
			{
				key: Date.now(),
				itemCode: record.itemCode,
				itemName: record.itemName,
				width: '',
				height: '',
				quantity: '',
				ugpName: '',
				currency: currency,
				price: record.price,
				lineTotal: '',
				lineNum: tableData[tableData.length - 1].lineNum + 1,
				u_Timer: record.u_Timer,
				u_Number: record.u_Number,
				koefitsent: record.koefitsent,
				disabled: record.disabled,
			},
		])
	}

	const removeRow = (key) => {
		if (tableData.length > 1) {
			setTableData(tableData.filter((row) => row.key !== key))
		}
	}

	const uploadImg = async (index) => {
		const image = imageList[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle'

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = true
			setImageList(updatedImageList)

			const res = await http.post('api/assets/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			const { key } = res.data
			updatedImageList[index].path = key
			setImageList(updatedImageList)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedImageList = [...imageList]
			updatedImageList[index].imgLoading = false
			setImageList(updatedImageList)
		}
	}

	const viewImage = async (index) => {
		const image = imageList[index]

		try {
			const res = await http.get(`api/assets/download/${image.path}`, {
				responseType: 'blob',
			})

			const url = window.URL.createObjectURL(
				new Blob([res.data], {
					type: 'image/png',
				}),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	const addNewRow = () => {
		setImageList([
			...imageList,
			{ file: null, path: '', imgLoading: false },
		])
	}

	const deleteRow = (index) => {
		setImageList(imageList.filter((_, i) => i !== index))
	}

	const handleDateChange = (e) => {
		const input = Number(e.target.value)

		if (!isNaN(input)) {
			let daysToAdd = input
			let newDate = moment()

			while (daysToAdd > 0) {
				newDate.add(1, 'days')
				if (newDate.day() !== 0) {
					daysToAdd--
				}
			}
			setDate(newDate.format('YYYY-MM-DD'))
		} else {
			console.error('Invalid input: Please input a valid number.')
		}
	}

	const handleFileChange = (event, index) => {
		const updatedImageList = [...imageList]
		updatedImageList[index].file = event.target.files[0]
		setImageList(updatedImageList)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setSearchClientName)
	}

	const handleItemNameChange = (val) => {
		debouncedSet(val, setSearchItemName)
	}
	const handleKoefitsentChange = (value, itemName) => {
		setTableData((prevData) => {
			const koef = parseFloat(value) || 0
			return prevData.map((row) => {
				if (row.itemName === itemName) {
					// Update all grouped rows
					const selectedPrices = selectedServices.reduce(
						(acc, service) =>
							service.ugpName !== 'шт'
								? acc + service.price
								: acc,
						0,
					)

					const shtPrices = selectedServices.reduce(
						(acc, service) =>
							service.ugpName === 'шт'
								? acc + service.price * service.quantity
								: acc,
						0,
					)

					const totalWithKoef = calculateTotalWithKoef(
						row.width,
						row.height,
						row.quantity,
						koef,
					)

					const lineTotal =
						calculateLineTotal(
							row.price,
							selectedPrices,
							totalWithKoef,
						) + shtPrices

					return {
						...row,
						koefitsent: koef, // Store updated coefficient
						lineTotalWithKoef: totalWithKoef, // Recalculate total
						lineTotal, // Update calculated total
					}
				}
				return row
			})
		})
	}

	const handleModalYes = () => {
		setIsConfirmModalVisible(false)
		navigate('/sales-order')
	}

	const handleModalNo = () => {
		setIsConfirmModalVisible(false)
	}

	const handleModalCancel = () => {
		setIsConfirmModalVisible(false)
	}

	const handleServiceModalYes = () => {
		setIsConfirmServiceModalVisible(false)
		setIsModalOpen(false)
	}

	const handleServiceModalNo = () => {
		setIsConfirmServiceModalVisible(false)
	}

	const handleServiceModalCancel = () => {
		setIsConfirmServiceModalVisible(false)
	}

	const handleInputChange = (rowKey, index, value) => {
		setInputValues((prev) => {
			const updatedValues = { ...prev }
			if (!updatedValues[rowKey]) {
				updatedValues[rowKey] = [] // Initialize row if not present
			}
			updatedValues[rowKey][index] = value // Set the specific input value
			return updatedValues
		})
	}

	const handleParametrQuantityInputChange = (rowKey, index, value) => {
		setInputParametrQuantity((prev) => {
			const updatedValues = { ...prev }
			if (!updatedValues[rowKey]) {
				updatedValues[rowKey] = [] // Initialize row if not present
			}
			updatedValues[rowKey][index] = value // Set the specific input value
			return updatedValues
		})
	}

	const toggleParametrSelection = (itemCode, rowKey) => {
		const selectedService = geometricShapesData.find(
			(service) => service.itemCode === itemCode,
		)
		if (selectedService && currentRecord) {
			setSelectedServices((prevSelected) => {
				const isAlreadySelected = prevSelected.some(
					(service) => service.itemCode === itemCode,
				)
				let newSelectedServices

				if (isAlreadySelected) {
					newSelectedServices = prevSelected.filter(
						(service) => service.itemCode !== itemCode,
					)
				} else {
					const { itemCode, price, ugpName, u_Timer, u_Number } =
						selectedService
					const { lineNum, currency, quantity, width, height } =
						currentRecord
					newSelectedServices = [
						...prevSelected,
						{
							width,
							height,
							itemCode,
							price,
							lineNum,
							currency,
							itemQuantity: quantity,
							ugpName,
							u_Timer,
							u_Number,
							isParametr: true,
						},
					]
				}
				return newSelectedServices
			})

			setInputValues((prev) => {
				const updatedValues = { ...prev }
				if (!prev[rowKey]) {
					updatedValues[rowKey] = Array(10).fill('')
				}
				if (
					selectedServices.some(
						(service) => service.itemCode === itemCode,
					)
				) {
					return updatedValues[rowKey]
				}
				return updatedValues
			})
		}
	}

	const toggleServiceSelection = (itemCode) => {
		const selectedService = serviceItemsData.find(
			(service) => service.itemCode === itemCode,
		)
		if (selectedService && currentRecord) {
			setSelectedServices((prevSelected) => {
				const isAlreadySelected = prevSelected.some(
					(service) => service.itemCode === itemCode,
				)
				let newSelectedServices

				if (isAlreadySelected) {
					newSelectedServices = prevSelected.filter(
						(service) => service.itemCode !== itemCode,
					)
				} else {
					const { itemCode, price, ugpName, u_Timer, u_Number } =
						selectedService
					const { lineNum, currency, quantity, width, height } =
						currentRecord
					newSelectedServices = [
						...prevSelected,
						{
							width,
							height,
							itemCode,
							price,
							lineNum,
							currency,
							quantity,
							ugpName,
							u_Timer,
							u_Number,
							isParametr: false,
						},
					]
				}
				return newSelectedServices
			})
		}
	}

	const toggleServiceSelectionGroup = (line) => {
		const selectedService = line
			.map((item) =>
				serviceItemsData.find(
					(service) => service.itemCode === item.itemCode,
				),
			)
			.filter(Boolean) // Remove undefined values

		// Find itemCodes that do not exist
		const missingItemCodes = line
			.map((item) => item.itemCode)
			.filter(
				(itemCode) =>
					!serviceItemsData.some(
						(service) => service.itemCode === itemCode,
					),
			)

		// Show an error message for missing itemCodes
		if (missingItemCodes.length > 0) {
			message.error(
				`The following item codes were not found: ${missingItemCodes.join(', ')}`,
			)
		}

		if (selectedService.length > 0 && currentRecord) {
			setSelectedServices((prevSelected) => {
				const isAlreadySelected = line.every((item) =>
					prevSelected.some(
						(service) => service.itemCode === item.itemCode,
					),
				)

				let newSelectedServices

				if (isAlreadySelected) {
					newSelectedServices = prevSelected.filter(
						(service) =>
							!line.some(
								(item) => item.itemCode === service.itemCode,
							),
					)
				} else {
					const newServices = selectedService.map(
						({ itemCode, price, ugpName, u_Timer, u_Number }) => {
							const {
								lineNum,
								currency,
								quantity,
								width,
								height,
							} = currentRecord
							return {
								width,
								height,
								itemCode,
								price,
								lineNum,
								currency,
								quantity,
								ugpName,
								u_Timer,
								u_Number,
								isParametr: false,
							}
						},
					)
					newSelectedServices = [...prevSelected, ...newServices]
				}
				return newSelectedServices
			})
		}
	}

	// const toggleServiceSelectionGroup = (line) => {
	// 	const selectedService = line.map((item) =>
	// 		serviceItemsData.find(
	// 			(service) => service.itemCode === item.itemCode,
	// 		),
	// 	)

	// 	if (selectedService && currentRecord) {
	// 		setSelectedServices((prevSelected) => {
	// 			const isAlreadySelected = line.every((item) =>
	// 				prevSelected.some(
	// 					(service) => service.itemCode === item.itemCode,
	// 				),
	// 			)

	// 			let newSelectedServices

	// 			if (isAlreadySelected) {
	// 				newSelectedServices = prevSelected.filter(
	// 					(service) =>
	// 						!line.every(
	// 							(item) => item.itemCode === service.itemCode,
	// 						),
	// 				)
	// 				// !line.every((item) bunda uslugalarni oraqaga qaytaib bolmaydi agar some ishlatilsa  uslugalarni oraqaga qaytaib boladi
	// 			} else {
	// 				const newServices = selectedService.map(
	// 					({ itemCode, price, ugpName, u_Timer, u_Number }) => {
	// 						const {
	// 							lineNum,
	// 							currency,
	// 							quantity,
	// 							width,
	// 							height,
	// 						} = currentRecord
	// 						return {
	// 							width,
	// 							height,
	// 							itemCode,
	// 							price,
	// 							lineNum,
	// 							currency,
	// 							quantity,
	// 							ugpName,
	// 							u_Timer,
	// 							u_Number,
	// 							isParametr: false,
	// 						}
	// 					},
	// 				)
	// 				newSelectedServices = [...prevSelected, ...newServices]
	// 			}
	// 			return newSelectedServices
	// 		})
	// 	}
	// }

	const handleOkCombined = () => {
		const updatedSelectedServices = selectedServices.map((service) => {
			let quantity = service.quantity || 0
			let u_ParametrQuantityValues = service.u_ParametrQuantityValues || 0
			let isParametr = false
			// Calculate quantity for shapes if inputValues are present
			const inputValuesForRow =
				inputValues[`${currentRecord.key}${service.itemCode}`] || []

			if (inputValuesForRow.length > 0) {
				if (service.itemCode === 'Circle') {
					const value = parseFloat(inputValuesForRow[0] || 0)
					quantity = (3.14 * value) / 1000
					isParametr = true
				} else if (service.itemCode === 'semicircle') {
					const value = parseFloat(inputValuesForRow[0] || 0)
					quantity = (3.14 * (value / 2)) / 1000
					isParametr = true
				} else {
					quantity =
						inputValuesForRow.reduce(
							(acc, val) => acc + parseFloat(val || 0),
							0,
						) / 1000
					isParametr = true
				}
			}

			const inputParametrQuantityValues =
				inputParametrQuantity[
					`${currentRecord.key}${service.itemCode}`
				] || []
			if (inputParametrQuantityValues.length > 0) {
				u_ParametrQuantityValues = inputParametrQuantityValues.reduce(
					(acc, val) => acc + parseFloat(val || 0),
					0,
				)
				isParametr = true
			}

			// Buyoda azgina logika ozgarishi mumkun yani 	service.itemQuantity bunga ham kopaytirish qoshilishi mumkun bu itemmi tashqaridigi tabledigi yozilgan soni ozi postda (+service.quantity * 	+service.u_ParametrQuantityValues * 	service.itemQuantity) bunda service.itemQuantity  ham qoshilgan ekan  ekan
			// Ozi bunda yozilgan O'lcham inputlarini jami bilan *  yozilgan input soniga kopaytilyapti hozir Figuri modaldigi
			const totalPemitr = quantity * u_ParametrQuantityValues

			return {
				...service,
				quantity,
				u_ParametrQuantityValues: u_ParametrQuantityValues,
				totalPemitr: totalPemitr,
				isParametr,
			}
		})

		// Calculate selectedPrices and shtPrices for services
		const selectedPrices = updatedSelectedServices.reduce(
			(acc, service) => {
				if (service.ugpName !== 'шт') {
					return acc + service.price
				}
				return acc
			},
			0,
		)

		const shtPrices = updatedSelectedServices.reduce((acc, service) => {
			if (service.ugpName === 'шт') {
				return acc + service.price * service.quantity
			}
			return acc
		}, 0)

		//
		const totalPerimetr = updatedSelectedServices.reduce((acc, service) => {
			return acc + service.totalPemitr
		}, 0)

		const updatedData = tableData.map((row) => {
			if (row.key === currentRecord.key) {
				const koef = 1
				const totalWithKoef = calculateTotalWithKoef(
					row.width,
					row.height,
					row.quantity,
					koef,
				)
				const mWithKoef = calculateMetrTotalWithKoef(
					row.width,
					row.height,
					row.quantity,
					koef,
				)

				const mPrices = updatedSelectedServices.reduce(
					(acc, service) => {
						if (service.ugpName === 'м') {
							return acc + service.price * mWithKoef
						}
						return acc
					},
					0,
				)

				const lineTotal =
					calculateLineTotal(
						row.price,
						selectedPrices,
						totalWithKoef,
					) +
					mPrices +
					shtPrices

				return {
					...row,
					selectedServices: updatedSelectedServices,
					lineTotal,
					totalPerimetr,
				}
			}
			return row
		})

		setTableData(updatedData)
		setSelectedServices(updatedSelectedServices)
		setIsModalOpen(false)
	}

	const summarizeTableData = (data) => {
		const groupedData = data.reduce((acc, record) => {
			const {
				itemName,
				width,
				height,
				quantity,
				koefitsent,
				amountInWhs,
				price,
			} = record
			const koef = koefitsent || 1
			const totalWithKoef = calculateTotalWithKoef(
				width,
				height,
				quantity,
				koef,
			)

			if (!acc[itemName]) {
				acc[itemName] = {
					...record,
					lineTotalWithKoef: 0,
					lineTotal: 0,
					count: 0,
					amountInWhs,
					koefitsent,
				}
			}

			acc[itemName].lineTotalWithKoef += totalWithKoef
			acc[itemName].count += 1

			return acc
		}, {})

		return Object.values(groupedData).map((item, index) => ({
			...item,
			key: index,
		}))
	}

	const summarizedData = summarizeTableData(tableData)

	const totalPerimetrToM = tableData
		.filter((row) => Array.isArray(row.selectedServices))
		.flatMap((row, rowIndex) =>
			row.selectedServices
				.filter(
					(service) => service.ugpName === 'м' && !service.isParametr,
				)
				.map((service) => ({
					total:
						((+row.width + +row.height) / 1000) *
						2 *
						service.quantity,
				})),
		)
		.reduce((acc, row) => acc + row.total, 0)

	return (
		<Layout>
			<div className={'p-7'}>
				<div className="flex items-center gap-10 flex-wrap">
					<div className={'flex items-end'}>
						<div className="flex flex-col items-start gap-3 ">
							<p className="font-nunito">{t('Client')}</p>
							<AutoComplete
								loading={loadingClient}
								showSearch
								value={clientName}
								className=" w-[300px]"
								onSelect={(value) => {
									const val = value.split(' ,/ ')[0]

									setClientName(val)
									handleCardNameChange(val)
									const selectedOption = clientsData.find(
										(client) => {
											const cardName = get(
												client,
												'cardName',
												'',
											)
											const cardCode = get(
												client,
												'cardCode',
												'',
											)
											const phoneNumber = get(
												client,
												'Cellular',
												'',
											)
												? get(client, 'Cellular', '')
												: ''
											const formattedValue = [
												cardName,
												phoneNumber,
											]
												.filter(Boolean)
												.join(' ')
											return (
												`${formattedValue} ,/ ${cardCode}` ===
												value
											)
										},
									)

									if (selectedOption) {
										const cardCode = get(
											selectedOption,
											'cardCode',
											'',
										)
										setClientCode(cardCode)
									}
								}}
								onChange={(val) => {
									const value = val.split(' ,/ ')[0]
									setClientName(value)
									handleCardNameChange(value)
								}}
								options={clientsData.map((client) => ({
									value: `${client.cardName}${client.Cellular ? ` ${client.Cellular}` : ''} ,/ ${client.cardCode}`,
									label: (
										<div className="flex items-center justify-between">
											<p>{client.cardName}</p>
											{client.Cellular && (
												<Tag
													color="blue"
													className="text-[9px]"
												>
													{client.Cellular}
												</Tag>
											)}
										</div>
									),
								}))}
							/>
						</div>
						<button
							className="w-[30px] h-8 bg-blue-700 text-white text-lg hover:bg-blue-500"
							onClick={() => setIsModalVisible(true)}
						>
							+
						</button>
					</div>
					<div className="flex flex-col gap-2">
						<p>{t('currency')}</p>
						<div className="flex">
							<Select
								value={currency}
								onChange={(value) => setCurrency(value)}
								className="w-[150px]"
								disabled
							>
								<Select.Option key={1} value={'UZS'}>
									UZS
								</Select.Option>
							</Select>
						</div>
					</div>
					{roleNames.includes('admin') && (
						<div className="flex flex-col gap-2">
							<p>{t('departmentName')}</p>
							<div className="flex">
								<Select
									value={departmentAdmin}
									className="flex h-8  w-[150px] items-center justify-center "
									style={{ color: 'black' }}
									onChange={(val) => {
										setDepartmentAdmin(val)
									}}
									options={departmentData.map(
										(department) => {
											return {
												label: department.name,
												value: Number(department.code),
											}
										},
									)}
								/>
							</div>
						</div>
					)}
					{roleNames.includes('admin') && (
						<div className="flex flex-col gap-2">
							<p>{t('slpName')}</p>
							<Select
								value={slpCode}
								className="flex h-8  w-[150px] items-center justify-center "
								style={{ color: 'black' }}
								onChange={(val) => {
									setSlpAdmin(val)
								}}
								options={slpData.map((slp) => {
									return {
										label: slp.slpName,
										value: Number(slp.slpCode),
									}
								})}
							/>
						</div>
					)}
					{roleNames.includes('admin') && (
						<div className="flex flex-col gap-2">
							<p>{t('date')}</p>
							<Input
								type="date"
								value={dateSale}
								onChange={(e) => setDateSale(e.target.value)}
								className="w-full"
							/>
						</div>
					)}
					{roleNames.includes('admin') && (
						<div className="flex flex-col gap-2">
							<p>{t('whs')}</p>
							<Select
								// value={whs}
								onChange={(value) => setWhs(value)}
								className="w-[200px] "
								options={warehouseData
									.filter((warehouse) => {
										return warehouse.code !== whs
									})
									.map((warehouse) => ({
										label: warehouse.name,
										value: warehouse.code,
									}))}
							/>
						</div>
					)}
				</div>

				<div className="flex items-end gap-2 mt-5 ">
					<div className="flex flex-col gap-2">
						<p>{t('bpDebt')}</p>
						<Input
							value={
								clientCode
									? `${new Intl.NumberFormat().format(bpDebt) || 0} USD`
									: ''
							}
							className="w-[200px] "
							disabled
						/>
					</div>
					<Button
						type="primary"
						onClick={() => setIsViewBpBalanceModalVisible(true)}
						disabled={!clientCode}
						className="bg-blue-700 text-white rounded-lg  hover:bg-blue-500 disabled:bg-blue-300"
					>
						<ViewIcon />
					</Button>
				</div>

				<Table
					columns={columns}
					dataSource={tableData}
					pagination={false}
					className="border-1 mt-12 border-black"
					bordered
					scroll={{ x: 'max-content' }}
				/>

				<Table
					columns={columnsKoefitsent}
					dataSource={summarizedData}
					pagination={false}
					className="border-1 mt-2 border-black"
					bordered
					scroll={{ x: 'max-content' }}
				/>

				<div className="flex gap-10 mt-20 drop-shadow-lg bg-white p-5 rounded-lg">
					<div className="w-full flex flex-col  gap-6 ">
						<div className="flex flex-col items-start gap-3 ">
							<p className="font-nunito">{t('deadLine')}</p>
							<Input
								type={'number'}
								onWheel={(e) => e.target.blur()}
								min={0}
								className={'w-full h-10'}
								onChange={handleDateChange}
							/>
						</div>
						<div className="flex   gap-5 items-end">
							<div className="flex flex-col items-start gap-3">
								<p className="font-nunito">{t('minTotal')}</p>
								<Input
									type="text"
									value={`${new Intl.NumberFormat().format(docTotal || 0)} ${currency}`}
									className="h-10 w-full "
									style={{ color: 'black' }}
									disabled
								/>
							</div>
							<div className="flex flex-col items-start gap-3">
								<p className="font-nunito">{t('totalKvm')}</p>
								<Input
									type="text"
									value={totalKvm}
									className="h-10 w-full "
									style={{ color: 'black' }}
									disabled
								/>
							</div>
							<div className="flex flex-col items-start gap-3">
								<p className="font-nunito">
									{t('totalPerimeter')}
								</p>
								<Input
									type="text"
									value={totalPerimetrToM}
									className="h-10 w-full "
									style={{ color: 'black' }}
									disabled
								/>
							</div>
						</div>
						<div className="flex flex-col items-start gap-3 ">
							<p className="font-nunito">{t('docTotal')}</p>
							<Input
								value={formatNumberWithSpaces(inputDocTotal)}
								className={'w-full h-10'}
								min={docTotal || 0}
								suffix={
									<span className="text-gray-400">UZS</span>
								}
								onBlur={(e) => {
									const rawValue = e.target.value

									const sanitizedValue = rawValue
										.replace(/\s+/g, '')
										.replace(',', '.')
									const value =
										parseFloat(sanitizedValue) || 0

									setInputDocTotal(value)

									if (value >= (parseInt(docTotal) || 0)) {
										setInputDocTotal(value)
										setIsDiscountBigger(false)
									} else {
										setInputDocTotal(parseInt(docTotal))
										setIsDiscountBigger(true)
									}
								}}
								onChange={(e) => {
									const rawValue = e.target.value
									const sanitizedValue = rawValue
										.replace(/\s+/g, '')
										.replace(',', '.')
									const val = parseFloat(sanitizedValue) || 0

									setInputDocTotal(val)
								}}
							/>
							{isDiscountBigger && (
								<p className="text-red-600 font-nunito">
									{t('docTotalBigger')}{' '}
									{numberWithSpacesIntl(docTotal) || 0}
								</p>
							)}
						</div>
						{/* <div className="flex flex-col items-start gap-3 ">
							<p className="font-nunito">{t('Discount')}</p>
							<Input
								// type={'number'}
								controls={false}
								value={
									docTotalWithDiscount,
								}
								className={'w-full'}
								min={0}
								onChange={(e) => {
									const val = e.target.value
									if (
										val <=
										(docTotal / 100) *
											get(getMe, 'u_Discount', 1)
									) {
										setDocTotalWithDiscount(val)
										setIsDiscountBigger(false)
									} else {
										setIsDiscountBigger(true)
									}
								}}
							/>
							{isDiscountBigger && (
								<p className="text-red-600 font-nunito">
									{t('discountBigger')}{' '}
									{(docTotal / 100) *
										get(getMe, 'u_Discount', 1) || 0}
								</p>
							)}
						</div> */}
						<div className="flex flex-col items-start gap-3 w-full">
							<p className="font-nunito">{t('sendFile')}</p>
							<Input
								type="checkbox"
								value={sendFile}
								onChange={(e) => {
									setSendFile(e.target.checked)
								}}
								className="w-fit"
							/>
						</div>
					</div>

					<div className="w-full flex  h-full">
						<div className="flex flex-col items-start gap-3 w-full">
							<p className="font-nunito">{t('comments')} </p>
							<TextArea
								className={'w-full'}
								rows={11}
								onChange={(e) => {
									setComments(e.target.value)
								}}
							/>
						</div>
					</div>
				</div>
				<div>
					{imageList.map((image, index) => (
						<div
							key={index}
							className="flex w-full items-start gap-1 flex-col mt-5 mb-10"
						>
							<div>
								<p className="font-bold">
									{t('photo')}-{index + 1}
								</p>
							</div>
							<div className="flex items-center gap-3">
								<Input
									type="file"
									onChange={(e) => handleFileChange(e, index)}
									className="w-full"
								/>

								<Button
									onClick={() => uploadImg(index)}
									isLoading={image.imgLoading}
									className="h-9 w-[200px] rounded-3xl bg-[#0A4D68] text-white"
								>
									{t('upload')}
								</Button>
								{image.path && (
									<Button
										onClick={() => viewImage(index)}
										className="h-9 w-[200px] rounded-3xl bg-[#0A4D68] text-white"
									>
										{t('download')}
									</Button>
								)}
								{index !== 0 && (
									<Button
										onClick={() => deleteRow(index)}
										className="h-9 w-[70px] rounded-3xl  bg-red-500 text-white"
									>
										{/* {t("delete")} */}-
									</Button>
								)}

								{index === imageList.length - 1 && (
									<Button
										onClick={addNewRow}
										className="h-9 w-[70px] rounded-3xl bg-[#0A4D68] text-white"
									>
										{/* {t("add2")} */}+
									</Button>
								)}
							</div>
						</div>
					))}
				</div>
				<div className={'flex mt-10 w-full items-center gap-10'}>
					<Button
						onClick={() => setIsConfirmModalVisible(true)}
						type={'danger'}
						className="w-full bg-red-600 text-white"
					>
						{t('back')}
					</Button>
					<Button
						onClick={onSubmit}
						loading={btnLoading}
						// disabled={
						// 	btnLoading ||
						// 	!tableData.length === 0 ||
						// 	!clientCode ||
						// 	!departmentAdmin ||
						// 	!slpCode ||
						// 	!dateSale ||
						// 	!whs
						// }
						type={'primary'}
						className="w-full"
					>
						{t('create')}
					</Button>
				</div>
			</div>

			<Modal
				title={
					<div className="flex items-center gap-2">
						<span className="cursor-pointer text-2xl font-bold">
							{t('services')}
						</span>
					</div>
				}
				open={isModalOpen}
				onCancel={() => {
					setIsConfirmServiceModalVisible(true)
				}}
				// onOk={handleOkServices}
				footer={null}
				width="50%"
			>
				<div className="w-full">
					<Space style={{ marginBottom: 16 }} className="mt-5">
						<button
							className={`${
								activeTab === 'tab1'
									? 'text-blue-900 underline underline-offset-8'
									: ''
							} font-semibold `}
							onClick={() => setActiveTab('tab1')}
						>
							{t('Select Services')}
						</button>
						<button
							className={`${
								activeTab === 'tab2'
									? 'text-blue-900 underline underline-offset-8'
									: ''
							} font-semibold ml-3`}
							onClick={() => setActiveTab('tab2')}
						>
							{t('shapes')}
						</button>
					</Space>
					<div className="mt-5">{renderContent()}</div>
				</div>
			</Modal>

			<CreteCustomerModal
				visible={isModalVisible}
				data={null}
				onClose={() => {
					refetch()
					setIsModalVisible(false)
				}}
				onConfirm={(code) => {
					const data = `${code?.name} ${code?.phoneNumber ? code?.phoneNumber : ''}`
					setClientName(data)
					setClientCode(code?.cardCode)
				}}
			/>
			<ViewDepartmentsBalanceModal
				visible={isViewBpBalanceModalVisible}
				data={bpBalanceData[0]}
				onClose={() => {
					setIsViewBpBalanceModalVisible(false)
				}}
			/>

			<ConfirmExitModal
				visible={isConfirmModalVisible}
				handleClickCancel={handleModalCancel}
				handleClickNo={handleModalNo}
				handleClickYes={handleModalYes}
			/>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
				onConfirm={() => {
					navigate('/sales-order')
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
			<WarningModal
				getRef={(r) => {
					warningRef.current = r
				}}
			/>
			<ConfirmExitModal
				visible={isConfirmServiceModalVisible}
				handleClickCancel={handleServiceModalCancel}
				handleClickNo={handleServiceModalNo}
				handleClickYes={handleServiceModalYes}
			/>
		</Layout>
	)
}

export default CreateSales
