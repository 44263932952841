import dayjs from 'dayjs'
import moment from 'moment'

export const getToday = () => {
	return moment().format('YYYY-MM-DD')
}

export const getFiveDaysAgo = () => {
	return moment().subtract(5, 'days').format('YYYY-MM-DD')
}

const getFiveDaysAgo2 = () => dayjs().subtract(5, 'days')
const getToday2 = () => dayjs()

export const disabledDate = (current) => {
	if (!current) return false
	return (
		current.isBefore(getFiveDaysAgo2(), 'day') ||
		current.isAfter(getToday2(), 'day')
	)
}
