import { Input, Modal, Button, message, Empty, Image } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { http } from '../../../services/http'
import SuccessModal from '../SuccessModal'
import ErrorModal from '../ErrorModal'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { config } from '../../../config.js'

const ImageTab = ({ data }) => {
	const { t } = useTranslation()

	const [selectedImage, setSelectedImage] = useState(null)
	const [path, setPath] = useState([])

	useEffect(() => {
		if (data?.u_ChertejId) {
			const photoId = data?.u_ChertejId

			const photoPaths = photoId
				? photoId.includes(',')
					? photoId.split(',')
					: [photoId]
				: []

			const initialPath = photoPaths.map((path) => ({
				file: 'not empty',
				has: true,
				path,
				imgLoading: false,
			}))

			const finalPath =
				initialPath.length > 0
					? initialPath
					: [{ file: null, path: '', imgLoading: false }]

			setPath(finalPath)
		}
	}, [data])

	const downloadImage = async (path) => {
		try {
			const res = await http.get(`api/assets/download/${path}`, {
				responseType: 'blob',
			})

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error(t('errorDownload'))
		}
	}
	const viewImage = (path) => {
		setSelectedImage(`${config.baseURL}api/assets/download/${path}`)
	}

	return (
		<div className="flex w-full items-end gap-5 mt-5">
			{path?.length > 0 && path[0].file ? (
				<Image.PreviewGroup
					preview={{
						onChange: (current, prev) =>
							console.log(
								`current index: ${current}, prev index: ${prev}`,
							),
					}}
				>
					{path?.map((image, index) =>
						image.has ? (
							image.path ? (
								<div
									key={index}
									className="flex w-full items-start flex-col gap-3 "
								>
									<div>
										<p className="font-bold">
											{t('drawingPhoto')}-{index + 1}
										</p>
									</div>
									<div className="flex flex-col items-center gap-3">
										<Image
											width={200}
											src={`${config.baseURL}api/assets/download/${image.path}`}
											placeholder={
												<Image
													preview={false}
													src={`${config.baseURL}api/assets/download/${image.path}`}
													width={200}
												/>
											}
										/>
										<Button
											onClick={() =>
												downloadImage(image.path)
											}
											className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
										>
											{t('download')}{' '}
										</Button>
									</div>
								</div>
							) : (
								<Empty
									key={index}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							)
						) : (
							''
						),
					)}
				</Image.PreviewGroup>
			) : (
				<div className="w-full mt-10 mb-10 flex items-center justify-center">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			<Modal
				open={!!selectedImage}
				onCancel={() => setSelectedImage(null)}
				footer={null}
				centered
				// width="80%"
				// zIndex={1050}
			>
				<img
					src={selectedImage}
					alt="Large preview"
					className="max-w-full max-h-[80vh] mx-auto"
				/>
			</Modal>
		</div>
	)
}

export default ImageTab
