import React, { memo, useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import SuccessImage from '../../../assets/images/success.png'
import SuccessModalStyle from './SuccessModalStyle'
import { useTranslation } from 'react-i18next'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

// import SuccessSound from '../../../assets/music/success.mp3'

const SuccessModal = ({ getRef = () => {}, onConfirm = () => {} }) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [successTitle, setSuccessTitle] = useState('Выполнено успешно')
	const { t } = useTranslation()

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				setSuccessTitle(t)
				// let audio = new Audio(SuccessSound)
				// audio.play()
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [getRef])

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Enter' && isOpenModal) {
				onConfirm()
				setIsOpenModal(false)
			}
		}

		document.addEventListener('keydown', handleKeyPress)
		return () => document.removeEventListener('keydown', handleKeyPress)
	}, [isOpenModal, onConfirm])

	return (
		<Modal
			open={isOpenModal}
			// onCancel={() => setIsOpenModal(false)}
			footer={null}
			centered
			bodyStyle={{
				textAlign: 'center',
				borderRadius: 15,
			}}
			// closeIcon={<span style={{ fontSize: '1.5rem' }}>×</span>}
		>
			<SuccessModalStyle>
				<div className="card">
					<div className="flex justify-center">
						<img
							src={SuccessImage}
							alt="success"
							className="w-[100px]"
						/>

						{/* <DotLottieReact
							src="https://lottie.host/ae6f4346-3d73-4d43-bd07-873e837849f7/c8u5psmIi3.lottie"
							loop
							autoplay
						/> */}
					</div>
					<h2 className="font-semibold mt-5 text-lg">
						{successTitle}
						{'!'}
					</h2>

					<div className="centerCard">
						<Button
							className="w-[100px] text-sm h-12 bg-green-600 text-white "
							onClick={() => {
								onConfirm()
								setIsOpenModal(false)
							}}
						>
							{t('understand')}
						</Button>
					</div>
				</div>
			</SuccessModalStyle>
		</Modal>
	)
}

export default memo(SuccessModal)
