import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../../../../services/http'
import { useSelector } from 'react-redux'
import SuccessModal from '../../../../../components/Modal/SuccessModal'
import ErrorModal from '../../../../../components/Modal/ErrorModal'
import { useQuery } from '@tanstack/react-query'
import {
  fetchDdsArticle,
  fetchCashAccounts,
} from '../../../../../utils/fetchData'

const CreateOutgoingPaymentModal = ({ visible, onClose }) => {
  const { t } = useTranslation()
  const successRef = useRef()
  const errorRef = useRef()

  const uzsCash = useSelector((state) => state.main.uzsCash)
  const usdCash = useSelector((state) => state.main.usdCash)

  const [loading, setLoading] = useState(false)
  const [dds, setDds] = useState('')
  const [currency, setCurrency] = useState('')
  const [comment, setComment] = useState('')
  const [accCode, setAccCode] = useState('')
  const [amount, setAmount] = useState('')

  const postData = async () => {
    setLoading(true)
    try {
      const paymentAccounts = [
        {
          accountCode: accCode,
          sumPaid: Number(amount),
        },
      ]
      await http.post('api/vendorpayments', {
        cardCode: accCode,
        cashAccount:
          currency === 'USD' ? usdCash : currency === 'UZS' ? uzsCash : '',
        docCurrency: currency,
        cashSum: Number(amount),
        remarks: comment,
        u_DdsArticle: dds,
        paymentAccounts,
      })
      resetForm()
      onClose()

      successRef.current.open(t('sucCreated'))
    } catch (error) {
      errorRef.current.open(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  const resetForm = () => {
    setLoading(false)
    setDds('')
    setCurrency('')
    setComment('')
    setAccCode('')
    setAmount('')
  }

  const { data: ddsData = [], isLoading: ddsLoading } = useQuery({
    queryKey: ['dds-articles'],
    queryFn: fetchDdsArticle,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const { data: cashAccountsData = [], isLoading: cashLoading } = useQuery({
    queryKey: ['cash-accounts'],
    queryFn: fetchCashAccounts,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  return (
    <Modal
      title={
        <h1 className="font-nunito  font-extrabold text-[#000000]">
          {t('createOutgoing')}
        </h1>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width="65%"
      style={{ height: '70%' }}
    >
      <div className="w-full px-2 sm:px-14">
        <div className="flex items-center justify-between mt-5">
          <div className=" flex flex-col items-start gap-3  ">
            <p className="font-bold">{t('expenseAcc')}</p>
            <Select
              type="text"
              onChange={(val) => {
                setAccCode(val)
              }}
              value={accCode}
              loading={cashLoading}
              className="flex h-8 w-[350px] items-center justify-center"
            >
              {cashAccountsData
                .filter((cash) => cash.acctCode.startsWith('94'))
                .map((cash, idx) => (
                  <Select.Option key={idx} value={cash.acctCode}>
                    {`${cash.acctCode} - ${cash.acctName}`}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <div className="flex flex-col gap-3 ">
            <p className="font-bold">{t('currency')}</p>

            <Select
              type="text"
              onChange={(val) => {
                setCurrency(val)
              }}
              value={currency}
              className="flex h-8 w-[250px] items-center justify-center "
            >
              <Select.Option value={'USD'}>USD</Select.Option>
              <Select.Option value={'UZS'}>UZS</Select.Option>
            </Select>
          </div>
        </div>
        <div className="flex items-center justify-between mt-5">
          <div className=" flex flex-col items-start gap-3  ">
            <p className="font-bold">{t('dds')}</p>
            <Select
              type="text"
              onChange={(val) => {
                setDds(val)
              }}
              value={dds}
              loading={ddsLoading}
              className="flex h-8 w-[350px] items-center justify-center"
            >
              {ddsData.map((dds, idx) => (
                <Select.Option key={idx} value={dds.fldValue}>
                  {dds.descr}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="flex flex-col items-start gap-3 ">
            <p className="font-bold">{t('payingAmount')}</p>
            <Input
              type="text"
              onChange={(e) => {
                setAmount(e.target.value)
              }}
              value={amount}
              className="flex h-8 w-[250px] items-center justify-center border-[1px] border-[#D0D0D0]"
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full mt-5">
          <p className="font-bold">{t('comments')}</p>
          <TextArea
            onChange={(e) => {
              setComment(e.target.value)
            }}
            value={comment}
            className="w-full"
            rows={4}
          />
        </div>

        <div className="flex items-center gap-10 mt-14 justify-between">
          <Button
            onClick={() => {
              onClose()
            }}
            className="w-full bg-red-600 h-[35px] rounded-lg text-white"
          >
            {t('back')}
          </Button>
          <Button
            onClick={postData}
            loading={loading}
            disabled={loading}
            className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
          >
            {t('add')}
          </Button>
        </div>
      </div>
      <SuccessModal
        getRef={(r) => {
          successRef.current = r
        }}
      />
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r
        }}
      />
    </Modal>
  )
}

export default CreateOutgoingPaymentModal
