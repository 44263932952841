import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import mainReducer from '../slice/mainSlice'
import salesReducer from '../slice/salesSlice'
import productionReducer from '../slice/productionsSlice'

const rootReducer = combineReducers({
	main: mainReducer,
	sales: salesReducer,
	production: productionReducer,
})

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['persist/PERSIST'],
				ignoredActionPaths: ['register', 'rehydrate'],
			},
		}),
})

export const persistor = persistStore(store)

export default store
