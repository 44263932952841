export const calculateKvmetr = (total) => {
	const area = total
	const length = area.toString().length
	const divisor = Math.pow(10, length)
	return area / divisor
}

export const calculateTotalWithKoef = (width, height, quantity, koef = 1) => {
	return ((width * height) / 1000000) * parseFloat(quantity || 0) * koef
}

export const calculateMetrTotalWithKoef = (
	width,
	height,
	quantity,
	koef = 1,
) => {
	return (((width + height) * 2) / 1000) * parseFloat(quantity || 0) * koef
}

export const calculateLineTotal = (basePrice, servicesTotal, totalWithKoef) => {
	return (parseFloat(basePrice) + parseFloat(servicesTotal)) * totalWithKoef
}
