import React, { memo, useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import SuccessImage from '../../../assets/images/success.png'
import SuccessModalStyle from './SuccessModalStyle'
import { useTranslation } from 'react-i18next'

const SuccessModalWithTimer = ({ getRef = () => {}, onConfirm = () => {} }) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [successTitle, setSuccessTitle] = useState('Выполнено успешно')
	const [time, setTime] = useState(5)
	const { t } = useTranslation()
	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				setSuccessTitle(t)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [getRef])

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Enter' && isOpenModal) {
				onConfirm()
				setIsOpenModal(false)
			}
		}

		document.addEventListener('keydown', handleKeyPress)
		return () => document.removeEventListener('keydown', handleKeyPress)
	}, [isOpenModal, onConfirm])

	useEffect(() => {
		if (isOpenModal) {
			const timer = setInterval(() => {
				setTime(time - 1)
				if (time === 0) {
					setIsOpenModal(false)
					setTime(5)
				}
			}, 1000)
			return () => clearInterval(timer)
		}
	}, [isOpenModal, time])

	const formatTime = (seconds) => {
		const mins = Math.floor(Math.abs(seconds) / 60) * Math.sign(seconds) // Determine minutes with correct sign
		const secs = Math.abs(seconds) % 60 // Always take the absolute value of the remainder

		// Handle the formatting
		const sign = seconds < 0 ? '-' : '' // Determine overall sign
		const formattedMins = Math.abs(mins).toString().padStart(2, '0') // Absolute value of mins
		const formattedSecs = secs.toString().padStart(2, '0') // Always format seconds

		return `${sign}${formattedMins}:${formattedSecs}`
	}

	return (
		<Modal
			open={isOpenModal}
			onCancel={() => setIsOpenModal(false)}
			footer={null}
			centered
			closeIcon={<span style={{ fontSize: '1.5rem' }}>×</span>}
		>
			<SuccessModalStyle>
				<div className="card">
					<div className="flex justify-center">
						<img
							src={SuccessImage}
							alt="success"
							className="w-[100px]"
						/>
					</div>

					<h2 className="font-semibold mt-5 text-lg">
						{successTitle}
						{'!'}
					</h2>
					{/* {time > 0 && ( */}
					<p className="text-gray-500 text-sm mt-2">
						Пожалуйста, подождите{' '}
						<span className="font-bold text-xl">
							{formatTime(time)} секунд
						</span>
						, чтобы закрыть окно
					</p>
					{/* )} */}
					<div className="centerCard">
						<Button
							className="w-[100px] text-sm h-12 bg-green-600 text-white "
							onClick={() => {
								onConfirm()
								setIsOpenModal(false)
							}}
						>
							{t('understand')}
						</Button>
					</div>
				</div>
			</SuccessModalStyle>
		</Modal>
	)
}

export default memo(SuccessModalWithTimer)
