import React, { useState, useRef, useEffect } from 'react'
import { Modal, Input, Button, Select, AutoComplete } from 'antd'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { fetchItemsSelect } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../utils/debounce'
import DeleteIcon from '../../assets/icons/DeleteSvg'
import ConfirmExitModal from './ConfirmExitModal/index'
const EditPackageModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation()
	const successRef = useRef()
	const errorRef = useRef()

	const [items, setItems] = useState([])
	const [loading, setLoading] = useState(false)
	const [name, setName] = useState('')
	const [code, setCode] = useState('')
	const [searchItemName, setSearchItemName] = useState('')

	const initItem = {
		itemCode: '',
		itemName: '',
		u_Number: '',
		lineId: items ? items[items.length - 1]?.lineId + 1 : 1,
	}

	const patchData = async () => {
		setLoading(true)
		const poisLineUpdates = items.map((item) => {
			return {
				code: code,
				u_ItemName: item.itemName,
				u_ItemCode: item.itemCode,
				lineId: item.lineId,
			}
		})
		try {
			await http.patch('api/packageofservices', {
				code,
				poisLineUpdates,
			})
			successRef.current.open(t('sucEdited'))
			onClose()
			resetForm()
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}
	useEffect(() => {
		if (data) {
			setItems(
				data?.serviceLines.map((item) => ({
					itemCode: item.itemCode,
					itemName: item.itemName,
					lineId: item.lineId,
				})),
			)
			setCode(data?.code)
			setName(data?.name)
		}
	}, [data])

	const { data: itemsData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['items', searchItemName, 'услуги', 'api/items/get-items'],
		queryFn: fetchItemsSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const resetForm = () => {
		setCode('')
		setName('')
		setSearchItemName('')
		setItems([])
	}

	const handleItemSelect = (value, label, i) => {
		console.log(label)
		setSearchItemName('')
		const updatedItems = [...items]
		updatedItems[i] = {
			...updatedItems[i],
			itemCode: value,
			itemName: label?.data?.itemName,
			u_Number: label?.data?.u_Number,
		}
		setItems(updatedItems)
	}

	const handleItemChange = (val, label, i) => {
		const value = label.label || val
		if (val === label?.value) {
			handleItemNameChange('')
		} else {
			handleItemNameChange(value)
		}
		const updatedItems = [...items]
		updatedItems[i] = {
			...updatedItems[i],
			itemName: value,
		}
		setItems(updatedItems)
	}

	const handleItemNameChange = (val) => {
		debouncedSet(val, setSearchItemName)
	}
	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }])
	}

	const handleRemoveItem = (i) => {
		setItems((prev) => prev.filter((_, idx) => idx !== i))
	}

	if (!data) return null

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('editPackageService')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="50%"
			style={{ height: '70%' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center gap-5 justify-between mt-4">
					<div className=" flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('serviceGroupName')}`}</p>
						<Input
							type="text"
							value={name}
							onChange={(e) => {
								setName(e.target.value)
							}}
							disabled
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
					<div className=" flex flex-col gap-3 sm:mt-5 w-full">
						<p>{`${t('serviceGroupCode')}`}</p>
						<Input
							type="text"
							value={code}
							onChange={(e) => {
								setCode(e.target.value)
							}}
							disabled
							className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
					</div>
				</div>

				<table className="w-full text-sm m-5 text-left rtl:text-right text-gray-500">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
						<tr>
							<th className="px-6 py-4 border text-center">
								{t('service')}
							</th>

							<th className="px-6 py-4 border text-center"></th>
						</tr>
					</thead>
					<tbody>
						{items.map((v, i) => (
							<tr className="bg-white hover:bg-gray-50 " key={i}>
								<td className="px-6 border py-4 w-full">
									<AutoComplete
										loading={itemsLoading}
										options={itemsData?.map((item) => ({
											value: item.itemCode,
											label: (
												<span>
													<span className="font-bold">
														{item.u_Number
															? `${item.u_Number} `
															: ''}
													</span>
													{item.itemName}
												</span>
											),
											data: {
												itemName: item.itemName,
												u_Number: item.u_Number,
											},
										}))}
										className="w-full"
										value={`${v.u_Number ? `${v.u_Number}` : ''} ${v.itemName}`}
										onSelect={(value, label) =>
											handleItemSelect(
												value,
												label,

												i,
											)
										}
										onChange={(val, label) => {
											handleItemChange(val, label, i)
										}}
										showSearch
									/>
								</td>

								<td className="px-6 border py-4">
									<div className="flex items-center justify-start gap-2 w-[100px]">
										{items.length > 1 && (
											<button
												onClick={() =>
													handleRemoveItem(i)
												}
												className="bg-[#d62a2a] text-white w-[30px] h-[35px] rounded-lg flex justify-center items-center"
											>
												<DeleteIcon />
											</button>
										)}
										{i === items.length - 1 && (
											<Button
												type="primary"
												onClick={handleAddItem}
											>
												+
											</Button>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('cancel')}
					</Button>
					<Button
						onClick={patchData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('save')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default EditPackageModal
