import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table, Input, Button } from 'antd'
import Layout from '../../../components/Layout/index.jsx'
import ViewProductionWorkersModal from '../../../components/Modal/viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../../components/Modal/SuccessModalWithTimer/index.jsx'
import ErrorModalWithTimer from '../../../components/Modal/ErrorModalWithTimer/index.jsx'
import { columnProductionWorkersDone } from '../../../utils/columns.jsx'
import { fetchProductionWorkersDone } from '../../../utils/fetchData.js'
import moment from 'moment'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import './table.css'
import { Search } from 'lucide-react'
import { debouncedSet } from '../../../utils/debounce'
import { handleMarshrutWorkers } from '../../../utils/navigateToPdf'
import { switchBgColor } from '../../../utils/switchBgProductionsTables.js'

const ProductionManagementDone = () => {
	const { t } = useTranslation()
	const formattedDate = moment().format('YYYY-MM-DD')
	const { roleNames, getMe } = useSelector((state) => state.main)
	const successRef = useRef()
	const errorRef = useRef()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)
	const [pageSize] = useState('10')
	const [inputDocNum, setInputDocNum] = useState('')

	const { data: productionWorkersData = [], isLoading } = useQuery({
		queryKey: [
			'productionWorkersDone',
			currentPage,
			pageSize,
			startDate,
			endDate,
			docNum,
			roleNames.includes('admin') ||
			roleNames.includes('ProdManager') ||
			roleNames.includes('Worker')
				? ''
				: getMe?.department,
			roleNames.includes('admin') ? '' : getMe?.u_Service,
		],
		queryFn: fetchProductionWorkersDone,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (productionWorkersData.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleNavigateSeries = (record) => {
		console.log('record', record)
		localStorage.setItem('seriesData', JSON.stringify(record))
		const url = `/series-one-barcode`
		window.open(url, '_blank')
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const columns = columnProductionWorkersDone(
		t,
		handleRowClick,
		handleNavigateSeries,
		handleDocNumChange,
		handleMarshrutWorkers,
	)

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
					{t('finishedProductions')}
				</h1>

				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14" />

				<div className="mt-5 ml-8 flex items-end">
					<div className="flex flex-col gap-2">
						<span className="font-bold text-sm">{t('docNum')}</span>
						<Input
							className="w-[250px] h-8"
							placeholder={t('docNum')}
							onChange={(e) => {
								setInputDocNum(e.target.value)
								if (e.target.value === '') setDocNum('')
							}}
							value={inputDocNum}
						/>
					</div>
					<Button
						type="primary"
						className="h-8 rounded-none"
						onClick={() => setDocNum(inputDocNum)}
						icon={<Search />}
					/>
				</div>
				<div className="flex mt-5 w-full flex-col sm:flex-row items-end justify-between gap-4  mr-5  ml-4 sm:gap-7 sm:px-5">
					<div className="  flex gap-5 flex-col sm:flex-row">
						<div className="flex flex-col gap-3">
							<p>{t('startDate')}</p>
							<Input
								type="date"
								value={startDate}
								className="w-[150px]"
								onChange={handleStartDate}
								max={endDate}
								allowClear
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p>{t('endDate')}</p>
							<Input
								type="date"
								value={endDate}
								className="w-[150px]"
								onChange={handleEndDate}
								min={startDate}
								max={formattedDate}
								allowClear
							/>
						</div>
					</div>
					<div className="flex mr-5">
						<button
							onClick={handlePreviousPage}
							disabled={currentPage === 0}
							className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
						>
							{t('previous')}
						</button>
						<div className="bg-[#099204] w-[20px] h-[30px] flex items-center justify-center">
							<span className="text-center text-white">
								{currentPage + 1}
							</span>
						</div>
						<button
							onClick={handleNextPage}
							disabled={productionWorkersData.length !== 10}
							className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[100px]"
						>
							{t('next')}
						</button>
					</div>
				</div>

				<div className="ml-4">
					<Table
						columns={columns}
						dataSource={productionWorkersData}
						pagination={false}
						className="mt-6 w-full sm:mt-10 ant-table-override"
						rowKey={(record) => record.key}
						bordered
						scroll={{ x: 900 }}
						loading={isLoading}
						rowClassName={switchBgColor}
					/>
				</div>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsModalVisible(false)
				}}
			/>
			<SuccessModalWithTimer
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModalWithTimer
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Layout>
	)
}

export default ProductionManagementDone
