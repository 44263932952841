import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnExpectedPayments } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewSalesModal from '../../../components/Modal/ViewSalesWithDocEntryModal'
import { useQuery } from '@tanstack/react-query'
import { fetchExpectedPayments } from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'
import { useSelector } from 'react-redux'

const ExpectedPayments = () => {
	const { t } = useTranslation()
	const formattedDate = getToday()
	const roleNames = useSelector((state) => state.main.roleNames)

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [cardName, setCardName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)

	const { data = [], isLoading } = useQuery({
		queryKey: [
			'expactedPayment',
			currentPage,
			cardName,
			startDate,
			endDate,
		],
		queryFn: fetchExpectedPayments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}

	const columns = columnExpectedPayments(
		t,
		handleRowClick,
		handleCardNameChange,
	)

	return (
		<Layout>
			<Header
				title={t('expectedPayments')}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				isSticky={false}
				formattedDate={formattedDate}
			/>
			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				ship={roleNames.includes('admin') ? true : false}
				incoming={
					roleNames.includes('admin') || roleNames.includes('Cashier')
						? true
						: false
				}
			/>
		</Layout>
	)
}

export default ExpectedPayments
