import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnSeeSales } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewSalesModal from '../../../components/Modal/ViewSalesWithDocEntryModal'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { fetchSeeSales } from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'

const SeeSales = () => {
	const { t } = useTranslation()
	const location = useLocation()

	const queryParams = window.location.search.split('cardCode=')[1]
	const cardCode =
		window.location.search.split('cardCode=')[1] ||
		localStorage.getItem('cardCode')

	console.log('SeeSales CardCode', cardCode)
	console.log('Query', queryParams)

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const formattedDate = getToday()

	const [docNum, setDocNum] = useState('')
	const [cardName, setCardName] = useState('')
	const [slpName, setSlpName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'seeSales',
			currentPage,
			cardCode,
			docNum,
			startDate,
			endDate,
		],
		queryFn: fetchSeeSales,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName)
	}

	const columns = columnSeeSales(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange,
	)
	return (
		<Layout>
			<Header
				title={'act-sverka'}
				currentPage={currentPage + 1}
				// handlePreviousPage={handlePreviousPage}
				// handleNextPage={handleNextPage}
				// hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
			/>
			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				ship={false}
				incoming={false}
			/>
		</Layout>
	)
}

export default SeeSales
