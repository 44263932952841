import React, { useEffect, useState, useRef } from 'react'
import Layout from '../../../components/Layout'
import { Button, message, Table, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import ChangeRateModal from '../../../components/Modal/ChangeRate'
import { useLocation } from 'react-router-dom'
import { EyeIcon, FileText, Upload as UploadIcon } from 'lucide-react'
import moment from 'moment'
import OriginalFileModal from '../../../components/Modal/OriginalFileModal/index'
import ProcessedFileModal from '../../../components/Modal/ProcessedFileModal/index'
import { http } from '../../../services/http'

const BankStatements = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const code = location.pathname.split('/')[2]
	const tableContainerRef = useRef(null)

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isOriginalModalVisible, setIsOriginalModalVisible] = useState(false)
	const [isProcessedModalVisible, setIsProcessedModalVisible] =
		useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [dragOver, setDragOver] = useState(false)
	const [fileName, setFileName] = useState('')
	const [error, setError] = useState('')
	const [file, setFile] = useState(null)
	const [data, setData] = useState([])
	const [btnLoading, setBtnLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [hasMore, setHasMore] = useState(true)
	const [currentPage, setCurrentPage] = useState(0)
	const pageSize = 20
	const [accCode, setAccCode] = useState('')

	useEffect(() => {
		fetchData(accCode, currentPage, pageSize)
	}, [code, accCode, currentPage, pageSize])

	useEffect(() => {
		setAccCode(code)
		setIsLoading(false)
		setHasMore(true)
		setCurrentPage(0)
		fetchData(code, 0, pageSize)
	}, [code])
	const fetchData = async (code, page, pageSize) => {
		if (isLoading || !hasMore) return

		setIsLoading(true)
		try {
			const { data } = await http.get(
				`api/bankstatements/uploaded-drafts?accountCode=${code}&skip=${page}&pageSize=${pageSize}`,
			)
			console.log('data', data)

			if (page === 0) {
				setData(data)
			} else {
				setData((prevData) => [...prevData, ...data])
			}
			setHasMore(data.length === pageSize)
		} catch (err) {
			message.error(err?.response?.data?.message)
		} finally {
			setIsLoading(false)
		}
	}

	const handleDragOver = (e) => {
		e.preventDefault()
		setDragOver(true)
	}

	const handleDragLeave = () => {
		setDragOver(false)
	}

	const handleDrop = (e) => {
		e.preventDefault()
		setDragOver(false)

		const file = e.dataTransfer.files[0]
		validateFile(file)
	}

	const handleFileSelect = (e) => {
		const file = e.target.files[0]
		console.log('Selected file = ', file)
		setFile(file)
		validateFile(file)
	}

	const validateFile = (file) => {
		if (!file) return

		const validTypes = [
			'application/vnd.ms-excel', // .xls
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
		]

		if (validTypes.includes(file.type)) {
			setFileName(file.name)
			setError('')
			setFile(file)
		} else {
			setFileName('')
			setFile(null)
			setError('Please upload a valid Excel file (.xls, .xlsx).')
		}
	}

	const columns = [
		{
			title: t('code'),
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: t('fileName'),
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: t('addedDate'),
			dataIndex: 'u_AddedDate',
			key: 'u_AddedDate',
			render: (text) => {
				return (
					<span>
						{text
							? moment(text).format('DD-MM-YYYY HH:mm:ss')
							: '-'}
					</span>
				)
			},
		},
		{
			title: t('status'),
			dataIndex: 'u_UploadStatus',
			key: 'u_UploadStatus',
			render: (text) => {
				return (
					<span>
						{text === 'Completed' ? (
							<Tag className="rounded-xl" color="green">
								{t(text)}
							</Tag>
						) : text === 'Pending' ? (
							<Tag className="rounded-xl" color="yellow">
								{t(text)}
							</Tag>
						) : (
							<Tag className="rounded-xl" color="red">
								{text}
							</Tag>
						)}
					</span>
				)
			},
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			render: (text, record) => {
				return (
					<div className="flex items-center justify-center gap-2">
						<Button
							type="default"
							onClick={() => {
								setIsOriginalModalVisible(true)
								setSelectedRowData(record)
							}}
						>
							<div className="flex items-center gap-1">
								<span>
									<EyeIcon size={18} />
								</span>
								<span>{t('original')}</span>
							</div>
						</Button>
						<Button
							type="default"
							onClick={() => {
								setIsProcessedModalVisible(true)
								setSelectedRowData(record)
							}}
						>
							<div className="flex items-center gap-1">
								<span>
									<FileText size={18} />
								</span>
								<span>{t('processed')}</span>
							</div>
						</Button>
					</div>
				)
			},
		},
	]

	const handleRateModalClose = () => {
		setIsModalVisible(false)
	}
	const handleOriginalModalClose = () => {
		setIsOriginalModalVisible(false)
	}

	const handleProcessedModalClose = () => {
		setIsProcessedModalVisible(false)
	}

	const handleUploadFile = async (file) => {
		setBtnLoading(true)

		if (!file) {
			message.error('Файл не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', file)

		try {
			const data = await http
				.post(`api/bankstatements/upload-excel/${code}`, formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				})
				.then((res) => {
					setCurrentPage(0)
				})

			fetchData(accCode, 0, pageSize)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error(err?.response?.data?.message)
		} finally {
			setBtnLoading(false)
		}
	}

	const handleScroll = () => {
		if (!tableContainerRef.current) return

		const { scrollTop, scrollHeight, clientHeight } =
			tableContainerRef.current
		// Load more when user scrolls to bottom (with a 20px threshold)
		if (scrollTop + clientHeight >= scrollHeight - 20) {
			setCurrentPage((page) => page + 1)
		}
	}

	const refetch = () => {
		setCurrentPage(0)
		fetchData(accCode, 0, pageSize)
	}

	return (
		<Layout>
			<div className="p-5 px-10">
				<h1 className="text-2xl font-bold text-basic">
					{t('bankStatements')}-{code}
				</h1>
				<div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white p-5 drop-shadow-md">
					<span className="text-xl font-bold text-basic">
						{t('filtersPayments')}
					</span>
					<div className="mt-10 flex items-center gap-2">
						<div
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={handleDrop}
							className={`border-2 ${
								dragOver ? 'border-blue-500' : 'border-gray-300'
							} w-full cursor-pointer rounded-lg border-dashed p-10 text-center transition-colors`}
						>
							<input
								type="file"
								accept=".xls,.xlsx"
								onChange={handleFileSelect}
								className="hidden"
								id="fileInput"
							/>
							<label
								htmlFor="fileInput"
								className="cursor-pointer"
							>
								<p className="text-gray-500">
									{fileName && file ? (
										<span className="font-medium text-blue-500">
											{fileName}
										</span>
									) : (
										<div className="flex items-center justify-center gap-2 text-center text-lg">
											<UploadIcon />
											Drop Excel files to Attach, or{' '}
											<span className="text-blue-500 underline">
												browse
											</span>
										</div>
									)}
								</p>
							</label>
							{error && (
								<p className="mt-2 text-sm text-red-500">
									{error}
								</p>
							)}
						</div>
						<Button
							type="primary"
							loading={btnLoading}
							disabled={!file}
							className="bg-basic text-white hover:!bg-sky-700"
							onClick={() => {
								handleUploadFile(file)
							}}
						>
							{t('upload')}
						</Button>
					</div>
				</div>
				<div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white drop-shadow-md">
					<div className="p-5">
						<div className="flex items-center justify-between">
							<span className="text-xl font-bold text-basic">
								{t('Uploaded Payment Drafts')}
							</span>
						</div>
						<div
							ref={tableContainerRef}
							onScroll={handleScroll}
							className="max-h-[600px] overflow-auto"
						>
							<Table
								columns={columns}
								dataSource={data}
								className="mt-10"
								pagination={false}
							/>
						</div>
					</div>
				</div>
			</div>
			<ChangeRateModal
				visible={isModalVisible}
				data={data}
				onClose={handleRateModalClose}
			/>
			<OriginalFileModal
				visible={isOriginalModalVisible}
				data={selectedRowData}
				onClose={handleOriginalModalClose}
				code={code}
			/>
			<ProcessedFileModal
				visible={isProcessedModalVisible}
				data={selectedRowData}
				onClose={handleProcessedModalClose}
				code={code}
				refetch={refetch}
			/>
		</Layout>
	)
}

export default BankStatements
