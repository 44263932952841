import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnDebtors } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import { debouncedSet } from '../../../utils/debounce'
import { useQuery } from '@tanstack/react-query'
import { fetchDebtors } from '../../../utils/fetchData'
import { Table, Input } from 'antd'

const Debtors = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [currentPage, setCurrentPage] = useState(0)
	const [cardName, setCardName] = useState('')
	const [docNum, setDocNum] = useState('')

	const { data = [], isLoading } = useQuery({
		queryKey: ['debtors', currentPage, cardName, docNum],
		queryFn: fetchDebtors,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleSeeBtnClick = (cardCode) => {
		navigate(
			`/see-sales-customers?cardCode=${encodeURIComponent(cardCode)}`,
		)
	}

	const columns = columnDebtors(t, handleCardNameChange, handleSeeBtnClick)

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<div className="flex w-fullitems-center justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
					<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">
						{t('Accounts receivable')}
					</h1>
				</div>

				<div className="mt-8 w-full border-[1px] border-[#E8E8E8] sm:mt-8"></div>

				<div className="mt-6 sm:ml-10 sm:mt-10">
					<div className="mb-4 flex flex-col justify-between sm:flex-row">
						<div className="mt-2 flex sm:mr-10">
							<Input.Search
								// value={docNum}
								onChange={(e) =>
									handleDocNumChange(e.target.value)
								}
								className="w-[250px] h-8"
								placeholder={t('docNum')}
							/>
						</div>
						<div className="mt-2 flex sm:mr-10">
							<button
								onClick={handlePreviousPage}
								disabled={currentPage === 0}
								className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
							>
								{t('previous')}
							</button>
							<div className="bg-[#099204]  w-[20px] h-[30px] flex items-center  justify-center">
								<span className="text-center text-white">
									{currentPage + 1}
								</span>
							</div>
							<button
								onClick={handleNextPage}
								disabled={!data.length === 10}
								className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 "
							>
								{t('next')}
							</button>
						</div>
					</div>

					<div className="mr-10 mt-10 mb-10">
						<Table
							columns={columns}
							dataSource={data}
							pagination={false}
							bordered
							className="w-full"
							rowKey="docEntry"
							// rowClassName="text-center"
							loading={isLoading}
						/>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Debtors
