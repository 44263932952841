import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { http } from '../services/http'

// Async thunk for submitting data
export const submitSalesOrder = createAsyncThunk(
	'sales/submitOrder',
	async ({ payload, sendFile }, { rejectWithValue }) => {
		try {
			const response = await http.post(
				`/api/sales/sotuv-buyurtmasi?needToConfirmFromBot=${sendFile}`,
				payload,
			)
			return response.data // Success response
		} catch (error) {
			return rejectWithValue(
				error.response?.data?.message || 'Error occurred',
			)
		}
	},
)

const salesSlice = createSlice({
	name: 'sales',
	initialState: {
		loading: false,
		error: null,
		success: false,
		progress: 0,
		testState: false,
	},
	reducers: {
		resetState: (state) => {
			state.loading = false
			state.error = null
			state.success = false
			state.progress = 0
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitSalesOrder.pending, (state) => {
				state.loading = true
				state.success = false
				state.error = null
				state.progress = 20 // Initial progress
			})
			.addCase(submitSalesOrder.fulfilled, (state) => {
				state.loading = false
				state.success = true
				state.progress = 100 // Complete progress
			})
			.addCase(submitSalesOrder.rejected, (state, action) => {
				state.loading = false
				state.error = action.payload
				state.progress = 0
			})
	},
})

export const { resetState } = salesSlice.actions
export default salesSlice.reducer
