import { Modal, Input, Button, Select } from 'antd'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import {
	fetchItemsStockTransfers,
	fetchWarehouse,
	fetchDepartments,
} from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

const CreateStockTransferlinesModal = ({ visible, onClose, refetch }) => {
	const { t } = useTranslation()
	const successRef = useRef()
	const errorRef = useRef()
	const { department, roleNames } = useSelector((state) => state.main)

	const initItem = {
		itemCode: '',
		quantity: '',
	}

	const [items, setItems] = useState([{ ...initItem }])
	const [loading, setLoading] = useState(false)
	const [fromWhs, setFromWhs] = useState('')
	const [toWhs, setToWhs] = useState('')
	const [departmentAdmin, setDepartment] = useState(department)

	const postData = async () => {
		setLoading(true)

		try {
			const stockTransferLines = items.map((item, idx) => ({
				itemCode: item.itemCode,
				quantity: item.quantity,
				warehouseCode: toWhs,
				fromWarehouseCode: fromWhs,
				lineNum: idx,
			}))
			await http.post('api/stocktransfers', {
				toWarehouse: toWhs,
				fromWarehouse: fromWhs,
				u_Department: departmentAdmin,
				stockTransferLines,
			})
			successRef.current.open(t('sucCreated'))
			onClose()
			refetch()
			resetForm()
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	const { data: warehouseData = [] } = useQuery({
		queryKey: ['warehouses'],
		queryFn: fetchWarehouse,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: departmentData = [] } = useQuery({
		queryKey: ['departments'],
		queryFn: fetchDepartments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: itemsData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['itemsStockTransfers'],
		queryFn: fetchItemsStockTransfers,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }])
	}

	const handleRemoveItem = (i) => {
		setItems((prev) => prev.filter((_, idx) => idx !== i))
	}

	const handleItemChange = (value, i) => {
		const updatedItems = [...items]
		updatedItems[i] = {
			...updatedItems[i],
			itemCode: value,
		}
		setItems(updatedItems)
	}

	const handleInputChange = (value, i) => {
		const updatedItems = [...items]
		updatedItems[i] = {
			...updatedItems[i],
			quantity: value,
		}
		setItems(updatedItems)
	}

	const resetForm = () => {
		setItems([{ ...initItem }])
		setFromWhs('')
		setToWhs('')
		setDepartment('')
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('AddProduct')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			style={{ height: '70%' }}
			bodyStyle={{ height: 'calc(100% - 55px)', overflowY: 'auto' }}
		>
			<div className="w-full px-2 sm:px-14">
				<div className="flex items-center gap-5 justify-between">
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{t('fromWhs')}</p>
						<Select
							value={fromWhs}
							onChange={(value) => setFromWhs(value)}
							className="w-full h-10"
							options={warehouseData
								.filter((whs) => {
									return whs.code !== toWhs
								})
								.map((warehouse) => ({
									label: warehouse.name,
									value: warehouse.code,
								}))}
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
						<p>{t('toWhs')}</p>
						<Select
							value={toWhs}
							onChange={(value) => setToWhs(value)}
							className="w-full h-10"
							options={warehouseData
								.filter((whs) => {
									return whs.code !== fromWhs
								})
								.map((warehouse) => ({
									label: warehouse.name,
									value: warehouse.code,
								}))}
						/>
					</div>
					{roleNames.includes('admin') && (
						<div className="mt-4 flex flex-col gap-3 sm:mt-5 w-full">
							<p>{t('departmentName')}</p>
							<div className="flex">
								<Select
									value={departmentAdmin}
									className="flex h-8  w-[150px] items-center justify-center "
									style={{ color: 'black' }}
									onChange={(val) => {
										setDepartment(val)
									}}
									options={departmentData.map(
										(department) => {
											return {
												label: department.name,
												value: Number(department.code),
											}
										},
									)}
								/>
							</div>
						</div>
					)}
				</div>

				<table className="w-full text-sm mt-7 text-left rtl:text-right text-gray-500">
					<thead className="text-xs text-gray-700 uppercase bg-gray-50">
						<tr>
							<th className="px-6 py-4 border text-center">
								{t('item')}
							</th>

							<th className="px-6 py-4 border text-center">
								{t('kv.m')}
							</th>

							<th className="px-6 py-4 border text-center"></th>
						</tr>
					</thead>
					<tbody>
						{items.map((v, i) => (
							<tr className="bg-white hover:bg-gray-50 " key={i}>
								<td className="px-6 border py-4 w-full">
									<Select
										value={v.itemCode}
										onChange={(value) =>
											handleItemChange(value, i)
										}
										className="w-full h-10"
										options={itemsData.map((item) => ({
											label: item.itemName,
											value: item.itemCode,
										}))}
									/>
								</td>
								<td className="px-6 border py-4 w-full">
									<Input
										className="w-[200px]"
										type="number"
										// placeholder={`${t('quantity')}`}
										value={v.quantity}
										onChange={(e) =>
											handleInputChange(e.target.value, i)
										}
									/>
								</td>

								<td className="px-6 border py-4">
									<div className="flex items-center gap-2">
										{items.length > 1 && (
											<Button
												type="danger"
												onClick={() =>
													handleRemoveItem(i)
												}
												className="bg-red-600 text-white"
											>
												-
											</Button>
										)}
										{i === items.length - 1 && (
											<Button
												type="primary"
												onClick={handleAddItem}
											>
												+
											</Button>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							onClose()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('cancel')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('add')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default CreateStockTransferlinesModal
