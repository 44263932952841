import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Auth from '../pages/auth'
import PurchaseOrder from '../pages/sales/PurchaseOrder'
import PurchaseInvoices from '../pages/sales/PurchaseInvoices'
import PurchaseIncoming from '../pages/sales/PurchaseIncoming'
import CreatePurchaseOrder from '../pages/sales/PurchaseOrder/createPurchaseOrder'
import Items from '../pages/sales/Items'
import Suppliers from '../pages/sales/Suppliers/index'
import MySales from '../pages/sales/MySales'
import Debtors from '../pages/sales/Debtors'
import FinishedSales from '../pages/sales/FinishedSales'
import ShippedSales from '../pages/sales/ShippedSales'
import Clients from '../pages/sales/Clients'
import SalesOrder from '../pages/sales/SalesOrder'
import CreateSales from '../pages/sales/SalesOrder/Create'
import Sales from '../pages/sales/Sales'
import OutgoingPayments from '../pages/sales/Payments/OutgoingPayments'
import IncomingPayments from '../pages/sales/Payments/IncomingPayments/index'
import BalancePage from '../pages/sales/Cash/Balance/index'
import SendMoney from '../pages/sales/Cash/SendMoney/index'
import Reports from '../pages/sales/Reports/index'
import SeeSales from '../pages/sales/SeeSales'
import OrderPage from '../pages/sales/OrderPage'
import MarshrutPage from '../pages/sales/MarshrutPage/index'
import ItemsAdmin from '../pages/sales/ItemsAdmin'
import StockTransfers from '../pages/sales/StockTranfers/index'
import PackageOfServices from '../pages/sales/PackageOfSerivces/index'
import Employees from '../pages/sales/Employees/index'
import ItemsServices from '../pages/sales/ItemsServices/index'
import ExpectedPayments from '../pages/sales/ExpectedPayments/index'
import Branches from '../pages/sales/Branches/index'
import Cashs from '../pages/sales/Cashs/index'
import LandedCosts from '../pages/sales/LandedCosts/index'
import ItemsTable from '../pages/sales/ItemsTable'
import ItemsServicesTable from '../pages/sales/ItemsServicesTable'
import DebtorsAdmin from '../pages/sales/DebtorsAdmin/index'
import SalesOrderRequestAdmin from '../pages/sales/SalesOrderRequestAdmin'
import IgnoredSalesOrders from '../pages/sales/IgnoredSalesOrders'
import ProductionOrders from '../pages/sales/ProductionOrder'
import ProductionWorkers from '../pages/sales/ProductionWorkers'
import SeriesBarcodePage from '../pages/sales/SeriesBarcodePage'
import ItemsShapes from '../pages/sales/ItemsShapes'
import ItemShapesTable from '../pages/sales/ItemsShapesTable'
import OneSeriesBarcodePage from '../pages/sales/SeriesOneBarcodePage'
import ProdManagerSeriesBarcodePage from '../pages/sales/SeriesBarcodePageProdManager'
import ProductionManagementDone from '../pages/sales/ProductionWorkersDone'
import MarshrutPageForWorkers from '../pages/sales/MarshrutPageForWorkers'
import ProductionWorkersAdmin from '../pages/sales/ProductionWorkersAdmin'
import AdminSeriesBarcodePage from '../pages/sales/SeriesBarcodePageAdmin'
import CheckDebtors from '../pages/sales/DebtorsCheck'
import DebtorsSalesPerson from '../pages/sales/DebtorsSalesPerson'
import ActSverka from '../../src/pages/sales/ActSverka'
import DashboardAccountant from '../pages/sales/DashboardAccountant'
import ConversionAccountant from '../pages/sales/ConversionAccountant'
import LoadedPayments from '../pages/sales/LoadedPayments'
import BankStatements from '../pages/sales/BankStatements/index'
import Recomendations from '../pages/sales/Recommendations'
import SeeSalesCustomers from '../pages/sales/SeeSalesCustomers'
import DashboardWorkers from '../pages/sales/DashboardWorkers'
const ProtectedRoute = ({ element: Component, ...rest }) => {
	const isAuthenticated = useSelector((state) => state.main.isAuthenticated)
	return isAuthenticated ? (
		<Component {...rest} />
	) : (
		<Navigate to="/auth" replace />
	)
}

const RoutesComponent = () => {
	const isAuthenticated = useSelector((state) => state.main.isAuthenticated)

	return (
		<Routes>
			<Route
				path="/"
				element={
					<Navigate
						to={isAuthenticated ? '/my-sales' : '/auth'}
						replace
					/>
				}
			/>
			<Route path="/auth" element={<Auth />} />
			<Route
				path="/conversionAccountant"
				element={<ProtectedRoute element={ConversionAccountant} />}
			/>
			<Route
				path="/recomendations"
				element={<ProtectedRoute element={Recomendations} />}
			/>
			<Route
				path="/bankStatements"
				element={<ProtectedRoute element={BankStatements} />}
			/>
			<Route
				path="/loadedPayments"
				element={<ProtectedRoute element={LoadedPayments} />}
			/>
			<Route
				path="/sales-order-request"
				element={<ProtectedRoute element={SalesOrderRequestAdmin} />}
			/>
			<Route
				path="/series-one-barcode"
				element={<ProtectedRoute element={OneSeriesBarcodePage} />}
			/>
			<Route
				path="/admin-series-barcode"
				element={<ProtectedRoute element={AdminSeriesBarcodePage} />}
			/>
			<Route
				path="/prod-manager-series-barcode"
				element={
					<ProtectedRoute element={ProdManagerSeriesBarcodePage} />
				}
			/>
			<Route
				path="/dashboardAccountant"
				element={<ProtectedRoute element={DashboardAccountant} />}
			/>
			<Route
				path="/prod-workers-admin"
				element={<ProtectedRoute element={ProductionWorkersAdmin} />}
			/>
			<Route
				path="/ignored-sales-orders"
				element={<ProtectedRoute element={IgnoredSalesOrders} />}
			/>
			<Route
				path="/purchase-order"
				element={<ProtectedRoute element={PurchaseOrder} />}
			/>
			<Route
				path="/orders"
				element={<ProtectedRoute element={OrderPage} />}
			/>
			<Route
				path="/marshrut"
				element={<ProtectedRoute element={MarshrutPage} />}
			/>
			<Route
				path="/purchase-incoming"
				element={<ProtectedRoute element={PurchaseIncoming} />}
			/>
			<Route
				path="/purchase-invoices"
				element={<ProtectedRoute element={PurchaseInvoices} />}
			/>
			<Route
				path="/production-orders"
				element={<ProtectedRoute element={ProductionOrders} />}
			/>
			<Route
				path="/production-workers"
				element={<ProtectedRoute element={ProductionWorkers} />}
			/>{' '}
			<Route
				path="/production-workers-done"
				element={<ProtectedRoute element={ProductionManagementDone} />}
			/>
			<Route path="/items" element={<ProtectedRoute element={Items} />} />
			<Route
				path="/itemsAdmin"
				element={<ProtectedRoute element={ItemsAdmin} />}
			/>
			<Route
				path="/itemsAdmin"
				element={<ProtectedRoute element={ItemsAdmin} />}
			/>
			<Route
				path="/itemsShapes"
				element={<ProtectedRoute element={ItemsShapes} />}
			/>
			<Route
				path="/itemsShapesTable"
				element={<ProtectedRoute element={ItemShapesTable} />}
			/>
			<Route
				path="/itemsServices"
				element={<ProtectedRoute element={ItemsServices} />}
			/>
			<Route
				path="/expected-payments"
				element={<ProtectedRoute element={ExpectedPayments} />}
			/>
			<Route
				path="/branches"
				element={<ProtectedRoute element={Branches} />}
			/>
			<Route path="/cashs" element={<ProtectedRoute element={Cashs} />} />
			<Route
				path="/itemsTable"
				element={<ProtectedRoute element={ItemsTable} />}
			/>
			<Route
				path="/servicesTable"
				element={<ProtectedRoute element={ItemsServicesTable} />}
			/>
			<Route
				path="/act-sverka"
				element={<ProtectedRoute element={ActSverka} />}
			/>
			<Route
				path="/landed-costs"
				element={<ProtectedRoute element={LandedCosts} />}
			/>
			<Route
				path="/suppliers"
				element={<ProtectedRoute element={Suppliers} />}
			/>
			<Route
				path="/marshrut-for-workers"
				element={<ProtectedRoute element={MarshrutPageForWorkers} />}
			/>
			<Route
				path="/series-barcode"
				element={<ProtectedRoute element={SeriesBarcodePage} />}
			/>
			<Route
				path="/create-purchase-order"
				element={<ProtectedRoute element={CreatePurchaseOrder} />}
			/>
			<Route
				path="/stock-transfers"
				element={<ProtectedRoute element={StockTransfers} />}
			/>
			<Route
				path="/employees"
				element={<ProtectedRoute element={Employees} />}
			/>
			<Route
				path="/dashboard-workers"
				element={<ProtectedRoute element={DashboardWorkers} />}
			/>
			<Route
				path="/package-services"
				element={<ProtectedRoute element={PackageOfServices} />}
			/>
			<Route
				path="/see-sales"
				element={<ProtectedRoute element={SeeSales} />}
			/>
			<Route
				path="/see-sales-customers"
				element={<ProtectedRoute element={SeeSalesCustomers} />}
			/>
			<Route
				path="/my-sales"
				element={<ProtectedRoute element={MySales} />}
			/>
			<Route
				path="/sales-order"
				element={<ProtectedRoute element={SalesOrder} />}
			/>
			<Route
				path="/sales-order/add"
				element={<ProtectedRoute element={CreateSales} />}
			/>
			<Route
				path="/debtors"
				element={<ProtectedRoute element={Debtors} />}
			/>
			<Route
				path="/debtorsAdmin"
				element={<ProtectedRoute element={DebtorsAdmin} />}
			/>
			<Route
				path="/finished-sales"
				element={<ProtectedRoute element={FinishedSales} />}
			/>
			<Route path="/sales" element={<ProtectedRoute element={Sales} />} />
			<Route
				path="/outgoing-payments"
				element={<ProtectedRoute element={OutgoingPayments} />}
			/>
			<Route
				path="/incoming-payments"
				element={<ProtectedRoute element={IncomingPayments} />}
			/>
			<Route
				path="/balance"
				element={<ProtectedRoute element={BalancePage} />}
			/>
			<Route
				path="/check-debtors"
				element={<ProtectedRoute element={CheckDebtors} />}
			/>
			<Route
				path="/sales-person-debtors"
				element={<ProtectedRoute element={DebtorsSalesPerson} />}
			/>
			<Route
				path="/send-money"
				element={<ProtectedRoute element={SendMoney} />}
			/>
			<Route
				path="/reports"
				element={<ProtectedRoute element={Reports} />}
			/>
			<Route
				path="/shipped-sales"
				element={<ProtectedRoute element={ShippedSales} />}
			/>
			<Route
				path="/clients"
				element={<ProtectedRoute element={Clients} />}
			/>
		</Routes>
	)
}

export default RoutesComponent
