import React from 'react'
import { motion } from 'framer-motion'

const Stars = React.memo(() => {
	const stars = Array.from({ length: 100 }).map((_, index) => (
		<motion.div
			key={index}
			className="absolute rounded-full bg-white opacity-80"
			style={{
				width: `${Math.random() * 3 + 1}px`,
				height: `${Math.random() * 3 + 1}px`,
				top: `${Math.random() * 100}%`,
				left: `${Math.random() * 100}%`,
			}}
			animate={{
				opacity: [0.5, 1, 0.5],
				y: [0, -5, 5, 0],
			}}
			transition={{ duration: Math.random() * 8 + 1, repeat: Infinity }}
		/>
	))
	return <>{stars}</>
})

export default Stars
