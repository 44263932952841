import { Input, Table, Button, message, Modal } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useSelector } from 'react-redux'
import OrderPaymentModal from '.././orderPaymentModal'
import { http } from '../../../services/http'
import SuccessModal from '../../Modal/SuccessModal'
import ErrorModal from '../../Modal/ErrorModal'
import { config } from '../../../config.js'

const SalesInfo = ({
	data,
	onClose = () => {},
	ship = false,
	incoming = false,
	refetch = () => {},
}) => {
	const { t } = useTranslation()

	const roleNames = useSelector((state) => state.main.roleNames)
	const employeeId = useSelector((state) => state.main.employeeId)
	const salesPersonCode = useSelector((state) => state.main.salesPersonCode)
	const department = useSelector((state) => state.main.department)

	const successRef = useRef()
	const errorRef = useRef()

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [shipBtnLoading, setShipBtnLoading] = useState(false)
	const [dueDate, setDueDate] = useState('')
	const [deadLine, setDeadline] = useState('')
	const [itemDocLines, setItemDocLines] = useState(null)

	const roundToTwoDecimals = (num) => ((num * 100 + 0.5) | 0) / 100

	useEffect(() => {
		if (data?.docDate) {
			setDueDate(moment(data?.docDate).format('DD.MM.YYYY'))
			setDeadline(moment(data?.docDueDate).format('DD.MM.YYYY'))
		}
		if (data) {
			const docLines = data?.documentLines
				.filter((item) => {
					return item.u_Produkt === null
				})
				.map((item, index) => ({
					key: index,
					...item,
					itemDescription: item.itemDescription,
					quantity: item.quantity,
					price: item.price,
					lineTotal: item.lineTotal,
					whsName: item.whsName,
					u_Produkt: item.u_Produkt,
					currency: item.currency,
					openQty: item.openQty,
					width: item.u_Width,
					height: item.u_Height,
					koef: item.u_Coefficient,
					ugpCode: item.ugpCode,
					u_PlannedQuantity: item.u_PlannedQuantity,
					oneItemKvm:
						((item.u_Width * item.u_Height) / 1000000) *
						(item.u_Coefficient || 1),
					openQuantitySon: roundToTwoDecimals(
						item.openQty /
							(((item.u_Width * item.u_Height) / 1000000) *
								(item.u_Coefficient || 1)),
					),
					openQuantitySonFromData: roundToTwoDecimals(
						item.openQty /
							(((item.u_Width * item.u_Height) / 1000000) *
								(item.u_Coefficient || 1)),
					),
				}))
			setItemDocLines(docLines || [])
		}
	}, [data])

	const shipData = async () => {
		setShipBtnLoading(true)

		const documentLinesServices = data.documentLines
			?.filter((item) => item.u_Produkt)
			.map((item, idx) => {
				return {
					baseEntry: data.docEntry,
					baseLine: item.lineNum,
					itemCode: item.itemCode,
					quantity: item.openQty,
					unitPrice: item.price,
					currency: item.currency,
					warehouseCode: item.warehouseCode || '',
					u_Coefficient: item.u_Coefficient,
					u_Height: item.u_Height,
					u_Width: item.u_Width,
					u_Kvmetr: item.u_Kvmetr,
				}
			})

		const documentLinesItems = itemDocLines.map((item, idx) => {
			return {
				baseEntry: data.docEntry,
				baseLine: item.lineNum,
				itemCode: item.itemCode,
				quantity: roundToTwoDecimals(
					item.oneItemKvm * item.openQuantitySon,
				),
				unitPrice: item.price,
				currency: item.currency,
				warehouseCode: item.warehouseCode || '',
				u_Coefficient: item.u_Coefficient,
				u_Height: item.u_Height,
				u_Width: item.u_Width,
				u_Kvmetr: item.u_Kvmetr,
			}
		})

		const postingData = {
			cardCode: data?.cardCode,
			docDueDate: moment().format('YYYY-MM-DD'),
			docDate: moment().format('YYYY-MM-DD'),
			documentsOwner: employeeId,
			salesPersonCode: salesPersonCode,
			comments: data?.comments,
			docCurrency: data?.docCurrency,
			department,
			documentLines: [...documentLinesItems, ...documentLinesServices],
		}

		try {
			await http.post('api/sales/ortib-yuborilgan', postingData)
			onClose()
			refetch()
			successRef.current.open(t('sucCreated'))
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setShipBtnLoading(false)
		}
	}

	if (!data) return null

	const columns = [
		{
			title: t('item'),
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			width: 150,
			render: (text, record, index) => {
				return <span>{text}</span>
			},
		},
		{
			title: t('size'),
			dataIndex: 'width',
			key: 'size',
			render: (text, record, index) => {
				const width = new Intl.NumberFormat('fr-FR').format(text)
				const height = new Intl.NumberFormat('fr-FR').format(
					record.height,
				)
				return (
					<span>
						{width} X {height}
					</span>
				)
			},
		},
		{
			title: `${t('quantity')} `,
			dataIndex: 'u_PlannedQuantity',
			key: 'u_PlannedQuantity',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},
		{
			title: `${t('openQty')}  (${t('sht')})`,
			dataIndex: 'openQuantitySon',
			key: 'openQuantitySon',
			render: (text, record, index) => {
				return ship ? (
					<div>
						<Input
							value={text}
							onChange={(e) => {
								const val = e.target.value
								const newItemDocLines = [...itemDocLines]
								if (
									val >= 0 &&
									val <= record.openQuantitySonFromData
								) {
									newItemDocLines[index].openQuantitySon = val
									newItemDocLines[
										index
									].openQuantityErrorMessage = ''
									setItemDocLines(newItemDocLines)
								} else {
									newItemDocLines[
										index
									].openQuantityErrorMessage =
										'Invalid Open Quantity'
									setItemDocLines(newItemDocLines)
								}
							}}
							type="number"
							min={0}
							onWheel={(e) => {
								e.target.blur()
							}}
							max={record.openQuantitySonFromData}
						/>
						<span className="text-red-500 mt-2">
							{t(record?.openQuantityErrorMessage, {
								quantity: record.openQuantitySonFromData,
							})}
						</span>
					</div>
				) : (
					<span>{text}</span>
				)
			},
		},

		{
			title: t('kv.m'),
			dataIndex: 'quantity',
			key: 'quantity',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},
		{
			title: `${t('openQty')} (${t('kv.m')})`,
			dataIndex: 'openQty',
			key: 'openQty',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},

		{
			title: t('Koefitsent'),
			dataIndex: 'koef',
			key: 'koef',
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span>{formattedText}</span>
			},
		},

		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},

		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},
	]

	const columnsServices = [
		{
			title: t('service'),
			dataIndex: 'itemDescription',
			key: 'itemDescription',
			width: 150,
			render: (text, record) => {
				return (
					<span>
						<span className="font-bold">
							{record.u_Number ? `${record.u_Number} ` : ''}
						</span>
						{text}
					</span>
				)
			},
		},

		{
			title: t('quantity'),
			dataIndex: 'quantity',
			key: 'quantity',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return <span> {formattedText}</span>
			},
		},
		{
			title: t('unitMsr'),
			dataIndex: 'ugpCode',
			key: 'ugpCode',
			render: (text, record, index) => {
				return <span>{text}</span>
			},
		},

		{
			title: t('price'),
			dataIndex: 'price',
			key: 'price',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},

		{
			title: t('totalMiqdor'),
			dataIndex: 'lineTotal',
			key: 'lineTotal',
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat('fr-FR').format(
					text,
				)
				return (
					<span>
						{formattedText} {record.currency}
					</span>
				)
			},
		},
		{
			title: t('u_Produkt'),
			dataIndex: 'u_Produkt',
			key: 'u_Produkt',
		},
	]

	const tableDataServices = data?.documentLines
		.filter((item) => {
			return item.u_Produkt !== null
		})
		.map((item, index) => ({
			key: index,
			itemDescription: item.itemDescription,
			quantity: item.quantity,
			price: item.price,
			lineTotal: item.lineTotal,
			whsName: item.whsName,
			u_Produkt: item.u_Produkt,
			currency: item.currency,
			openQty: item.openQty,
			ugpCode: item.ugpCode,
			u_PlannedQuantity: item.u_PlannedQuantity,
			u_Number: item.u_Number,
		}))

	return (
		<div className="w-full px-4 ">
			<div className="w-full">
				<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('mijoz')}</p>
						<Input
							type="text"
							value={data.cardName}
							className="flex w-[200px] items-center justify-center sm:w-[250px]"
							style={{ color: 'black' }}
							disabled
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-0">
						<p className="font-bold">{t('status')}</p>
						<Input
							value={data?.u_AbsoluteStatus}
							style={{ color: 'black' }}
							disabled
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-0">
						<p className="font-bold">{t('currency')}</p>
						<Input
							value={data.docCurrency}
							style={{ color: 'black' }}
							disabled
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-0">
						<p className="font-bold">{t('date')}</p>
						<Input
							type="text"
							value={dueDate}
							style={{ color: 'black' }}
							disabled
						/>
					</div>
					<div className="mt-4 flex flex-col gap-3 sm:mt-0">
						<p className="font-bold">{t('deadLine2')}</p>
						<Input
							type="text"
							value={deadLine}
							style={{ color: 'black' }}
							disabled
						/>
					</div>
				</div>

				<div className="mt-12">
					<p className="font-bold text-center">{t('items')}</p>
					<Table
						columns={columns}
						dataSource={itemDocLines}
						pagination={false}
						className="border-1 mt-5  border-black"
						scroll={{ x: '100%' }}
						bordered
					/>
				</div>

				<div className="mt-12">
					<p className="font-bold text-center">{t('services')}</p>
					<Table
						columns={columnsServices}
						dataSource={tableDataServices}
						pagination={false}
						className="border-1 mt-5 border-black"
						scroll={{ x: '100%' }}
						bordered
					/>
				</div>

				<div className="w-full flex items-center justify-between mt-5  ">
					<div className="flex items-center gap-3 ">
						<p className="font-bold">{t('totalForDocument')}:</p>
						<Input
							type="text"
							value={`${new Intl.NumberFormat('fr-FR').format(data.docTotal)} ${data.docCurrency}`}
							className="flex h-8 w-[70px] items-center justify-center sm:w-[250px]"
							style={{ color: 'black' }}
							disabled
						/>
					</div>

					<div>
						{incoming ? (
							<Button
								onClick={() => {
									setIsModalVisible(true)
								}}
								className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
							>
								{t('pay')}
							</Button>
						) : (
							''
						)}
						{ship ? (
							<Button
								onClick={shipData}
								loading={shipBtnLoading}
								className="h-[30px] w-[140px]  bg-[#0A4D68] rounded-lg text-white "
							>
								{t('ship')}
							</Button>
						) : (
							''
						)}
					</div>
				</div>
			</div>

			<OrderPaymentModal
				visible={isModalVisible}
				data={data}
				onClose={() => setIsModalVisible(false)}
			/>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</div>
	)
}

export default SalesInfo
