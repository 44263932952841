import * as React from 'react'
const MoneyIcon = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<rect
			x={2}
			y={4}
			width={20}
			height={16}
			rx={4}
			stroke="white"
			strokeWidth={1.5}
		/>
		<path
			d="M14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12"
			stroke="white"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
		<path
			d="M12 12C13.1046 12 14 12.8954 14 14C14 15.1046 13.1046 16 12 16C10.8954 16 10 15.1046 10 14"
			stroke="white"
			strokeWidth={1.5}
			strokeLinecap="round"
		/>
		<path
			d="M12 6.5V8"
			stroke="white"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 16V17.5"
			stroke="white"
			strokeWidth={1.5}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)
export default MoneyIcon
