import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnCheckDebtors } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewSalesModal from '../../../components/Modal/ViewSalesWithDocEntryModal'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../../utils/debounce'
import { Input, Table, Button } from 'antd'
import { http } from '../../../services/http'
import { Search } from 'lucide-react'

const DebtorsCheck = () => {
	const { t } = useTranslation()
	const formattedDate = getToday()

	const fetchSeeSales = async ({ queryKey }) => {
		const [_, docNum] = queryKey
		if (docNum === '') return
		try {
			let url = ''

			url = `api/businesspartners/get-bp-documents?docNum=${docNum}`
			const { data } = await http.get(url)

			const formattedData = data
				.filter((item) => String(item.docNum) === String(docNum))
				.map((data, index) => {
					return {
						...data,
						key: index,
					}
				})
			return formattedData
		} catch (error) {
			console.log(error)
		}
	}

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [searchDocNum, setSearchDocNum] = useState('')

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['checkDebtors', docNum],
		queryFn: fetchSeeSales,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const columns = columnCheckDebtors(t, handleRowClick)

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<div className="flex w-fullitems-center justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
					<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">
						{t('checkDebtors')}
					</h1>
				</div>

				<div className="mt-8 w-full border-[1px] border-[#E8E8E8] sm:mt-8"></div>

				<div className="mt-6 sm:ml-10 sm:mt-10">
					<div className="mb-4 flex flex-col justify-between sm:flex-row">
						<div className="mt-2 flex sm:mr-10">
							<Input
								value={searchDocNum}
								onChange={(e) => {
									setSearchDocNum(e.target.value)
									if (e.target.value === '') {
										setDocNum('')
									}
								}}
								className="w-[250px] h-8"
								placeholder={t('docNum')}
							/>
							<Button
								type="primary"
								className="h-8 rounded-none bg-basic"
								onClick={() => setDocNum(searchDocNum.trim())}
								icon={<Search color="white" />}
							/>
						</div>
					</div>

					<div className="mr-10 mt-10 mb-10">
						<Table
							columns={columns}
							dataSource={docNum ? data : []}
							pagination={false}
							bordered
							className="w-full"
							rowKey="key"
							// rowClassName="text-center"
							loading={isLoading}
						/>
					</div>
				</div>
			</div>
			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				ship={false}
				incoming={false}
			/>
		</Layout>
	)
}

export default DebtorsCheck
