import React, { memo, useEffect, useState } from 'react'
import { Modal, Button } from 'antd'
import ErrorImage from '../../../assets/images/error.png'
import ErrorModalStyle from './ErrorModalStyle'
import ErrorSound from '../../../assets/music/error1.mp3'
import { useTranslation } from 'react-i18next'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

const ErrorModal = ({ getRef = () => {}, onConfirm = () => {} }) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [errorTitle, setErrorTitle] = useState('')
	const { t } = useTranslation()

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				setErrorTitle(t)
				let audio = new Audio(ErrorSound)
				audio.play()
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [getRef])

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Enter' && isOpenModal) {
				onConfirm()
				setIsOpenModal(false)
			}
		}

		document.addEventListener('keydown', handleKeyPress)
		return () => document.removeEventListener('keydown', handleKeyPress)
	}, [isOpenModal, onConfirm])

	return (
		<Modal
			open={isOpenModal}
			onCancel={() => setIsOpenModal(false)}
			footer={null}
			centered
			closeIcon={<span style={{ fontSize: '1.5rem' }}>×</span>}
		>
			<ErrorModalStyle>
				<div className="card">
					<div className="flex justify-center">
						<img
							src={ErrorImage}
							alt="error image"
							className="img"
						/>

						{/* <DotLottieReact
							src="https://lottie.host/9eaccaaf-ab5e-4dda-b493-3d0f221bb804/Hn7S3nEkmK.lottie"
							loop
							autoplay
						/> */}
					</div>
					<h2 className="title">{errorTitle}</h2>
					<div className="centerCard">
						<Button
							type="primary"
							danger
							className="bg-red-600 w-[100px] text-sm h-12 text-white"
							onClick={() => {
								onConfirm()
								setIsOpenModal(false)
							}}
						>
							{t('understand')}
						</Button>
					</div>
				</div>
			</ErrorModalStyle>
		</Modal>
	)
}

export default memo(ErrorModal)
