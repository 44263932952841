import {
	calculateDurationString,
	getToday,
	formatTimeWithHours,
	isAfter,
	isSameDay,
	isBefore,
} from './time'

const formattedDate = getToday()

export const switchBgColor = (record) => {
	if (record.isReadyForNextStage !== 'Yes') {
		return 'bg-gray-500 hover:bg-gray-400 transition-colors duration-200'
	}
	if (record.isStarted === 'Yes' && record.isReady === 'Yes') {
		return 'bg-green-400 hover:bg-green-300 transition-colors duration-200'
	}
	if (record.isStarted === 'Yes' && record.isReady === 'No') {
		return 'bg-blue-400 hover:bg-blue-300 transition-colors duration-200'
	}
	if (
		record.isStarted === 'No' &&
		record.isReady === 'No' &&
		isSameDay(record.dueDate, formattedDate)
	) {
		return 'bg-black text-white hover:bg-gray-700 transition-colors duration-200'
	}
	if (
		record.isStarted === 'No' &&
		record.isReady === 'No' &&
		isBefore(record.dueDate, formattedDate)
	) {
		return 'bg-red-600 hover:bg-red-400 transition-colors duration-200'
	}
	if (
		record.isStarted === 'No' &&
		record.isReady === 'No' &&
		isAfter(record.dueDate, formattedDate)
	) {
		return 'bg-yellow-400 hover:bg-yellow-300 transition-colors duration-200'
	}
	return 'bg-white hover:bg-gray-300 transition-colors duration-200'
}

export const meaningOfBg = (t) => {
	return [
		{ id: 1, className: 'bg-green-400', meaning: t('done') },
		{ id: 2, className: 'bg-blue-400', meaning: t('inProcess2') },
		{ id: 3, className: 'bg-yellow-400', meaning: t('notStarted') },
		{ id: 4, className: 'bg-red-600', meaning: t('expired') },
		{ id: 5, className: 'bg-black', meaning: t('hasToDoToday') },
		{ id: 6, className: 'bg-gray-400', meaning: t('notReadyForNextStage') },
	]
}

export const ignoredKeys = [
	'Backspace',
	'Tab',
	'Escape',
	'Delete',
	'ArrowLeft',
	'ArrowRight',
	'ArrowUp',
	'ArrowDown',
	'F1',
	'F2',
	'F3',
	'F4',
	'F5',
	'F6',
	'F7',
	'F8',
	'F9',
	'F10',
	'F11',
	'F12',
	'Pause',
	'ScrollLock',
]
