import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnMySales } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import ViewSalesModal from '../../../components/Modal/ViewSalesModal/index'
import { useSelector } from 'react-redux'
import { Table, Input, Button, Select } from 'antd'
import { useQuery } from '@tanstack/react-query'
import { fetchSalesPerson } from '../../../utils/fetchData'
import { debouncedSet } from '../../../utils/debounce'
import { numberWithSpacesIntl } from '../../../utils/numberWithSpaces'
import {
	handleNavigate,
	handleNavigateCar,
	handleNavigateSeries,
} from '../../../utils/navigateToPdf'
import { http } from '../../../services/http'

const MySales = () => {
	const { t } = useTranslation()
	const { user, getMe, department, roleNames } = useSelector(
		(state) => state.main,
	)

	const isAdmin = roleNames.includes('admin')
	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [cardName, setCardName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)
	const [expandedRowKeys, setExpandedRowKeys] = useState([])
	const [forceRender, setForceRender] = useState(0)
	const [slpAdmin, setSlpAdmin] = useState('')

	const fetchSalesCountSlp = async ({ queryKey }) => {
		const [_, slpCode, date] = queryKey
		try {
			const { data } = await http.get(
				`api/sales/sales-count-by-sales-person?slpCode=${slpCode}&date=${date}&pageSize=1000&skip=0`,
			)
			const formattedData = data.map((item, index) => {
				return {
					...item,
					key: index.toString(),
				}
			})
			return formattedData
		} catch (error) {
			console.log(error)
		}
	}

	// getMe?.salesPersonCode, endDate

	const { data: salesCountData = [], isLoading: salesCountLoading } =
		useQuery({
			queryKey: [
				'salesCountSlp',
				isAdmin ? slpAdmin : getMe?.salesPersonCode,
				'',
			],
			queryFn: fetchSalesCountSlp,
			keepPreviousData: true,
			refetchOnWindowFocus: false,
		})

	const { data: slpData = [], isLoading: slpLoading } = useQuery({
		queryKey: ['salesPerson'],
		queryFn: fetchSalesPerson,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const columnsSalesCount = [
		{
			title: t('docDate'),
			dataIndex: 'docDate',
			key: 'docDate',
		},
		{
			title: t('salesCount'),
			dataIndex: 'totalCount',
			key: 'totalCount',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},
		{
			title: t('salesTotal'),
			dataIndex: 'totalDocTotal',
			key: 'totalDocTotal',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},
		{
			title: t('paidTotal'),
			dataIndex: 'paidTotal',
			key: 'paidTotal',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},
		{
			title: t('debtTotalSum'),
			dataIndex: 'debtTotal',
			key: 'debtTotal',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},
		{
			title: t('salesManager'),
			dataIndex: 'slpCode',
			key: 'slpCode',
			render: (text, record) => {
				return (
					<span>
						{slpData.find((item) => item.slpCode === text)
							?.slpName || ''}
					</span>
				)
			},
		},
	]

	const columnsSalesCountSub = [
		{
			title: t('cardName'),
			dataIndex: 'cardName',
			key: 'cardName',
		},
		{
			title: t('docTotal'),
			dataIndex: 'docTotal',
			key: 'docTotal',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},

		{
			title: t('paidTotal'),
			dataIndex: 'paidToDate',
			key: 'paidToDate',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},
		{
			title: t('debt'),
			dataIndex: 'debt',
			key: 'debt',
			render: (text, record) => {
				return <span>{`${numberWithSpacesIntl(text)} `}</span>
			},
		},
	]

	const onExpand = (expanded, record) => {
		setExpandedRowKeys(expanded ? [record.key] : [])
		setForceRender((prev) => prev + 1)
		console.log('expanded', expandedRowKeys, record)
	}

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<div className="flex  justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
					<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">
						{isAdmin ? t('salesBySlp') : t('MySales')}
					</h1>
					<div className="flex items-center gap-5 flex-col sm:flex-row"></div>
				</div>

				<div className="mt-8 w-full border-[1px] border-[#E8E8E8] sm:mt-8"></div>

				<div className="mt-6 sm:ml-10 sm:mt-10">
					<div className=" mt-5 flex gap-5 flex-col sm:flex-row">
						<div className="flex flex-col gap-3">
							<p>{t('startDate')}</p>
							<Input
								type="date"
								value={startDate}
								className="w-[150px] h-8"
								onChange={handleStartDate}
								max={endDate}
								allowClear
							/>
						</div>
						<div className="flex flex-col gap-3">
							<p>{t('endDate')}</p>
							<Input
								type="date"
								value={endDate}
								className="w-[150px] h-8"
								onChange={handleEndDate}
								min={startDate}
								max={formattedDate}
								allowClear
							/>
						</div>
						{isAdmin && (
							<div className="flex flex-col gap-3">
								<p>{t('salesManager')}</p>
								<Select
									value={slpAdmin}
									onChange={setSlpAdmin}
									className="w-[150px] h-8"
									options={[
										{
											value: '',
											label: t('all'),
										},
										...slpData.map((item) => ({
											value: item.slpCode,
											label: item.slpName,
										})),
									]}
								/>
							</div>
						)}
					</div>
					<div className="mr-10 mt-10 mb-10">
						<Table
							columns={columnsSalesCount}
							dataSource={salesCountData}
							key={forceRender}
							pagination={false}
							loading={salesCountLoading}
							expandedRowKeys={expandedRowKeys}
							onExpand={onExpand}
							bordered
							expandedRowRender={(record) => {
								return (
									<Table
										columns={columnsSalesCountSub}
										dataSource={record.documentLines || []}
										pagination={false}
										bordered
										rowKey={(line) =>
											line.docEntry || line.docNum
										}
									/>
								)
							}}
						/>
					</div>
				</div>
			</div>

			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>
		</Layout>
	)
}

export default MySales
