import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnSalesOrderNotAdmin } from '../../../utils/columns'
import { getToday } from '../../../utils/time'
import Layout from '../../../components/Layout'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ViewSalesOrderModal from '../../../components/Modal/ViewSalesModal'
import { useQuery } from '@tanstack/react-query'
import { fetchSalesOrder } from '../../../utils/fetchData'
import { useDispatch } from 'react-redux'
import { debouncedSet } from '../../../utils/debounce'
import CancelSaleModal from '../../../components/Modal/cancelSaleModal'
import OrderPaymentModal from '../../../components/Modal/orderPaymentModal'

import {
	handleNavigate,
	handleNavigateCar,
	handleNavigateSeries,
} from '../../../utils/navigateToPdf'
const SalesOrder = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { department, roleNames } = useSelector((state) => state.main)
	const progress = useSelector((state) => state?.post?.progress) || 0

	const formattedDate = getToday()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false)

	const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [cardName, setCardName] = useState('')
	const [slpName, setSlpName] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(formattedDate)
	const [cancelStatus, setCancelStatus] = useState('-')

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'salesOrder',
			currentPage,
			cardName,
			docNum,
			startDate,
			endDate,
			slpName,
			cancelStatus,
			roleNames.includes('Supplier') || roleNames.includes('admin')
				? ''
				: department,
		],
		queryFn: fetchSalesOrder,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleNextPage = () => {
		if (data.length === 10) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleCancelClick = (record) => {
		setSelectedRowData(record)
		setIsCancelModalVisible(true)
	}

	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleCardNameChange = (val) => {
		debouncedSet(val, setCardName)
	}
	const handleSlpNameChange = (val) => {
		debouncedSet(val, setSlpName)
	}

	const handleOpenPaymentModal = (record) => {
		setSelectedRowData(record)
		setIsPaymentModalVisible(true)
	}

	const columns = columnSalesOrderNotAdmin(
		t,
		handleRowClick,
		handleDocNumChange,
		handleCardNameChange,
		handleSlpNameChange,
		handleNavigate,
		handleNavigateCar,
		handleCancelClick,
		handleNavigateSeries,
		roleNames.includes('admin') || roleNames.includes('Sales Manager')
			? true
			: false,
		roleNames.includes('Cashier') || roleNames.includes('admin')
			? true
			: false,
		handleOpenPaymentModal,
	)

	const handleAdd = () => {
		navigate('add')
	}

	return (
		<Layout>
			<Header
				title={
					roleNames.includes('admin') ? t('orders') : t('SalesOrder')
				}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={data.length === 10}
				columns={columns}
				fdata={data}
				loading={isLoading}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				isSticky={false}
				formattedDate={formattedDate}
				{...(roleNames.includes('Sales Manager') ||
				roleNames.includes('admin')
					? { onBtnClick: handleAdd }
					: {})}
			/>
			<ViewSalesOrderModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				ship={true}
				incoming={true}
				refetch={refetch}
			/>
			<CancelSaleModal
				visible={isCancelModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsCancelModalVisible(false)
					refetch()
				}}
			/>
			<OrderPaymentModal
				visible={isPaymentModalVisible}
				data={selectedRowData}
				onClose={() => setIsPaymentModalVisible(false)}
			/>
		</Layout>
	)
}

export default SalesOrder
