import React, { useState, useEffect } from 'react'
import { Modal, Empty, Table, Tag } from 'antd'
import { formatTimeWithHours } from '../../../utils/time'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export default function ProductionDetailed({
	visible,
	data = {},
	onClose,
	service,
}) {
	const [timer, setTimer] = useState({})
	const { t } = useTranslation()

	useEffect(() => {
		if (data) {
			console.log('data', data)
			const newTimer = {}
			let remainingTime = null
			const currentTime = moment()
			data?.documentLines?.forEach((item) => {
				const startTime = moment(item.startTime, 'HH:mm:ss')
				const elapsedSeconds = currentTime.diff(startTime, 'seconds')
				remainingTime = Number(item.u_Timer) * 60 - elapsedSeconds

				newTimer[`${item.serialNumber}-${service}`] = {
					startTime: item.startTime,
					timer: remainingTime,
					u_Timer: item.u_Timer,
					isActive: item.isStart === 'Yes' && item.ready === 'No',
					status:
						item.isStart === 'Yes' && item.ready === 'No'
							? 'inProcess3'
							: item.ready === 'Yes'
								? 'completed'
								: 'notStarted',

					isStart: item.isStart,
					ready: item.ready,
					wastedTime: item.wastedTime,

					serialNumber: item.serialNumber,
				}
			})
			setTimer(newTimer)
			console.log('newTimer', newTimer)
		}
	}, [data, visible])

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer((prevTimers) => {
				const updatedTimers = {}
				Object.keys(prevTimers).forEach((key) => {
					updatedTimers[key] = {
						...prevTimers[key],
						timer: prevTimers[key].isActive
							? prevTimers[key].timer - 1
							: prevTimers[key].timer,
					}
				})
				return updatedTimers
			})
		}, 1000)

		return () => clearInterval(interval)
	}, [timer])

	const columns = [
		{
			title: t('serialNumber'),
			dataIndex: 'serialNumber',
			key: 'serialNumber',
		},
		{
			title: t('startTime'),
			dataIndex: 'startTime',
			key: 'startTime',
			render: (text, record) => {
				return (
					<span>
						{text
							? moment(text, 'HH:mm:ss').format('HH:mm:ss')
							: ''}
					</span>
				)
			},
		},
		{
			title: t('duration'),
			dataIndex: 'timer',
			key: 'timer',
			render: (text, record) => {
				return (
					<span>
						{record.status === 'inProcess3' ? (
							<Tag color="blue">{formatTimeWithHours(text)}</Tag>
						) : record.status === 'completed' ? (
							<Tag color="green">{record.wastedTime}</Tag>
						) : (
							<Tag color="red">
								{formatTimeWithHours(record.u_Timer * 60)}
							</Tag>
						)}
					</span>
				)
			},
		},
		{
			title: t('u_Timer'),
			dataIndex: 'u_Timer',
			key: 'u_Timer',
			render: (text, record) => {
				return text && `${text} ${t('minutes')}`
			},
		},
		{
			title: t('status'),
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => {
				return text && text === 'inProcess3' ? (
					<Tag color="blue">{t(text)}</Tag>
				) : text === 'completed' ? (
					<Tag color="green">{t(text)}</Tag>
				) : (
					<Tag color="red">{t(text)}</Tag>
				)
			},
		},
	]

	return (
		<Modal open={visible} onCancel={onClose} footer={null} width="90%">
			<p className="text-center m-5 text-xl font-bold">
				{t('service')} : {data?.serviceDescription}
			</p>
			<Table
				dataSource={Object.values(timer).sort(
					(a, b) => b.isActive - a.isActive,
				)}
				columns={columns}
				pagination={false}
			/>
		</Modal>
	)
}
