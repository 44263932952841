import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	testState: false,
	activeTimersWorker: {},
}

const productionSlice = createSlice({
	name: 'production',
	initialState,
	reducers: {
		setTestState: (state, action) => {
			state.testState = action.payload
		},
		setActiveTimersWorker: (state, action) => {
			state.activeTimersWorker = action.payload
		},
	},
})

export const { setTestState, setActiveTimersWorker } = productionSlice.actions
export default productionSlice.reducer
