export const formatNumberWithSpaces = (value, decimalPlaces = 2) => {
	if (!value) return ''
	const stringValue = typeof value === 'string' ? value : String(value)
	const numericValue = stringValue.replace(/[^\d.,]/g, '')

	return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function removeSpaces(value) {
	return String(value)
		.replace(/[^0-9.,]/g, '')
		.replace(/\s+/g, '')
}

export function numberWithSpacesIntl(number) {
	if (number === '' || number === undefined) return ''

	let strInput = String(number).replaceAll(',', '.')

	const parsingNumber = parseFloat(
		strInput.replace(/\s/g, '').replace(',', '.'),
	)
	if (isNaN(parsingNumber)) {
		return number
	}

	return new Intl.NumberFormat('fr-FR').format(parsingNumber)
}

export const formatInputNumber = (value) => {
	if (!value) return ''
	const stringValue = typeof value === 'string' ? value : String(value)
	const numericValue = stringValue.replace(/[^\d.,]/g, '')

	return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const roundToTwoDecimals = (num) => ((num * 100 + 0.5) | 0) / 100
