import React, { useMemo, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
} from '@ant-design/icons'
import {
	setCollapse,
	setSelectedPath,
	setOpenMiniMenu,
} from '../../slice/mainSlice'
import logo from '../../assets/images/BIS logo.svg'
import '../../assets/style/menu.css'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SalesIcon from '../../assets/icons/SalesSvg'
import PaymentIcon from '../../assets/icons/PaymentSvg'
import ClientIcon from '../../assets/icons/ClientSvg'
import SalesStaticsIcon from '../../assets/icons/SalesStatics'
import ReportsIcon from '../../assets/icons/ReportsSvg'
import ItemsIcon from '../../assets/icons/itemsSvg'
import { get } from 'lodash'
import SalesIcon2 from '../../assets/icons/SalesSvg2'
import ProductionIcon from '../../assets/icons/ProductionSvg'
import AccountsIcon from '../../assets/icons/AccountsSvg'
import PricingIcon from '../../assets/icons/PricingSvg'
import HRIcon from '../../assets/icons/HRSvg.jsx'
import DeliveryIcon from '../../assets/icons/DeliverySvg.jsx'
import { useQuery } from '@tanstack/react-query'
import {
	Lightbulb,
	FileCheck,
	Upload,
	LayoutDashboard,
	FileText,
} from 'lucide-react'
import { http } from '../../services/http'

export const Menubar = () => {
	const { t } = useTranslation()
	const { collapse, selectedPath, openMiniMenu, getMe } = useSelector(
		(state) => state.main,
	)
	const menuRef = useRef(null)

	const role = get(getMe, 'roleLines', [])
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { data: subMenus = [], isLoading: subMenusLoading } = useQuery({
		queryKey: ['subMenus'],
		queryFn: async () => {
			try {
				const { data } = await http.get(`api/accounts/menu`)
				return data
			} catch (err) {
				console.log('Error fetching subMenus:', err)
				return []
			}
		},
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const pages = {
		Wrhmanager: [
			{
				key: 'purchasement',
				label: t('purchasement'),
				icon: <SalesIcon />,
				children: [
					{ key: 'purchase-order', label: t('purchase-order') },
					{ key: 'purchase-incoming', label: t('purchase-incoming') },
				],
			},
			{
				key: 'sales',
				label: t('sales'),
				icon: <SalesIcon2 />,
				children: [
					{ key: 'sales-order', label: t('SalesOrder') },
					{ key: 'shipped-sales', label: t('Shipped') },
				],
			},
			{
				key: 'stock-transfers',
				label: t('stockTransfers'),
				icon: <DeliveryIcon />,
			},
		],
		'Sales Manager': [
			{
				key: 'saleStatics',
				label: t('saleStatics'),
				icon: <SalesStaticsIcon />,
				children: [
					{ key: 'my-sales', label: t('MySales') },
					{ key: 'debtors', label: t('Accounts receivable') },
				],
			},
			{
				key: 'sales',
				label: t('sales'),
				icon: <SalesIcon2 />,
				children: [
					{ key: 'sales-order', label: t('SalesOrder') },
					{ key: 'shipped-sales', label: t('Shipped') },
					{ key: 'finished-sales', label: t('finishedSales') },
					{ key: 'ignored-sales-orders', label: t('ignoredSales') },
				],
			},
			{ key: 'clients', label: t('clients'), icon: <ClientIcon /> },
		],
		Supplier: [
			{
				key: 'purchasement',
				label: t('purchasement'),
				icon: <SalesIcon />,
				children: [
					{ key: 'purchase-order', label: t('purchase-order') },
					{ key: 'purchase-incoming', label: t('purchase-incoming') },
					{ key: 'purchase-invoices', label: t('purchase-invoices') },
				],
			},
			{
				key: 'outgoing-payments',
				label: t('outgoingPayment'),
				icon: <PaymentIcon />,
			},
			{
				key: 'reports',
				label: t('reports'),
				icon: <ReportsIcon />,
				children: [
					{ key: 'reports', label: t('reportsWhs') },
					{ key: 'send-money', label: t('sendMoney') },
				],
			},
			{ key: 'itemsAdmin', label: t('items'), icon: <ItemsIcon /> },
			{
				key: 'suppliers',
				label: t('suppliers'),
				icon: (
					<UserOutlined
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
			},
		],
		Cashier: [
			{
				key: 'sales',
				label: t('sales'),
				icon: <SalesIcon2 />,
				children: [
					{ key: 'sales-order', label: t('SalesOrder') },
					{ key: 'sales', label: t('sales') },
					{ key: 'expected-payments', label: t('expectedPayments') },
				],
			},
			{
				key: 'payments',
				label: t('payments'),
				icon: <PaymentIcon />,
				children: [
					{ key: 'outgoing-payments', label: t('outgoingPayment') },
					{ key: 'incoming-payments', label: t('incomingPayment') },
				],
			},
			{
				key: 'cash',
				label: t('kassa'),
				icon: <PaymentIcon />,
				children: [
					{ key: 'balance', label: t('balance') },
					{ key: 'send-money', label: t('sendMoney') },
				],
			},
		],
		ProdManager: [
			{
				key: 'dashboard-workers',
				label: t('dashboardWorkers'),
				icon: (
					<LayoutDashboard
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
			},
			{
				key: 'production-orders',
				label: t('productionOrders'),
				icon: <ProductionIcon />,
			},
		],
		Worker: [
			{
				key: 'production-workers',
				label: t('production'),
				icon: <ProductionIcon />,
			},
		],
		Accountant: [
			{
				key: 'dashboardAccountant',
				icon: (
					<LayoutDashboard
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
				label: t('dashboard'),
			},
			{
				key: 'bankStatements',
				icon: <FileText style={{ color: 'white', fontSize: '25px' }} />,
				label: t('bankStatements'),
				children: subMenus.map((item) => {
					const isConversion = item.u_IsConvertationAccount === 'Yes'
					return {
						key: `${isConversion ? `conversionAccountant/${item.acctCode}` : `bankStatements/${item.acctCode}`}`,
						label: (
							<div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
						),
					}
				}),
			},
			{
				key: 'loadedPayments',
				icon: <Upload style={{ color: 'white', fontSize: '25px' }} />,
				label: t('loaded-payments'),
				children: subMenus.map((item) => ({
					key: `loadedPayments/${item.acctCode}`,
					label: (
						<div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
					),
				})),
			},
			{
				key: 'act-sverka',
				icon: (
					<FileCheck style={{ color: 'white', fontSize: '25px' }} />
				),
				label: t('act-sverka'),
				children: subMenus.map((item) => ({
					key: `act-sverka/${item.acctCode}`,
					label: (
						<div className="text-wrap text-sm">{`${item.acctCode} - ${item.acctName}`}</div>
					),
				})),
			},
			{
				key: 'recomendations',
				icon: (
					<Lightbulb style={{ color: 'white', fontSize: '25px' }} />
				),
				label: t('recommendation'),
			},
		],
		admin: [
			{
				key: 'whs',
				label: t('whs'),
				icon: <ReportsIcon />,
				children: [
					{ key: 'reports', label: t('warehouses') },
					{ key: 'itemsAdmin', label: t('items') },
					{ key: 'itemsServices', label: t('services') },
					{ key: 'itemsShapes', label: t('shapes') },
					{ key: 'package-services', label: t('packageServices') },
					{ key: 'stock-transfers', label: t('stockTransfers') },
					{ key: 'purchase-order', label: t('purchase-order') },
					{ key: 'purchase-incoming', label: t('purchase-incoming') },
					{ key: 'purchase-invoices', label: t('purchase-invoices') },
				],
			},
			{
				key: 'sales',
				label: t('salesDepartment'),
				icon: <SalesIcon2 />,
				children: [
					{ key: 'my-sales', label: t('salesBySlp') },
					{ key: 'sales-order', label: t('orders') },
					{
						key: 'sales-order-request',
						label: t('salesOrderRequest'),
					},
					{ key: 'ignored-sales-orders', label: t('ignoredSales') },
					{ key: 'clients', label: t('clients') },
					{ key: 'branches', label: t('branches') },
					{ key: 'itemsServices', label: t('services') },
					{ key: 'package-services', label: t('packageServices') },
				],
			},
			{
				key: 'production',
				label: t('production'),
				icon: <ProductionIcon />,
				children: [
					{ key: 'dashboard-workers', label: t('dashboardWorkers') },
					{ key: 'production-orders', label: t('productionOrders') },
					{
						key: 'prod-workers-admin',
						label: t('productionWorkersAdmin'),
					},
				],
			},
			{
				key: 'accounting',
				label: t('accounting'),
				icon: <AccountsIcon />,
				children: [
					{ key: 'expected-payments', label: t('expectedPayments') },
					{ key: 'incoming-payments', label: t('incomingAdmin') },
					{ key: 'cashs', label: t('cashs') },
					{ key: 'outgoing-payments', label: t('expenses') },
					{ key: 'debtors', label: t('Accounts receivable') },
				],
			},
			{
				key: 'HR',
				label: t('HR'),
				icon: <HRIcon />,
				children: [{ key: 'employees', label: t('employees') }],
			},
			{
				key: 'pricing',
				label: t('pricing'),
				icon: <PricingIcon />,
				children: [
					{ key: 'itemsTable', label: t('itemsTable') },
					{ key: 'itemsShapesTable', label: t('itemsShapesTable') },
					{ key: 'servicesTable', label: t('servicesTable') },
				],
			},
			{
				key: 'debtorsMenu',
				label: t('debtors'),
				icon: (
					<UserOutlined
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
				children: [
					{ key: 'debtors', label: t('Accounts receivable') },
					{ key: 'sales-person-debtors', label: t('slpDebtors') },
				],
			},
			{
				key: 'check-debtors',
				label: t('checkDebtors'),
				icon: (
					<UserOutlined
						style={{ fontSize: '25px', color: 'white' }}
					/>
				),
			},
		],
	}

	const toggleCollapsed = () => {
		dispatch(setCollapse(!collapse))
	}

	const onSelectMenuItem = ({ key }, domEvent) => {
		// Only handle normal clicks here
		if (!(domEvent.button === 1 || domEvent.ctrlKey || domEvent.metaKey)) {
			dispatch(setSelectedPath(key))
			navigate(`/${key}`)
		}
	}

	const uniquePages = useMemo(() => {
		const combinedPages = Array.isArray(role)
			? role.flatMap(({ roleName }) => pages[roleName] || [])
			: []

		const pageMap = new Map()

		combinedPages.forEach((page) => {
			if (!pageMap.has(page.key)) {
				pageMap.set(page.key, { ...page })
			} else {
				const existingPage = pageMap.get(page.key)
				if (page.children && existingPage.children) {
					const combinedChildren = [
						...existingPage.children,
						...page.children.filter(
							(newChild) =>
								!existingPage.children.some(
									(existingChild) =>
										existingChild.key === newChild.key,
								),
						),
					]
					pageMap.set(page.key, {
						...existingPage,
						children: combinedChildren,
					})
				} else if (page.children && !existingPage.children) {
					pageMap.set(page.key, {
						...existingPage,
						children: page.children,
					})
				}
			}
		})

		return Array.from(pageMap.values())
	}, [role])

	const itemsWithDataAttr = useMemo(() => {
		const processItems = (items) => {
			return items.map((item) => {
				const processed = { ...item }

				console.log('Processing item:', processed)
				// Add data attribute to label if it's a string
				if (
					typeof processed.label === 'string' &&
					!processed?.children
				) {
					processed.label = (
						<a
							href={`/${processed.key}`}
							className="text-white"
							onClick={(e) => {
								// Only handle left clicks
								if (
									e.button !== 1 &&
									!e.ctrlKey &&
									!e.metaKey
								) {
									e.preventDefault()
									dispatch(setSelectedPath(processed.key))
									navigate(`/${processed.key}`)
								}
								// Let middle clicks and Ctrl+clicks use default browser behavior
							}}
						>
							{processed.label}
						</a>
					)
				}

				// Process children recursively
				if (processed.children) {
					processed.children = processItems(processed.children)
				}

				return processed
			})
		}

		return processItems(uniquePages)
	}, [uniquePages])

	return (
		<div className="flex m-0 h-screen">
			<div
				className={`${collapse ? 'w-[80px]' : 'w-[295px]'} bg-[#0A4D68] h-screen menu-container`}
			>
				<div className="flex flex-col h-full">
					<div className="flex justify-between px-5 mt-6">
						{!collapse && (
							<img
								src={logo}
								alt="BIS Logo"
								className="w-[150px]"
							/>
						)}
						<button
							onClick={toggleCollapsed}
							className="bg-[#0A4D68] text-white text-2xl"
						>
							{collapse ? (
								<MenuUnfoldOutlined className="ml-2" />
							) : (
								<MenuFoldOutlined />
							)}
						</button>
					</div>
					<div className="flex-1 overflow-y-auto mt-14" ref={menuRef}>
						<Menu
							defaultOpenKeys={openMiniMenu}
							selectedKeys={[selectedPath]}
							mode="inline"
							inlineCollapsed={collapse}
							items={itemsWithDataAttr}
							className="bg-transparent"
							theme="light"
							onSelect={({ key, domEvent }) =>
								onSelectMenuItem({ key }, domEvent)
							}
							onOpenChange={(v) => dispatch(setOpenMiniMenu(v))}
						/>
					</div>
					{role.some(({ roleName }) => roleName === 'Worker') && (
						<div className="border-t border-gray-300">
							<Menu
								mode="inline"
								inlineCollapsed={collapse}
								selectedKeys={[selectedPath]}
								items={[
									{
										key: 'production-workers-done',
										label: (
											<span data-route-key="production-workers-done">
												{t('finishedProductions')}
											</span>
										),
										icon: <ProductionIcon />,
									},
								]}
								className="bg-transparent"
								theme="light"
								onSelect={({ key, domEvent }) =>
									onSelectMenuItem({ key }, domEvent)
								}
								onOpenChange={(v) =>
									dispatch(setOpenMiniMenu(v))
								}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Menubar
