import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../../../components/header'
import { columnCheckDebtors } from '../../../utils/columns'
import Layout from '../../../components/Layout'
import ViewSalesModal from '../../../components/Modal/ViewSalesWithDocEntryModal'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { debouncedSet } from '../../../utils/debounce'
import { Input, Table, Button, Select, Empty, Spin } from 'antd'
import { http } from '../../../services/http'
import { Search, Timer } from 'lucide-react'
import moment from 'moment'
import { fetchDepartments } from '../../../utils/fetchData'
import _ from 'lodash'
import { formatTimeWithHours } from '../../../utils/time'
import { switchBgColor } from '../../../utils/switchBgProductionsTables'
import ProductionDetailed from '../../../components/Modal/ProductoinDetailedDashboard'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const DashboardWorkers = () => {
	const { t } = useTranslation()
	const tableRef = useRef(null)
	const { roleNames, getMe } = useSelector((state) => state.main)

	const switchIndex = (index) => {
		switch (index) {
			case 1:
				return '10'
				break
			case 2:
				return '20'
				break
			case 3:
				return '30'
				break
			case 4:
				return '40'
				break
			case 5:
				return '50'
				break

			default:
				return '10'
				break
		}
	}

	const switchIndexStartTime = (index) => {
		switch (index) {
			case 1:
				return '20:00:00'
				break
			case 2:
				return '12:00:00'
				break
			case 3:
				return '14:00:00'
				break
			case 4:
				return '13:00:00'
				break
			case 5:
				return '23:00:00'
				break

			default:
				return '19:00:00'
				break
		}
	}

	const fetchDashboardServices = async ({ queryKey }) => {
		const [_, department] = queryKey
		if (department === '') return

		try {
			const url = `api/items/dashboard/services?department=${department}`
			const { data } = await http.get(url)

			const formattedData = data.map((item) => {
				return {
					...item,
					dashboardLines: item.dashboardLines.map((row) => {
						const currentTime = moment()
						let positiveTimersSum = 0
						let positiveItemsLength = 0
						let negativeTimersSum = 0
						let negativeItemsLength = 0

						const docLines = row.documentLines.map((line) => {
							let remainingTime = null

							if (
								line.isStart === 'Yes' &&
								line.ready === 'No' &&
								line.startTime
							) {
								const startTime = moment(
									line.startTime,
									'HH:mm:ss',
								)
								const elapsedSeconds = currentTime.diff(
									startTime,
									'seconds',
								)
								remainingTime =
									Number(line.u_Timer) * 60 - elapsedSeconds

								if (remainingTime > 0) {
									positiveTimersSum += remainingTime
									positiveItemsLength++
								} else {
									negativeTimersSum += remainingTime
									negativeItemsLength++
								}
							}

							return {
								...line,
								timer: remainingTime,
							}
						})

						return {
							...row,
							documentLines: docLines,
							activeTimers: {
								positiveTimer: positiveTimersSum,
								positiveItemsLength: positiveItemsLength,
								negativeTimer: negativeTimersSum,
								negativeItemsLength: negativeItemsLength,
							},
						}
					}),
				}
			})

			// Aggregate timers across all dashboards for each docEntry
			const timersByDocEntry = formattedData.reduce((acc, item) => {
				item.dashboardLines.forEach((row) => {
					// If docEntry already exists, add to its values; otherwise, initialize it
					if (acc[`${row.docEntry}-${row.service}`]) {
						acc[`${row.docEntry}-${row.service}`].positiveTimer +=
							row.activeTimers.positiveTimer
						acc[
							`${row.docEntry}-${row.service}`
						].positiveItemsLength +=
							row.activeTimers.positiveItemsLength
						acc[`${row.docEntry}-${row.service}`].negativeTimer +=
							row.activeTimers.negativeTimer
						acc[
							`${row.docEntry}-${row.service}`
						].negativeItemsLength +=
							row.activeTimers.negativeItemsLength
					} else {
						acc[`${row.docEntry}-${row.service}`] = {
							positiveTimer: row.activeTimers.positiveTimer,
							positiveItemsLength:
								row.activeTimers.positiveItemsLength,
							negativeTimer: row.activeTimers.negativeTimer,
							negativeItemsLength:
								row.activeTimers.negativeItemsLength,
						}
					}
				})
				return acc
			}, {})

			setTimer(timersByDocEntry)
			return formattedData
		} catch (error) {
			console.log(error)
		}
	}

	const updateTimers = (formattedData, setTimer) => {
		const interval = setInterval(() => {
			const updatedTimers = formattedData.reduce((acc, item) => {
				item.dashboardLines.forEach((row) => {
					let positiveTimersSum = 0
					let positiveItemsLength = 0
					let negativeTimersSum = 0
					let negativeItemsLength = 0

					row.documentLines.forEach((line) => {
						if (
							line.isStart === 'Yes' &&
							line.ready === 'No' &&
							line.startTime
						) {
							const startTime = moment(line.startTime, 'HH:mm:ss')
							const elapsedSeconds = moment().diff(
								startTime,
								'seconds',
							)
							const remainingTime =
								Number(line.u_Timer) * 60 - elapsedSeconds

							if (remainingTime > 0) {
								positiveTimersSum += remainingTime
								positiveItemsLength++
							} else {
								negativeTimersSum += remainingTime
								negativeItemsLength++
							}
						}
					})

					const key = `${row.docEntry}-${row.service}`
					acc[key] = {
						positiveTimer: positiveTimersSum,
						positiveItemsLength: positiveItemsLength,
						negativeTimer: negativeTimersSum,
						negativeItemsLength: negativeItemsLength,
					}
				})
				return acc
			}, {})

			setTimer(() => ({ ...updatedTimers })) // Ensure new object reference
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [department, setDepartment] = useState(get(getMe, 'department', ''))
	const [searchDocNum, setSearchDocNum] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize] = useState(1000)
	const [hasMore, setHasMore] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [timer, setTimer] = useState({})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [selectedService, setSelectedService] = useState(null)

	const {
		data: productionWorker = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['dashboardWorkers', department],
		queryFn: fetchDashboardServices,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		refetchInterval: 30000,
	})

	const { data: departmentData = [] } = useQuery({
		queryKey: ['departments'],
		queryFn: fetchDepartments,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const columns = columnCheckDebtors(t, handleRowClick)

	const selectOptions = [
		{
			value: '',
			label: t('all'),
		},

		{
			value: 'Ready',
			label: 'Ready',
		},
		{
			value: 'In Process',
			label: 'In Process',
		},
		{
			value: 'Finished',
			label: 'Finished',
		},
	]

	useEffect(() => {
		if (productionWorker) {
			const cleanup = updateTimers(productionWorker, setTimer)
			return cleanup
		} else {
		}
	}, [productionWorker])

	const getBgClassName = (serviceIndex) => {
		// ${serviceIndex === 0 ? ' bg-gray-500' : serviceIndex === 1 ? 'bg-green-400' : serviceIndex === 2 ? 'bg-yellow-400' : serviceIndex === 3 ? 'bg-blue-400' : serviceIndex === 4 ? 'bg-red-600' : serviceIndex === 5 ? 'bg-black ' : 'bg-[#E8E8E8]'}
		switch (serviceIndex) {
			case 0:
				return 'bg-gray-500 text-white'

			case 1:
				return 'bg-green-400 text-white'

			case 2:
				return 'bg-yellow-400 text-white'

			case 3:
				return 'bg-blue-400 text-white'

			case 4:
				return 'bg-red-600 text-white'

			case 5:
				return 'bg-black text-white'

			default:
				return 'bg-[#E8E8E8] text-black'
		}
	}
	const getTextByIndex = (serviceIndex) => {
		switch (serviceIndex) {
			case 0:
				return t('notStarted')

			case 1:
				return t('done')

			case 2:
				return t('notStarted')

			case 3:
				return t('inProcess2')

			case 4:
				return t('expired')

			case 5:
				return t('hasToDoToday')

			default:
				return t('Unknown')
		}
	}

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				<div className="flex w-fullitems-center justify-between   ml-4 mr-4 mt-10 sm:ml-10 sm:mr-10 sm:mt-14 ">
					<h1 className="font-poppins text-xl font-bold text-black  sm:text-xl">
						{t('dashboardWorkers')}
					</h1>
				</div>
				<div className="mt-8 w-full border-t-[2px] border-[#E8E8E8] sm:mt-8"></div>
				<div className="flex w-full flex-col sm:flex-row items-end  gap-5 px-4 mt-10  sm:gap-7 sm:px-10">
					<div className="flex gap-5 flex-col sm:flex-row">
						{roleNames.includes('admin') && (
							<div className="flex flex-col gap-2">
								<p>{t('department')}</p>
								<Select
									className="w-[200px] h-8"
									value={department}
									onChange={setDepartment}
									options={departmentData.map(
										(department) => {
											return {
												value: department.code,
												label: department.name,
											}
										},
									)}
								/>
								{department === '' && roleName && (
									<span className="text-red-600">
										{t('selectDepartment')}
									</span>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="mt-10 border-t-[2px] border-[#E8E8E8]    flex w-full items-start overflow-x-auto overflow-y-scroll sm:mt-10 ">
					{isLoading ? (
						<div className="flex items-center justify-center max-h-screen  w-full my-10">
							<Spin size="large" />
						</div>
					) : productionWorker.length > 0 ? (
						productionWorker.map((worker, index) => {
							return (
								<div
									className="flex flex-col items-center justify-center border-r-[2px]  border-[#E8E8E8]   min-w-[400px] w-full "
									key={worker.dashboard}
								>
									<div className="flex flex-col items-center  gap-3 border-b-[2px] border-[#E8E8E8]  w-full py-5">
										<p className="font-bold text-xl">
											{worker.dashboard || '  .'}
										</p>
										<div>
											<p className="font-bold text-sm">
												{`${t('detailsCount')} : ${worker.dashboardLines.length}`}
											</p>
										</div>
									</div>
									<div className="flex flex-col gap-10 items-center justify-center p-5 cursor-pointer  min-w-[400px] w-full">
										{worker.dashboardLines.map(
											(data, serviceIndex) => {
												const time =
													timer[
														`${data.docEntry}-${data.service}`
													]?.negativeTimer

												const positiveTimer =
													timer[
														`${data.docEntry}-${data.service}`
													]?.positiveTimer

												const totalTimer =
													positiveTimer + time

												const completedDetailsTime =
													data.documentLines
														.filter((data) => {
															return (
																data.ready ===
																'Yes'
															)
														})
														.reduce(
															(total, item) => {
																// Convert current total (seconds) to HH:MM:SS
																const [
																	minutes,
																	seconds,
																] =
																	item.wastedTime
																		.split(
																			':',
																		)
																		.map(
																			Number,
																		)
																const timeInSeconds =
																	minutes *
																		60 +
																	seconds
																return (
																	total +
																	timeInSeconds
																)
															},
															0,
														)
												const notStartedDetailsTime =
													data.documentLines
														.filter((data) => {
															return (
																data.ready ===
																	'No' &&
																data.isStart ===
																	'No'
															)
														})
														.reduce(
															(total, item) => {
																return (
																	total +
																		item.u_Timer *
																			60 ||
																	0
																)
															},
															0,
														)

												const startedDetailsTime =
													data.documentLines
														.filter((data) => {
															return (
																data.ready ===
																	'No' &&
																data.isStart ===
																	'Yes'
															)
														})
														.reduce(
															(total, item) => {
																return (
																	total +
																		item.u_Timer *
																			60 ||
																	0
																)
															},
															0,
														)

												const isReadyDocument =
													data.documentLines.every(
														(item) => {
															return (
																item.ready ===
																'Yes'
															)
														},
													)

												return (
													<div
														key={`${data.docEntry}-${data.service}`}
														className={`flex  w-full flex-col gap-2 drop-shadow-2xl rounded-2xl ${isReadyDocument ? 'bg-green-400' : ' bg-white'}    justify-center p-2 border-2 border-red-600 `}
													>
														<div className="flex items-center gap-2 mt-2">
															<span className="text-gray-900 text-2xl">
																{t('docNum')}
																{' : '}
																{data.docNum}
															</span>
														</div>

														<div className="flex items-center gap-2">
															<span>
																{t(
																	'totalDetails',
																)}{' '}
																:{' '}
																{
																	data.serviceCount
																}
															</span>
														</div>
														<div className="flex items-center gap-2">
															<span>
																{t(
																	'completedDetails',
																)}{' '}
																:{' '}
																{t(
																	data.completedDetails,
																)}
															</span>
														</div>
														<div className="flex items-center gap-2">
															<span>
																{t(
																	'detailsNotStarted',
																)}{' '}
																:{' '}
																{t(
																	data.ostatka -
																		data.detailsInProcess,
																)}
															</span>
														</div>
														<div className="flex items-center gap-2">
															<span>
																{t(
																	'detailsInProcess',
																)}{' '}
																:{' '}
																{
																	data.detailsInProcess
																}
															</span>
														</div>
														<div className="flex items-center gap-2">
															<span>
																{t(
																	'completedDetailsTime',
																)}{' '}
																:{' '}
																{formatTimeWithHours(
																	completedDetailsTime,
																) ||
																	completedDetailsTime}
															</span>
														</div>
														<div className="flex items-center gap-2">
															<span>
																{t(
																	'notStartedDetailsTime',
																)}{' '}
																:{' '}
																{formatTimeWithHours(
																	notStartedDetailsTime,
																)}
															</span>
														</div>

														<div className="flex items-center gap-2">
															<span>
																{t(
																	'timeInProcess',
																)}{' '}
																:{' '}
																{totalTimer && (
																	<span
																		className={`${
																			totalTimer <=
																			0
																				? 'text-red-600'
																				: ''
																		}`}
																	>
																		{formatTimeWithHours(
																			totalTimer,
																		)}
																	</span>
																)}
															</span>
														</div>

														<div className="flex items-center justify-end gap-2 mt-2 mb-2">
															<Button
																type="primary"
																onClick={() => {
																	setIsModalOpen(
																		true,
																	)
																	setSelectedRow(
																		data,
																	)
																	setSelectedService(
																		data.service,
																	)
																	console.log(
																		'docLines',
																		data,
																	)
																	// setModalVisible(true)
																}}
															>
																{t(
																	'seeDetailed',
																)}
															</Button>
														</div>
													</div>
												)
											},
										)}
									</div>
								</div>
							)
						})
					) : (
						<div className="flex items-center justify-center max-h-screen  w-full my-10">
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</div>
					)}
				</div>
			</div>
			<ProductionDetailed
				visible={isModalOpen}
				data={selectedRow}
				onClose={() => setIsModalOpen(false)}
				service={selectedService}
			/>
			<ViewSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
				ship={false}
				incoming={false}
			/>
		</Layout>
	)
}

export default DashboardWorkers
