import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Table, Input, Button, Select, AutoComplete } from 'antd'
import Layout from '../../../components/Layout/index.jsx'
import ViewProductionWorkersModal from '../../../components/Modal/viewProductionWorkerModal.jsx'
import SuccessModalWithTimer from '../../../components/Modal/SuccessModalWithTimer/index.jsx'
import ErrorModalWithTimer from '../../../components/Modal/ErrorModalWithTimer/index.jsx'
import { columnProductionWorkersAdmin } from '../../../utils/columns.jsx'
import {
	fetchProductionWorkersAdmin,
	fetchItemsSelect,
} from '../../../utils/fetchData.js'
import moment from 'moment'
import { http } from '../../../services/http.js'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import './table.css'
import { debouncedSet } from '../../../utils/debounce.js'
import { handleMarshrutWorkers } from '../../../utils/navigateToPdf.jsx'
import {
	calculateDurationString,
	isAfter,
	isBefore,
	isSameDay,
} from '../../../utils/time'
import {
	switchBgColor,
	meaningOfBg,
	ignoredKeys,
} from '../../../utils/switchBgProductionsTables'

const ProductionWorkersAdmin = () => {
	const { t } = useTranslation()
	const formattedDate = moment()
	const { roleNames, getMe } = useSelector((state) => state.main)
	const successRef = useRef()
	const errorRef = useRef()

	const [currentPage, setCurrentPage] = useState(0)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedRowData, setSelectedRowData] = useState(null)
	const [docNum, setDocNum] = useState('')
	const [inputDocNum, setInputDocNum] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState(
		moment().endOf('month').format('YYYY-MM-DD'),
	)
	const [pageSize] = useState('30')
	const [activeTimers, setActiveTimers] = useState({})
	const [scannedCode, setScannedCode] = useState('')
	const [lastScanTime, setLastScanTime] = useState(null)
	const [mappedData, setMappedData] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [selectedRows, setSelectedRows] = useState([])
	const [btnLoading, setBtnLoading] = useState(false)
	const [isReadyForNextStage, setIsReadyForNextStage] = useState('')
	const [searchItemName, setSearchItemName] = useState('')
	const [itemName, setItemName] = useState('')
	const [itemCode, setItemCode] = useState('')

	const {
		data: productionWorkersData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: [
			'productionWorkersAdmin',
			currentPage,
			pageSize,
			startDate,
			endDate,
			docNum,
			isReadyForNextStage,
			roleNames.includes('admin') ? '' : getMe?.department,
			itemCode,
		],
		queryFn: fetchProductionWorkersAdmin,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const { data: itemsData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['services', searchItemName, 'услуги', 'api/items/get-items'],
		queryFn: fetchItemsSelect,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		setMappedData(productionWorkersData)
	}, [productionWorkersData])

	const startTimePatch = async (data, startTime) => {
		const docLines = data?.documentLines[0]
		const documentLines = [
			{
				lineId: docLines.lineId,
				startTime: startTime,
				endTime: docLines.endTime,
				wastedTime: docLines.wastedTime,
				isStarted: 'Yes',
				isReady: docLines.isReady,
				isReadyForNextStage: docLines.isReadyForNextStage,
				description: docLines.description,
			},
		]
		const patchingData = {
			priority: data.priority,
			status: docLines.description,
			salesOrderDocEntry: data?.salesOrderDocEntry,
			docEntry: data?.docEntry,

			documentLines,
		}
		try {
			await http.patch(
				`api/pots/update-pots/${data?.docEntry}`,
				patchingData,
			)
			refetch()
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		}
	}

	const endTimePatch = async (data, endTime, duration) => {
		const docLines = data?.documentLines[0]
		const documentLines = [
			{
				lineId: docLines.lineId,
				startTime: docLines.startTime,
				endTime: endTime,
				wastedTime: duration,
				isStarted: docLines.isStarted,
				isReady: 'Yes',
				isReadyForNextStage: 'Yes',
				description: docLines.description,
			},
		]
		const patchingData = {
			priority: data.priority,
			status: docLines.description,
			salesOrderDocEntry: data?.salesOrderDocEntry,
			docEntry: data?.docEntry,

			documentLines,
		}
		try {
			await http.patch(
				`api/pots/update-pots/${data?.docEntry}`,
				patchingData,
			)
			refetch()
			successRef.current?.open(t('sucSaved'))
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		}
	}

	const handleStartBtn = async (serialNumber) => {
		const currentTime = moment().format('HH:mm:ss')
		let url = `api/pots/get-pots?serialNumber=${serialNumber}&service=${getMe?.u_Service}`
		const { data } = await http.get(url)
		const validData = data[0]

		if (validData) {
			if (validData?.documentLines[0]?.isReadyForNextStage === 'Yes') {
				setActiveTimers((prev) => ({
					...prev,
					[serialNumber]: {
						startTime: currentTime,
						timer:
							Number(validData?.documentLines[0]?.timer) * 60 ||
							30,
						isActive: true,
						serialNumber,
						...validData,
					},
				}))
				try {
					startTimePatch(validData, currentTime)
				} catch (error) {
					console.log(error)
				}
			} else {
				errorRef.current?.open(t('notReadyForNextStage'))
			}
		} else {
			errorRef.current?.open(t('seriesNotFound'))
		}
	}

	const handleEndBtn = async (serialNumber) => {
		const currentTime = moment().format('HH:mm:ss')
		let url = `api/pots/get-pots?serialNumber=${serialNumber}&service=${getMe?.u_Service}`
		const { data } = await http.get(url)
		const validData = data[0]

		const endData = activeTimers[serialNumber]
		const durationFromStart = calculateDurationString(
			endData?.startTime,
			currentTime,
		)

		try {
			endTimePatch(validData, currentTime, durationFromStart)
			setActiveTimers((prev) => {
				const newTimers = { ...prev }
				delete newTimers[serialNumber]
				return newTimers
			})
			if (index !== '') {
				setMappedData((prev) => {
					return prev.map((item, i) => {
						if (i === index) {
							return {
								...item,
								isShowStart: true,
								isShowEnd: false,
							}
						}
						return item
					})
				})
			}
		} catch (error) {
			console.log('Error updating production:', error)
		}
	}

	const handleScan = useCallback(
		async (serialNumber) => {
			console.log(serialNumber)
			const currentTime = moment().format('HH:mm:ss')

			if (lastScanTime && currentTime - lastScanTime < 500) {
				return
			}
			setLastScanTime(currentTime)

			if (!activeTimers[serialNumber]) {
				handleStartBtn(serialNumber)
			} else if (activeTimers[serialNumber].isActive) {
				handleEndBtn(serialNumber)
			}
		},
		[lastScanTime, activeTimers],
	)

	useEffect(() => {
		const handleKeyPress = (e) => {
			// Define keys to ignore explicitly

			if (e.ctrlKey || e.metaKey || e.altKey || e.shiftKey) {
				return
			}

			if (e.key === 'Enter') {
				// Process scannedCode if Enter is pressed and scannedCode is not empty
				if (scannedCode) {
					handleScan(scannedCode) // Process the scanned barcode
					setScannedCode('') // Clear the scannedCode
				} else {
					console.log('Enter key pressed but no code to process.')
				}
			} else if (!ignoredKeys.includes(e.key)) {
				// Append valid characters to scannedCode
				setScannedCode((prev) => prev + e.key)
			}
		}

		window.addEventListener('keydown', handleKeyPress)
		return () => window.removeEventListener('keydown', handleKeyPress)
	}, [scannedCode, handleScan])

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveTimers((prev) => {
				const newTimers = { ...prev }
				Object.keys(newTimers).forEach((serialNumber) => {
					if (newTimers[serialNumber].isActive) {
						newTimers[serialNumber].timer -= 1
					}
				})
				return newTimers
			})
		}, 1000)

		return () => clearInterval(interval)
	}, [])
	const handleNextPage = () => {
		if (mappedData.length === +pageSize) {
			setCurrentPage((prevPage) => prevPage + 1)
		}
	}

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1)
		}
	}

	const handleRowClick = (record) => {
		setSelectedRowData(record)
		setIsModalVisible(true)
	}
	const handleStartDate = (e) => {
		setStartDate(e.target.value)
	}

	const handleEndDate = (e) => {
		setEndDate(e.target.value)
	}

	const handleDocNumChange = (val) => {
		debouncedSet(val, setDocNum)
	}

	const handleNavigateSeries = (record) => {
		console.log('record', record)
		localStorage.setItem('seriesData', JSON.stringify(record))
		const url = `/series-one-barcode`
		window.open(url, '_blank')
	}

	const onSelectChange = (selectedKeys, rows) => {
		console.log('selectedRowKeys changed: ', selectedKeys)
		console.log('rows changed: ', rows)
		setSelectedRowKeys(selectedKeys)
		setSelectedRows(rows)
	}

	const handlePrintSeries = (data) => {
		console.log('data', data)
		localStorage.setItem('adminSeriesData', JSON.stringify(data))
		const url = `/admin-series-barcode`
		window.open(url, '_blank')
	}

	const handlePatchSelected = (data) => {
		const currentTime = moment().format('HH:mm:ss')
		console.log('Data = ', data)

		setBtnLoading(true)
		const patchingData = data.map((item) => {
			return {
				docEntry: item.docEntry,
				priority: item.priority,
				status: item.status,
				documentLines: [
					{
						lineId: item.lineId,
						startTime: currentTime,
						endTime: currentTime,
						wastedTime: '00:00:00',
						isStarted: 'Yes',
						isReady: 'Yes',
						isReadyForNextStage: 'Yes',
					},
				],
			}
		})
		const patchingData2 = data.map((item) => {
			return {
				docEntry: item.docEntry,
				serialNumber: item.serialNumber,
			}
		})
		try {
			http.patch(`api/pots/update/batch`, patchingData).then((res) => {
				http.patch(`api/pots/ready-for-next-stage-batch`, patchingData2)
					.then((res) => {
						successRef.current?.open(t('sucChanged'))
						setTimeout(() => {
							refetch()
						}, 3000)
					})
					.catch((err) => {
						errorRef.current?.open(err?.response?.data?.message)
					})
			})
		} catch (error) {
			errorRef.current?.open(error?.response?.data?.message)
		} finally {
			setBtnLoading(false)
		}
	}

	const columns = columnProductionWorkersAdmin(
		t,
		handleRowClick,
		handleStartBtn,
		handleEndBtn,
		handleNavigateSeries,
		handleDocNumChange,
		handleMarshrutWorkers,
	)

	const handleItemSearch = (value) => {
		debouncedSet(value, setSearchItemName)
	}

	const meaningBg = meaningOfBg(t)

	return (
		<Layout>
			<div className="m-5 border-[2px] border-gray-200">
				{/* Header */}
				<h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
					{t('production')}
				</h1>

				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14" />

				{/* Active Timers Section */}
				{/* <div className="mt-5">
					<h2 className="text-lg font-bold mb-4 ml-10 ">
						{t('inProcessProduction')}
					</h2>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 m-5">
						{Object.entries(activeTimers).map(([docNum, data]) => (
							<Card key={docNum} className="relative">
								<div className="p-6">
									<div className="flex items-center justify-between">
										<span className="font-medium">
											{docNum}
										</span>
										<div className="flex items-center gap-2">
											<Timer className="w-5 h-5" />
											<span className="text-xl font-bold">
												{formatTime(data.timer)}
											</span>{' '}
										</div>
									</div>
								</div>
							</Card>
						))}
					</div>
				</div>
				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14" /> */}

				<div className="mt-5 ml-2 sm:ml-8 flex items-end gap-5 flex-wrap">
					<div className="flex  items-end">
						<div className="flex flex-col gap-2">
							<span className="font-bold text-sm">
								{t('docNum')}
							</span>
							<Input
								className="w-full sm:w-[200px] h-8"
								placeholder={t('docNum')}
								onChange={(e) => {
									handleDocNumChange(e.target.value)
									setInputDocNum(e.target.value)
								}}
								value={inputDocNum}
							/>
						</div>

						<div className="flex flex-col gap-2 ml-3">
							<span className="font-bold text-sm">
								{t('isReadyForNextStage')}
							</span>
							<Select
								value={isReadyForNextStage}
								onChange={(val) => {
									setIsReadyForNextStage(val)
									setSelectedRowKeys([])
								}}
								className="w-full sm:w-[200px]"
								options={[
									{ value: '', label: t('all') },
									{ value: 'Yes', label: t('yes') },
									{ value: 'No', label: t('no') },
								]}
							/>
						</div>
						<div className="flex flex-col gap-2 ml-3">
							<span className="font-bold text-sm">
								{t('services')}
							</span>
							<AutoComplete
								loading={itemsLoading}
								options={[
									{
										label: t('all'),
										value: '',
									},
									...itemsData?.map((item) => ({
										value: item.itemCode,
										label: item.itemName,
									})),
								]}
								className="w-[200px]"
								value={itemName}
								onSelect={(value, label) => {
									console.log(label.label)
									setItemCode(value)
									setItemName(label.label)
									handleItemSearch('')
								}}
								onChange={(val) => {
									setItemName(val)
									if (val === '') {
										setItemCode('')
									}
									handleItemSearch(val)
								}}
								showSearch
							/>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						<p className="font-bold text-sm">{t('startDate')}</p>
						<Input
							type="date"
							value={startDate}
							className="w-[150px]"
							onChange={handleStartDate}
							max={endDate}
							allowClear
						/>
					</div>
					<div className="flex flex-col gap-2">
						<p className="font-bold text-sm">{t('endDate')}</p>
						<Input
							type="date"
							value={endDate}
							className="w-[150px]"
							onChange={handleEndDate}
							min={startDate}
							max={formattedDate}
							allowClear
						/>
					</div>
					<div>
						<Button
							type="primary"
							className="h-8 "
							onClick={() => {
								handlePrintSeries(selectedRows)
							}}
							disabled={selectedRowKeys.length === 0}
						>
							{t('printSelected')}
						</Button>
					</div>
					<div>
						<Button
							type="primary"
							className="h-8 bg-red-600 hover:bg-red-400 disabled:bg-gray-100"
							onClick={() => {
								handlePatchSelected(selectedRows)
							}}
							disabled={selectedRowKeys.length === 0}
						>
							{t('finishSelected')}
						</Button>
					</div>
				</div>
				{/* <div className="flex w-full flex-col sm:flex-row items-end justify-between gap-4 px-4  sm:gap-7 sm:px-10">

				</div> */}

				<div className=" mt-10 mb-4 ml-10 flex flex-col justify-between sm:flex-row">
					<div className="flex gap-5 flex-wrap">
						{meaningBg.map((item, index) => (
							<div
								key={index}
								className="flex gap-1 items-center"
							>
								<div
									className={`rounded-full ${item.className} w-[20px] h-[20px]`}
								></div>
								<span>{item.meaning}</span>
							</div>
						))}
					</div>
					<div className="flex sm:mr-10">
						<button
							onClick={handlePreviousPage}
							disabled={currentPage === 0}
							className="h-[30px] w-[110px] bg-[#0A4D68] text-white flex items-center justify-center  disabled:bg-gray-200 disabled:text-gray-400 sm:w-[110px]"
						>
							{t('previous')}
						</button>
						<div className="bg-[#099204] w-[20px] h-[30px] flex items-center justify-center">
							<span className="text-center text-white">
								{currentPage + 1}
							</span>
						</div>
						<button
							onClick={handleNextPage}
							disabled={mappedData.length !== 30}
							className="h-[30px] w-[110px] bg-[#0A4D68] text-white disabled:bg-gray-200 disabled:text-gray-400 sm:w-[110px]"
						>
							{t('next')}
						</button>
					</div>
				</div>

				<div className="ml-10">
					<Table
						rowSelection={{
							selectedRowKeys,
							onChange: onSelectChange,
							getCheckboxProps: (record) => ({
								disabled: record.isReadyForNextStage === 'No', // Disable checkbox if isReadyForNextStage is 'No'
							}),
						}}
						columns={columns}
						dataSource={mappedData}
						pagination={false}
						className="mt-6 w-full sm:mt-10 ant-table-override"
						rowKey={(record) =>
							`${record.itemCode}-${record.serialNumber}-${
								record.lineId
							}`
						}
						bordered
						scroll={{ x: 900 }}
						loading={isLoading}
						rowClassName={switchBgColor}
					/>
				</div>
			</div>

			<ViewProductionWorkersModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => {
					setIsModalVisible(false)
				}}
			/>
			<SuccessModalWithTimer
				getRef={(r) => {
					successRef.current = r
				}}
			/>
			<ErrorModalWithTimer
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Layout>
	)
}

export default ProductionWorkersAdmin
