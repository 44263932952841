import { Modal, Input, Select, Button } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseOutlined } from '@ant-design/icons'
import { fetchItemsGroups, fetchUoMGroups } from '../../utils/fetchData'
import { useQuery } from '@tanstack/react-query'
import { http } from '../../services/http'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
const EditItemsTableModal = ({
	visible,
	data,
	onClose,
	onClick,
	finish = false,
}) => {
	const { t } = useTranslation()

	const successRef = useRef()
	const errorRef = useRef()

	const [loading, setLoading] = useState('')
	const [itemName, setItemName] = useState('')
	const [width, setWidth] = useState('')
	const [height, setHeight] = useState('')
	const [quantity, setQuantity] = useState('')
	const [price, setPrice] = useState('')
	const [currency, setCurrency] = useState('')
	const [itmsGrp, setItmsGrp] = useState('')
	const [ugpName, setUgpName] = useState('')
	const [timer, setTimer] = useState('')
	const [u_Number, setU_Number] = useState('')
	useEffect(() => {
		if (data) {
			setItemName(data.itemName ?? '')
			setWidth(data.u_Width ?? '')
			setHeight(data.u_Height ?? '')
			setQuantity(data.u_Kvmetr ?? '')
			setPrice(parseFloat(data.price) ?? '')
			setCurrency(data.currency ?? '')
			setItmsGrp(data.itmsGrpNam ?? '')
			setUgpName(data.ugpEntry ?? '')
			setTimer(data.u_Timer ?? '')
			setU_Number(data.u_Number ?? '')
		}
	}, [data])

	const { data: itemsGroupData = [], isLoading: itemsLoading } = useQuery({
		queryKey: ['itemsGrp'],
		queryFn: fetchItemsGroups,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const {
		data: uomData = [],
		isLoading,
		refetch,
	} = useQuery({
		queryKey: ['uoMEntry'],
		queryFn: fetchUoMGroups,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	})

	const postData = async () => {
		setLoading(true)

		try {
			await http.patch(`api/items/${data.itemCode}`, {
				itemName: itemName,
				itemsGroupCode: data?.itmsGrpCod,
				price,
				currency,
				u_Width: width.toString(),
				u_Height: height.toString(),
				u_Kvmetr: quantity.toString(),
				uoMGroupEntry: ugpName,
				u_Timer: timer,
				u_Number: String(u_Number),
			})
			successRef.current.open(t('sucEdited'))
			resetForm()
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	const resetForm = () => {
		setLoading(false)
		setItemName('')
		setWidth('')
		setHeight('')
		setQuantity('')
		setPrice('')
		setCurrency('')
		setItmsGrp('')
		setUgpName('')
		setTimer('')
		setU_Number('')
	}

	if (!data) return null

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t('editService')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="60%"
			style={{ height: '80%' }}
			bodyStyle={{ height: 'calc(100% - 55px)', overflowY: 'auto' }}
		>
			<div className="w-full px-4 sm:px-5">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center gap-7 justify-between sm:flex-row">
						<div className="flex flex-col gap-3 w-full">
							<p className="font-nunito">{t('itemName')}</p>
							<Input
								type="text"
								value={itemName}
								className="flex h-10  w-full items-center justify-center "
								style={{ color: 'black' }}
								onChange={(e) => {
									setItemName(e.target.value)
								}}
							/>
						</div>

						<div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
							<p className="font-nunito">{t('productGroup')}</p>

							<Input
								type="text"
								value={itmsGrp}
								className="flex h-10  w-full items-center justify-center "
								style={{ color: 'black' }}
								disabled
							/>
						</div>

						<div className="mt-4 flex flex-col gap-3 sm:mt-0 w-full">
							<p className="font-nunito">{t('currency')}</p>
							<Select
								value={currency}
								className="flex h-10  w-full items-center justify-center "
								style={{ color: 'black' }}
								onChange={(val) => {
									setCurrency(val)
								}}
								options={[
									{
										label: 'UZS',
										value: 'UZS',
									},
								]}
							/>
						</div>
					</div>

					<div className="mt-10 flex flex-col items-end gap-7  justify-between sm:flex-row">
						<div className="flex flex-col gap-3 w-full">
							<p className="font-nunito">{`${t('price')} `}</p>
							<Input
								type="number"
								onWheel={(e) => e.target.blur()}
								value={price}
								className="flex h-10 w-auto items-center justify-center"
								style={{ color: 'black' }}
								onChange={(e) => {
									setPrice(e.target.value)
								}}
							/>
						</div>
						<div className="flex flex-col  gap-3 w-full">
							<p className="font-nunito text-center">{`${t('timerItem')}`}</p>
							<Input
								value={timer}
								className="flex h-10 w-auto items-center justify-center"
								style={{ color: 'black' }}
								onChange={(e) => {
									setTimer(e.target.value)
								}}
							/>
						</div>
						<div className=" flex flex-col gap-3 w-full">
							<p>{`${t('unitMsr')}`}</p>
							<Select
								type="text"
								value={ugpName}
								onChange={(val) => {
									setUgpName(val)
								}}
								className="flex h-10 w-full  items-center justify-center border-[#D0D0D0]"
								options={uomData.map((item) => ({
									label: item.ugpName,
									value: item.ugpEntry,
								}))}
							/>
						</div>
						<div className="flex flex-col gap-3  w-full">
							<p>{t('u_Number')}</p>
							<Input
								value={u_Number}
								type="number"
								show={false}
								onWheel={(e) => e.target.blur()}
								min={0}
								onChange={(e) => {
									setU_Number(e.target.value)
								}}
								className="flex h-10 w-full  items-center justify-center border-[1px] border-[#D0D0D0]"
							/>
						</div>
					</div>

					<div className="flex items-center gap-10 mt-14 justify-end">
						<Button
							onClick={postData}
							loading={loading}
							disabled={loading}
							className="w-auto bg-[#0A4D68] h-[35px] rounded-lg text-white"
						>
							{t('save')}
						</Button>
					</div>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
				onConfirm={() => onClose()}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default EditItemsTableModal
