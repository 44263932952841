import React, { useState } from 'react'
import Layout from '../../../components/Layout'
import { Card, Button, Table, message, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import ChangeRateModal from '../../../components/Modal/ChangeRate'
import { http } from '../../../services/http'
import { TrendingUp, TrendingDown } from 'lucide-react'

const DashboardAccountant = () => {
	const { t } = useTranslation()

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [accData, setAccData] = useState([])
	const [accLoading, setAccLoading] = useState(false)

	const fetchData = async () => {
		try {
			setAccLoading(true)
			const { data } = await http.get(
				`api/dashboard/exchange-rates?date=${moment().format('YYYY-MM-DD')}`,
			)
			setData(data)
		} catch (err) {
			message.error(err?.response?.data?.message)
		} finally {
			setAccLoading(false)
		}
	}

	const fetchAccounts = async () => {
		try {
			setLoading(true)
			const { data } = await http.get('api/dashboard/accounts')
			setAccData(data)
		} catch (err) {
			message.error(err?.response?.data?.message)
		} finally {
			setLoading(false)
		}
	}

	React.useEffect(() => {
		fetchData()
		fetchAccounts()
	}, [])

	const testDataCard = [
		{
			title: '5110 - 5110 - Расходный счёт Kurpasnab',
			price: '2000000',
			currency: 'UZS',
			date: '2023-01-01T16:35:00.000Z',
			lastPurchase: '200000',
			type: 'incoming',
			color: 'blue-500',
		},
		{
			title: '5120 - 5120 - Расходный счёт Kurbanov',
			price: '1500000',
			currency: 'USD',
			date: '2023-01-01T16:35:00.000Z',
			lastPurchase: '200000',
			type: 'incoming',
			color: 'blue-500',
		},
		{
			title: '5130 - 5130 - Расходный счёт Kurbanov',
			price: '1500000',
			currency: 'EUR',
			date: '2023-01-01T16:35:00.000Z',
			lastPurchase: '200000',
			type: 'incoming',
			color: 'blue-500',
		},
		{
			title: '5140 - 5140 - Расходный счёт Kurbanov',
			price: '1500000',
			currency: 'RUB',
			date: '2023-01-01T16:35:00.000Z',
			lastPurchase: '200000',
			type: 'incoming',
			color: 'blue-500',
		},
	]

	const columns = [
		{
			title: t('bankName'),
			dataIndex: 'u_Bank',
			key: 'u_Bank',
			render: (text, record) => {
				return <span className="!text-left">{record.u_Bank}</span>
			},
		},
		{
			title: "1 USD =SO'M",
			dataIndex: 'u_USDtoUZS',
			key: 'u_USDtoUZS',
			render: (text, record) => {
				const markaziyBank = data.find((bank) => {
					return bank.u_Bank === 'Markaziy Bank'
				})
				const percent =
					((record?.u_USDtoUZS - markaziyBank?.u_USDtoUZS) /
						markaziyBank?.u_USDtoUZS) *
					100
				return (
					<div className="flex flex-col gap-1">
						<span className="!text-left">
							{new Intl.NumberFormat('fr-FR', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(text)}{' '}
							so'm
						</span>
						{record.u_Bank === 'Hamkor Bank' && (
							<div
								className={`${percent > 0 ? 'text-green-500' : 'text-red-500'} flex gap-2`}
							>
								<span>
									{percent > 0 ? (
										<TrendingUp />
									) : (
										<TrendingDown />
									)}
								</span>
								<span>
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(percent)}
									{'%'}
								</span>
							</div>
						)}
					</div>
				)
			},
		},
		{
			title: "1 RUB = SO'M",
			dataIndex: 'u_RUBLtoUZS',
			key: 'u_RUBLtoUZS',
			render: (text, record) => {
				const markaziyBank = data.find((bank) => {
					return bank.u_Bank === 'Markaziy Bank'
				})
				const percent =
					((record?.u_RUBLtoUZS - markaziyBank?.u_RUBLtoUZS) /
						markaziyBank?.u_RUBLtoUZS) *
					100
				return (
					<div className="flex flex-col gap-1">
						<span className="!text-left">
							{new Intl.NumberFormat('fr-FR', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(text)}{' '}
							so'm
						</span>
						{record.u_Bank === 'Hamkor Bank' && (
							<div
								className={`${percent > 0 ? 'text-green-500' : 'text-red-500'} flex gap-2`}
							>
								<span>
									{percent > 0 ? (
										<TrendingUp />
									) : (
										<TrendingDown />
									)}
								</span>
								<span>
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(percent)}
									{'%'}
								</span>
							</div>
						)}
					</div>
				)
			},
		},
		{
			title: '1 USD = RUB',
			dataIndex: 'u_USDtoRUBL',
			key: 'u_USDtoRUBL',
			render: (text, record) => {
				const markaziyBank = data.find((bank) => {
					return bank.u_Bank === 'Markaziy Bank'
				})
				const percent =
					((record?.u_USDtoRUBL - markaziyBank?.u_USDtoRUBL) /
						markaziyBank?.u_USDtoRUBL) *
					100
				return (
					<div className="flex flex-col gap-2">
						<span className="!text-left">
							{new Intl.NumberFormat('fr-FR', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}).format(text)}{' '}
							rub
						</span>
						{record.u_Bank === 'Hamkor Bank' && (
							<div
								className={`${percent > 0 ? 'text-green-500' : 'text-red-500'} flex gap-2`}
							>
								<span>
									{percent > 0 ? (
										<TrendingUp />
									) : (
										<TrendingDown />
									)}
								</span>
								<span>
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}).format(percent)}
									{'%'}
								</span>
							</div>
						)}
					</div>
				)
			},
		},

		{
			title: t('cashTotalInUSD'),
			dataIndex: 'u_TotalSumInUSD',
			key: 'u_TotalSumInUSD',
			render: (text) => {
				return (
					<span className="!text-left">
						{new Intl.NumberFormat('fr-FR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(text)}{' '}
						$
					</span>
				)
			},
		},
		{
			title: t('cashTotalInRUB'),
			dataIndex: 'u_TotalSumInRUBL',
			key: 'u_TotalSumInRUBL',
			render: (text) => {
				return (
					<span className="!text-left">
						{new Intl.NumberFormat('fr-FR', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(text)}{' '}
						rub
					</span>
				)
			},
		},
	]

	const handleModalOpen = () => {
		setIsModalVisible(true)
	}

	const handleModalClose = () => {
		setIsModalVisible(false)
	}

	const switchCurrency = (currency) => {
		switch (currency) {
			case 'UZS':
				return "so'm"
			case 'USD':
				return '$'
			case 'EUR':
				return '€'
			case 'RUB':
				return '₽'
			default:
				return "so'm"
		}
	}

	const refetchData = async () => {
		fetchData()
	}
	// sorter: (a, b) => a.name.localeCompare(b.name),

	const sortedData = [...data].sort((a, b) =>
		a?.u_Bank.localeCompare(b?.u_Bank),
	)

	return (
		<Layout>
			<div className="mt-5 p-10">
				<Spin spinning={accLoading}>
					<div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2">
						{accData.map((card, index) => (
							<Card
								key={index}
								className="rounded-lg border-t-4 border-t-basic bg-white drop-shadow-lg transition duration-150 ease-in hover:scale-[1.03]"
							>
								<span className="text-sm font-semibold text-basic">
									{`${card.acctCode} - ${card.acctName}`}
								</span>
								<p className="mt-3 text-2xl font-bold text-basic">{`${new Intl.NumberFormat('fr-FR').format(card.currTotal)} ${switchCurrency(card.docCurr)}`}</p>
								<p className="mt-2 text-gray-500">
									{t('lastPurchase')}
								</p>
								<p className="text-gray-500">{card.docDate}</p>
							</Card>
						))}
					</div>
				</Spin>

				<div className="mt-10 rounded-lg border-t-4 border-t-basic bg-white drop-shadow-md">
					<div className="p-5">
						<div className="flex items-center justify-between">
							<span className="text-xl font-bold text-basic">
								{t('exchangeRate')}
							</span>
							<Button
								type="primary"
								className="h-10 bg-basic text-white hover:!bg-blue-900"
								onClick={handleModalOpen}
							>
								+ {t('changeRate')}
							</Button>
						</div>

						<Table
							columns={columns}
							loading={loading}
							dataSource={sortedData}
							className="mt-10"
							pagination={false}
						/>
					</div>
				</div>
			</div>
			<ChangeRateModal
				visible={isModalVisible}
				data={data}
				onClose={handleModalClose}
				refetch={refetchData}
			/>
		</Layout>
	)
}

export default DashboardAccountant
