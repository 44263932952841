import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, Select, Typography } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'
import { http } from '../../services/http'
import moment from 'moment'
import { useSelector } from 'react-redux'
import SuccessModal from './SuccessModal'
import ErrorModal from './ErrorModal'
import { useQuery } from '@tanstack/react-query'
import {
	formatNumberWithSpaces,
	numberWithSpacesIntl,
	removeSpaces,
} from '../../utils/numberWithSpaces'
const InvoiceVendorPaymentModal = ({ visible, onClose, data }) => {
	const { t } = useTranslation()

	const { uzsCash, department, usdCash, roleNames, terminalCash } =
		useSelector((state) => state.main)

	const successRef = useRef()
	const errorRef = useRef()

	// const [loading, setLoading] = useState(false)
	// const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))
	// const [cashAccount1, setCashAccount1] = useState('')
	// const [currency, setCurrency] = useState('')
	// const [comment, setComment] = useState('')
	// const [amount, setAmount] = useState(data?.openAmount)

	const [loading, setLoading] = useState(false)
	const [docDate, setDocDate] = useState(moment().format('YYYY-MM-DD'))
	const [cashAccount, setCashAccount] = useState('')
	const [currency, setCurrency] = useState('')
	const [comment, setComment] = useState('')
	const [amount, setAmount] = useState(0)
	const [cash, setCash] = useState('')
	const [bpDebt, setBpDebt] = useState('')
	const [bpDebtFc, setBpDebtFc] = useState('')
	const [docRate, setDocRate] = useState('')
	const [docRateType, setDocRateType] = useState('')

	const fetchCash = async () => {
		try {
			let url = `api/cashaccounts`
			const { data } = await http.get(url)
			return data
		} catch (error) {
			console.log(error)
		}
	}

	const fetchBpBalance = async ({ queryKey }) => {
		const [, clientCode] = queryKey
		if (clientCode) {
			try {
				let url = `api/businesspartners/get-business-partners-balance?cardCode=${clientCode}`
				const { data } = await http.get(url)
				setBpDebt(
					data[0]?.departments?.reduce(
						(acc, item) => acc + item.balance,
						0,
					),
				) || 0
				setBpDebtFc(
					data[0]?.departments?.reduce(
						(acc, item) => acc + item.balanceFC,
						0,
					) || 0,
				)
				return data
			} catch (error) {
				console.log(error)
			}
		}
	}

	const fetchDebtTotal = async ({ queryKey }) => {
		const [, docEntry] = queryKey
		if (docEntry) {
			try {
				let url = `api/sales/sales-order-open-amounts?salesOrderDocEntry=${docEntry}`
				const { data } = await http.get(url)

				return data[0]
			} catch (error) {
				console.log(error)
			}
		}
	}

	const fetchDocRate = async () => {
		try {
			let url = `	api/currency/get-currency-rate`
			const { data } = await http.get(url)

			return data
		} catch (error) {
			console.log(error)
		}
	}

	const { data: cashData = [], isLoading } = useQuery({
		queryKey: ['oneSalesOrder'],
		queryFn: fetchCash,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const { data: todayDocRate, isLoadingDocRate } = useQuery({
		queryKey: ['docRate'],
		queryFn: fetchDocRate,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const { data: bpBalanceData = [] } = useQuery({
		queryKey: ['bpBalance', data?.cardCode],
		queryFn: fetchBpBalance,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const { data: debtTotal = [] } = useQuery({
		queryKey: ['bpBalance', data?.docEntry],
		queryFn: fetchDebtTotal,
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	})

	const postData = async () => {
		setLoading(true)
		const cashNotAdmin =
			cashAccount === 'Cash' && currency === 'USD'
				? usdCash
				: cashAccount === 'Cash' && currency === 'UZS'
					? uzsCash
					: cashAccount === 'Terminal'
						? terminalCash
						: ''

		try {
			const paymentInvoices = [
				{
					lineNum: 0,
					docEntry: data?.docEntry,
					sumApplied: removeSpaces(amount),
				},
			]

			await http.post('api/vendorpayments/invoices', {
				cardCode: data?.cardCode,
				docDate: moment(docDate).format('YYYY-MM-DD'),
				cashAccount: roleNames.includes('admin') ? cash : cashNotAdmin,
				docCurrency: currency,
				remarks: comment,
				u_DdsArticle: 'Доход от реализации',
				u_Department: data?.u_Department,
				paymentInvoices: paymentInvoices,
			})
			resetForm()
			successRef.current.open(t('sucCreated'))
		} catch (error) {
			errorRef.current.open(error?.response?.data?.message)
			onClose()
		} finally {
			setLoading(false)
		}
	}

	const resetForm = () => {
		setDocDate(moment().format('YYYY-MM-DD'))
		setCashAccount('')
		setCurrency('')
		setComment('')
		setAmount(0)
		setCash('')
		setBpDebt('')
		setDocRate('')
		setDocRateType('')
		onClose()
	}

	return (
		<Modal
			title={
				<h1 className="font-nunito  font-extrabold text-[#000000]">
					{t('payTitle')}
				</h1>
			}
			open={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="55%"
			style={{ height: '70%' }}
		>
			<div className="w-full px-2 sm:px-5">
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('selectedFor')}</p>
						<Input
							type="text"
							value={`Наличного расчета`}
							className=" h-10 w-auto "
							disabled
						/>
					</div>
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('docNum')}</p>
						<Input
							type="text"
							value={data?.docNum}
							className=" h-10 w-auto "
							disabled
						/>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('clientDebtUZS')}</p>
						<Input
							type="text"
							value={`${numberWithSpacesIntl(bpBalanceData[0]?.balance * todayDocRate)} UZS`}
							className=" h-10 w-auto "
							disabled
						/>
					</div>
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('clientDebtUSD')}</p>
						<Input
							type="text"
							value={`${numberWithSpacesIntl(bpBalanceData[0]?.balance)} USD`}
							className=" h-10 w-auto "
							disabled
						/>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3 ">
						<p className="font-bold">{t('docRateTypeChoose')}</p>

						<Select
							type="text"
							onChange={(val, label) => {
								setDocRateType(val)
								setDocRate(label.label)
							}}
							value={docRateType}
							className="flex h-10 w-[320px] items-center justify-center"
						>
							<Select.Option value={'Now'} label={todayDocRate}>
								{t('Конвертировать по текущему курсу')}
							</Select.Option>
							<Select.Option
								value={'DocRate'}
								label={data?.docRate || 0}
							>
								{t('Конвертировать по  курсу оформления')}
							</Select.Option>
						</Select>
					</div>
				</div>
				<div className="mt-5">
					<span className="font-bold mt-5">
						{t('docRate')} : {formatNumberWithSpaces(docRate)}
					</span>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3 ">
						<p className="font-bold">{t('currency')}</p>

						<Select
							type="text"
							onChange={(val) => {
								setCurrency(val)
								setCash('')
								if (val === 'USD') {
									setCashAccount('Cash')
								}
							}}
							value={currency}
							className="flex h-10 w-[150px] items-center justify-center "
						>
							<Select.Option value="USD">USD</Select.Option>
							<Select.Option value="UZS">UZS</Select.Option>
						</Select>
					</div>

					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('date')}</p>
						<Input
							type="date"
							value={docDate}
							className=" h-10 w-[130px] "
							onChange={(e) => {
								setDocDate(e.target.value)
							}}
						/>
					</div>
				</div>
				<div className="flex items-start justify-between mt-5">
					{!roleNames.includes('admin') && (
						<div className=" flex flex-col items-start gap-3  ">
							<p className="font-bold">{t('cashs')}</p>
							<Select
								type="text"
								onChange={(val) => {
									setCashAccount(val)
									if (val === 'Terminal') {
										setCurrency('UZS')
									}
								}}
								value={cashAccount}
								className="flex h-10 w-[200px] items-center justify-center"
							>
								<Select.Option value="Cash">
									{t('cash')}
								</Select.Option>
								<Select.Option value="Terminal">
									{t('terminal')}
								</Select.Option>
							</Select>
						</div>
					)}
					{roleNames.includes('admin') && (
						<div className="flex flex-col gap-3 ">
							<p className="font-bold">{t('cashs')}</p>

							<Select
								type="text"
								onChange={(val) => {
									setCash(val)
								}}
								value={cash}
								className="flex h-10 w-[200px] items-center justify-center "
								options={cashData
									?.filter(
										(cash) => cash.actCurr === currency,
									)
									?.map((item) => ({
										value: item.acctCode,
										label: `${item.acctCode} - ${item.acctName}`,
									}))}
								disabled={!currency}
							/>
						</div>
					)}
					<div className="flex flex-col items-start gap-3 ">
						<p className="font-bold">{t('payingAmount')}</p>
						<Input
							type="text"
							onChange={(e) => {
								const val = removeSpaces(e.target.value)
								setAmount(val)
							}}
							suffix={currency === 'UZS' ? 'UZS' : 'USD'}
							value={formatNumberWithSpaces(amount)}
							className="flex h-10 w-[200px] items-center justify-center border-[1px] border-[#D0D0D0]"
						/>
						<span className="font-bold w-[200px] overflow-x-auto">
							{t('keyword')} :{' '}
							{currency === 'UZS'
								? `${numberWithSpacesIntl(debtTotal?.openAmountFC - removeSpaces(amount || 0) || data?.docTotal * todayDocRate - removeSpaces(amount || 0))} UZS`
								: currency === 'USD'
									? `${numberWithSpacesIntl(
											debtTotal?.openAmount -
												removeSpaces(amount || 0) ||
												data?.docTotal -
													removeSpaces(amount || 0),
										)} USD`
									: 0}
						</span>
					</div>
				</div>
				<div className="flex items-center justify-between mt-5">
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('debtUZS')}</p>
						<Input
							type="text"
							value={`${numberWithSpacesIntl(debtTotal?.openAmountFC || data?.docTotal * todayDocRate)} UZS`}
							className=" h-10 w-auto "
							disabled
						/>
					</div>
					<div className="flex flex-col gap-3">
						<p className="font-bold">{t('debtUSD')}</p>
						<Input
							type="text"
							value={`${numberWithSpacesIntl(debtTotal?.openAmount || data?.docTotal)} USD`}
							className=" h-10 w-auto "
							disabled
						/>
					</div>
				</div>
				<div className="flex flex-col items-start gap-3 w-full mt-5">
					<p className="font-bold">{t('comments')}</p>
					<TextArea
						onChange={(e) => {
							setComment(e.target.value)
						}}
						value={comment}
						className="w-full"
						rows={4}
					/>
				</div>

				<div className="flex items-center gap-10 mt-14 justify-between">
					<Button
						onClick={() => {
							resetForm()
						}}
						className="w-full bg-red-600 h-[35px] rounded-lg text-white"
					>
						{t('back')}
					</Button>
					<Button
						onClick={postData}
						loading={loading}
						disabled={loading}
						className="w-full bg-[#0A4D68] h-[35px] rounded-lg text-white"
					>
						{t('pay')}
					</Button>
				</div>
			</div>
			<SuccessModal
				getRef={(r) => {
					successRef.current = r
				}}
				onConfirm={() => window.location.reload()}
			/>
			<ErrorModal
				getRef={(r) => {
					errorRef.current = r
				}}
			/>
		</Modal>
	)
}

export default InvoiceVendorPaymentModal
